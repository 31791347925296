import useLeverRoutes from '../useLeverRoutes/useLeverRoutes';

export function usePageTitle() {
  const LEVER_ROUTES = useLeverRoutes();

  const getSubstringUntilSecondSlash = (url: string) => {
    const parts = url.split('/');
    return parts.slice(0, 3).join('/');
  };

  const getPageTitle = (path: string): string => {
    return LEVER_ROUTES.find((r) => r.path === getSubstringUntilSecondSlash(path))?.title;
  };

  return {
    getPageTitle
  };
}
