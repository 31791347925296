import { styled } from '@bees/vision-tokens';
import { theme } from '@hexa-ui/theme';
import { THEME_SPACE } from '../../constants';

const Content = styled('div', {
  marginTop: theme.space[THEME_SPACE.SPACE_40_PX],
  gap: theme.space[THEME_SPACE.SPACE_24_PX]
});

const ErrorMessage = styled('div', {
  display: 'flex',
  justifyContent: 'center'
});

const Styled = {
  Content,
  ErrorMessage
};

export default Styled;
