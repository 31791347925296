/* eslint-disable react/jsx-props-no-spreading */

export enum DiscountAndQuantityVariantsEnum {
  personalized = 'discountAndQuantitiesPersonalized',
  nonPersonalized = 'discountAndQuantitiesNonPersonalized',
  tieredNonPersonalized = 'discountAndQuantitiesTieredNonPersonalized',
  tieredPersonalized = 'discountAndQuantitiesTieredPersonalized'
}

export enum DiscountAndQuantityVariantsCopyDeckKeyEnum {
  personalized = 'personalized',
  nonPersonalized = 'nonPersonalized',
  tieredNonPersonalized = 'tieredNonPersonalized',
  tieredPersonalized = 'tieredPersonalized'
}
