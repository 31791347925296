import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PAGE_SIZE_WITH_25 } from '../../constants';
import { useGetAllLevers } from '../../services/lever/hook/useGetAllLevers/useGetAllLevers';
import { IAllLeversContext } from './AllLeversContext.d';

const AllLeversContext = createContext({} as IAllLeversContext);

function AllLeversProvider({ children }: { children: React.ReactNode }) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_WITH_25);
  const [sort, setSort] = useState<'ASC' | 'DESC' | undefined>('DESC');
  const [sortField, setSortField] = useState('period');

  const [queryConfig, setQueryConfig] = useState({
    page,
    pageSize,
    sort: sort || 'DESC',
    sortField: sortField || 'period'
  });

  const { data, isLoading, isError, error, refetch } = useGetAllLevers(queryConfig);

  useEffect(() => {
    setQueryConfig({
      page,
      pageSize,
      sort,
      sortField
    });
  }, [page, pageSize, sort, sortField]);

  const setSorting = useCallback((column, order) => {
    setSortField(column);
    setSort(order);
  }, []);

  const contextValue = useMemo(() => {
    return {
      data,
      isLoading,
      isError,
      error,
      refetch,
      setPage,
      setPageSize,
      setSorting,
      queryConfig
    };
  }, [data, isLoading, isError, error, refetch, setSorting, queryConfig]);

  return <AllLeversContext.Provider value={contextValue}>{children}</AllLeversContext.Provider>;
}

function useAllLeversContext() {
  const context = useContext(AllLeversContext);
  return context;
}

export { AllLeversProvider, useAllLeversContext };
