import { UserInfoDataProps, UserInfoProviderProps } from './useUserInfo.d';

export function useUserInfo(): UserInfoProviderProps {
  const userInfoStored = localStorage.getItem('userInfo');

  const setUserInfo = (userInfo: UserInfoDataProps) => {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  };

  const getUserInfo = (): UserInfoDataProps | null => {
    if (userInfoStored) {
      return JSON.parse(userInfoStored);
    }

    return null;
  };

  return {
    ...getUserInfo(),
    setUserInfo
  };
}

export default useUserInfo;
