import { styled } from '@bees/vision-tokens';

const CreationCampaignContainer = styled('div', {
  padding: `15px 0`
});

const StepContainer = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
});

const Styled = {
  CreationCampaignContainer,
  StepContainer
};

export default Styled;
