import { styled } from '@bees/vision-tokens';
import { theme } from '@hexa-ui/theme';
import { THEME_SPACE } from '../../constants';

const SellerSelectorWrapper = styled('div', {
  minWidth: '141px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});

const HeaderWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingBottom: theme.space[THEME_SPACE.SPACE_24_PX],
  variants: {
    hasOptionalButtons: {
      true: {
        justifyContent: 'flex-end'
      },
      false: {
        justifyContent: 'space-between',
        marginTop: '-50px'
      }
    }
  }
});

const Styled = {
  SellerSelectorWrapper,
  HeaderWrapper
};

export default Styled;
