import { BASE_URL } from '../../../constants';
import { campaignDetailsPageViewed } from '../../../services/segment/segment';
import { getScreenName } from '../utils';

const DOCUMENT_REFERRER = document.location.origin;

export const campaignDetailsPageViewedEvent = (campaignId: string) => {
  campaignDetailsPageViewed({
    url: `${DOCUMENT_REFERRER}${BASE_URL}/details/${campaignId}`,
    referrer: `${DOCUMENT_REFERRER}${BASE_URL}`,
    campaign_id: campaignId,
    screen_name: getScreenName('details')
  });
};
