import { BASE_URL } from '../../../constants';
import {
  ModalScenario,
  coverImageModificationTriggered,
  dateRangeSelected,
  discountValueUpdated,
  executionTypeSelected,
  sKUMaximumQuantityUpdated,
  sKUMinimumQuantityUpdated,
  sKUQuantityUpdated,
  sKURemoved,
  sKUSelectionTriggered,
  tierActionPerformed,
  touchpointDetailsPageViewed
} from '../../../services/segment/segment';

import { getScreenName, mapMechanismTypes } from '../utils';
import {
  coverImageModificationTriggeredEventProps,
  dateRangeSelectedEventProps,
  discountValueUpdatedEventProps,
  mechanismTypesArrayProps,
  sKUMaximumQuantityUpdatedProps,
  sKUMinimumQuantityUpdatedEventProps,
  sKURemovedEventProps,
  sKUSelectionTriggeredProps,
  skuQuantityUpdatedProps,
  tierActionPerformedEventProps,
  touchpointDetailsPageViewedProps
} from './touchpointsDetailsPageEvents.d';

const DOCUMENT_REFERRER = document.location.origin;
const SCREEN = getScreenName('touchpoint-details');

const executionType = {
  MULTIPLE_SKU: 'Multiple SKUs',
  SINGLE_SKU: 'Single SKU'
};

const isPopActive = {
  true: 'YES',
  false: 'NO'
};

export const touchpointDetailsPageViewedEvent = ({
  campaignId,
  touchpointType,
  mechanismTypes
}: touchpointDetailsPageViewedProps) => {
  const mechanismTypesArray: mechanismTypesArrayProps = mapMechanismTypes(mechanismTypes);

  touchpointDetailsPageViewed({
    campaign_id: campaignId,
    referrer: DOCUMENT_REFERRER,
    screen_name: SCREEN,
    touchpoint_type: touchpointType,
    mechanism_types: mechanismTypesArray,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoint-details/${campaignId}`
  });
};

export const executionTypeSelectedEvent = ({
  campaignId,
  cardId,
  name,
  touchpointType,
  machanismTypes,
  popApply
}) => {
  executionTypeSelected({
    execution_type: executionType[cardId],
    button_label: name,
    button_name: cardId,
    is_portfolio_optimization_active: isPopActive[popApply],
    mechanism_types: machanismTypes.map((item) => {
      return {
        mechanism_type: item
      };
    }),
    screen_name: SCREEN,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoint-details/${campaignId}`,
    touchpoint_type: touchpointType
  });
};

export const sKUSelectionTriggeredEvent = ({
  campaignId,
  buttonLabel,
  buttonName,
  touchpointType,
  mechanismTypes,
  skuList
}: sKUSelectionTriggeredProps) => {
  const mechanismTypesArray: mechanismTypesArrayProps = mapMechanismTypes(mechanismTypes);

  sKUSelectionTriggered({
    campaign_id: campaignId,
    button_label: buttonLabel,
    button_name: buttonName,
    touchpoint_type: touchpointType,
    mechanism_types: mechanismTypesArray,
    screen_name: getScreenName('touchpoint-details'),
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoint-details/${campaignId}`,
    sku_list: skuList,
    destination_url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoint-details/${campaignId}`,
    modal_scenario: ModalScenario.False
  });
};

export const sKUQuantityUpdatedEvent = ({
  campaignId,
  buttonLabel,
  buttonName,
  modalName,
  touchpointType,
  mechanismTypes,
  skuUpdates
}: skuQuantityUpdatedProps) => {
  const mechanismTypesArray: mechanismTypesArrayProps = mapMechanismTypes(mechanismTypes);

  sKUQuantityUpdated({
    campaign_id: campaignId,
    button_label: buttonLabel,
    button_name: buttonName,
    modal_name: modalName,
    screen_name: getScreenName('touchpoint-details'),
    sku_updates: skuUpdates,
    touchpoint_type: touchpointType,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoint-details/${campaignId}`,
    destination_url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoint-details/${campaignId}`,
    mechanism_types: mechanismTypesArray
  });
};

export const dateRangeSelectedEvent = ({
  startDate,
  endDate,
  mechanismStartDate,
  mechanismEndDate,
  touchpointType,
  mechanismTypes,
  campaignId
}: dateRangeSelectedEventProps) => {
  dateRangeSelected({
    start_date: startDate,
    end_date: endDate,
    mechanism_start_date: mechanismStartDate,
    mechanism_end_date: mechanismEndDate,
    touchpoint_type: touchpointType,
    mechanism_types: mechanismTypes.map((item) => {
      return {
        mechanism_type: item
      };
    }),
    screen_name: SCREEN,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoint-details/${campaignId}`,
    campaign_id: campaignId
  });
};

export const coverImageModificationTriggeredEvent = ({
  campaignId,
  actionType,
  buttonLabel,
  buttonName,
  imageFileName
}: coverImageModificationTriggeredEventProps) => {
  coverImageModificationTriggered({
    campaign_id: campaignId,
    action_type: actionType,
    button_label: buttonLabel,
    button_name: buttonName,
    destination_url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoint-details/${campaignId}`,
    screen_name: SCREEN,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoint-details/${campaignId}`,
    image_file_name: imageFileName
  });
};

export const sKURemovedEvent = ({
  buttonLabel,
  buttonName,
  campaignId,
  touchpointType,
  mechanismTypes,
  skuListCount,
  skuId
}: sKURemovedEventProps) => {
  sKURemoved({
    button_label: buttonLabel,
    button_name: buttonName,
    campaign_id: campaignId,
    touchpoint_type: touchpointType,
    mechanism_types: mechanismTypes.map((item) => {
      return {
        mechanism_type: item
      };
    }),
    sku_list_count: skuListCount,
    sku_id: skuId,
    screen_name: SCREEN,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoint-details/${campaignId}`
  });
};

export const sKUMinimumQuantityUpdatedEvent = ({
  touchpointType,
  mechanismTypes,
  campaignId,
  updatedMinimumQuantity,
  errorMessageShown,
  country
}: sKUMinimumQuantityUpdatedEventProps) => {
  const mechanismTypesArray: mechanismTypesArrayProps = mapMechanismTypes(mechanismTypes);

  sKUMinimumQuantityUpdated({
    campaign_id: campaignId,
    touchpoint_type: touchpointType,
    mechanism_types: mechanismTypesArray,
    updated_minimum_quantity: updatedMinimumQuantity,
    error_message_shown: errorMessageShown,
    screen_name: SCREEN,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoint-details/${campaignId}`,
    country
  });
};

export const sKUMaximumQuantityUpdatedEvent = ({
  campaignId,
  country,
  maxQuantity,
  touchpointType,
  mechanismTypes,
  hasError
}: sKUMaximumQuantityUpdatedProps) => {
  sKUMaximumQuantityUpdated({
    country,
    campaign_id: campaignId,
    error_message_shown: hasError,
    touchpoint_type: touchpointType,
    mechanism_types: mechanismTypes.map((item) => {
      return {
        mechanism_type: item
      };
    }),
    screen_name: SCREEN,
    updated_maximum_quantity: maxQuantity,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoint-details/${campaignId}`
  });
};

export const discountValueUpdatedEvent = ({
  touchpointType,
  mechanismTypes,
  campaignId,
  country,
  strategyLabel,
  strategyName,
  subStrategyLabel,
  subStrategyName,
  updatedDiscount
}: discountValueUpdatedEventProps) => {
  discountValueUpdated({
    country,
    campaign_id: campaignId,
    screen_name: SCREEN,
    strategy_label: strategyLabel,
    strategy_name: strategyName,
    sub_strategy_label: subStrategyLabel,
    sub_strategy_name: subStrategyName,
    touchpoint_type: touchpointType,
    mechanism_types: mechanismTypes.map((item) => {
      return {
        mechanism_type: item
      };
    }),
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoint-details/${campaignId}`,
    updated_discount: updatedDiscount
  });
};

export const tierActionPerformedEvent = ({
  campaignId,
  buttonLabel,
  buttonName,
  tierNumber,
  touchpointType,
  mechanismTypes,
  actionType,
  updatedMaximumQuantity,
  updatedMinimumQuantity,
  updatedDiscount
}: tierActionPerformedEventProps) => {
  const mechanismTypesArray: mechanismTypesArrayProps = mapMechanismTypes(mechanismTypes);

  tierActionPerformed({
    campaign_id: campaignId,
    button_label: buttonLabel,
    button_name: buttonName,
    tier_number: tierNumber,
    touchpoint_type: touchpointType,
    mechanism_types: mechanismTypesArray,
    updated_minimum_quantity: updatedMinimumQuantity,
    updated_maximum_quantity: updatedMaximumQuantity,
    updated_discount: updatedDiscount,
    action_type: actionType,
    destination_url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoint-details/${campaignId}`,
    screen_name: SCREEN,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoint-details/${campaignId}`
  });
};
