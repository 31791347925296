import { useParams } from 'react-router-dom';

type LeverPrams = {
  id?: string;
};

function useGetLeverParams() {
  const params = useParams<LeverPrams>();
  return params;
}

export default useGetLeverParams;
