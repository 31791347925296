import { styled } from '@bees/vision-tokens';
import { Button as _Button, Card as _Card, Paragraph as _Paragraph } from '@hexa-ui/components';

export const Card = styled(_Card, {
  overflowY: 'hidden'
});

export const HeaderContainer = styled('div', {
  padding: '0 0 $4 0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'baseline'
});

export const HeadingCardContent = styled('div', {
  padding: '0 0 $4 $4',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'baseline'
});

export const CardContent = styled('div', {
  padding: '0 0 $4 $4',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  minWidth: '532px'
});

export const Header = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
});

export const Paragraph = styled(_Paragraph, {
  color: '$interfaceLabelSecondary',
  textAlign: 'left'
});

export const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '24px',
  width: '100%'
});

export const NewCampaignContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingBottom: '27px',
  padding: '0px 8%',
  'h1, h2, h3, h4, h5, p': {
    margin: 0
  },
  width: '100%',
  maxWidth: '100%'
});

export const Button = styled(_Button, {
  marginBottom: '$4',
  marginTop: '$3',
  '& svg': {
    width: '18px'
  },
  '& svg:first-child': { marginRight: '6px' },
  '& svg:last-child': { marginLeft: '6px' }
});

export const ButtonsContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  width: '100%',
  gap: '16px',
  margin: '$3 0'
});
