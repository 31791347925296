import { memo, useCallback, useEffect, useState } from 'react';
import { useAppHeader } from 'admin-portal-shared-services';
import { Paragraph, Select } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../constants';
import { useAllLeversContext } from '../../context/AllLeversContext/AllLeversContext';
import useGenericDialog from '../../hooks/useGenericDialog/useGenericDialog';
import useGetSellersById from '../../services/sellers/hook/useGetSellers/useGetSellersById';
import Styled from './SellerSelector.styles';
import { useBeesContext } from '../../context/BeesContext/BeesContext';

interface SellerSelectorProps {
  isOfferCreationPage: boolean;
}

const SellerSelector = memo<SellerSelectorProps>(function SellerSelector({
  isOfferCreationPage
}: SellerSelectorProps) {
  const { sellerInfo, setSellerInfo, userInfo } = useBeesContext();
  const [selectedSeller, setSelectedSeller] = useState<string>();
  const [appHeaderConfig] = useAppHeader();
  const { onOpenDialog, Dialog, onCloseDialog } = useGenericDialog();
  const { refetch } = useAllLeversContext();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { data, isLoading } = useGetSellersById(userInfo.selectedVendor);

  const handleOpenDialog = useCallback(
    (e) => {
      onOpenDialog({
        title: formatMessage({ id: 'sellerChangeConfirmationModal.Title' }),
        body: (
          <Paragraph>
            {formatMessage({ id: 'sellerChangeConfirmationModal.Description' })}
          </Paragraph>
        ),
        confirmMessage: formatMessage({ id: 'sellerChangeConfirmationModal.Confirm' }),
        cancelMessage: formatMessage({ id: 'sellerChangeConfirmationModal.Cancel' }),
        buttonConfirmColor: 'destructive',
        onConfirm: () => {
          setSellerInfo(e);
          setSelectedSeller(e);
          navigate(`${BASE_URL}`);
          onCloseDialog();
        }
      });
    },
    [formatMessage, navigate, onCloseDialog, onOpenDialog, setSellerInfo]
  );

  const handleChangeSeller = useCallback(
    (e) => {
      setSellerInfo(e);
      setSelectedSeller(e);
    },
    [setSellerInfo]
  );

  useEffect(() => {
    if (!data) return;

    const selectedVendor = data.vendors.find((vendor) => vendor.id === sellerInfo);

    if (!sellerInfo || !selectedVendor) {
      setSellerInfo(data?.vendors[0]?.id);
      setSelectedSeller(data.vendors[0].id);
    } else {
      setSelectedSeller(sellerInfo);
    }
  }, [data]);

  useEffect(() => {
    if (!selectedSeller || !sellerInfo) return;

    refetch();
  }, [refetch, selectedSeller, sellerInfo]);

  return (
    <>
      <Dialog />
      <Styled.SellerSelectorWrapper>
        <Select.Root
          data-testid="seller-selector"
          shape="pill"
          size="small"
          disabled={isLoading || appHeaderConfig.loadingVendors || !selectedSeller}
          value={selectedSeller}
          key={selectedSeller}
          onChange={isOfferCreationPage ? handleOpenDialog : handleChangeSeller}
        >
          {data?.vendors.map((seller) => (
            <Select.Option
              value={seller.id}
              key={`option-${seller.name}`}
              data-testid={`seller-options-${seller.name}`}
            >
              {seller.name}
            </Select.Option>
          ))}
        </Select.Root>
      </Styled.SellerSelectorWrapper>
    </>
  );
});

SellerSelector.displayName = 'SellerSelector';

export default SellerSelector;
