import { useCallback } from 'react';

import { modalCancelOrExitEvent } from '../../../hooks/useSegment/modalEvents/modalEvents';

export const useBreadCrumbDialogCreationCampaignHelper = () => {
  const trackModalCancelOrExitEvent = useCallback(
    (buttonLabel: string, buttonName: string) =>
      modalCancelOrExitEvent({
        buttonLabel,
        buttonName,
        modalName: 'Leave offer creation'
      }),
    []
  );

  return {
    trackModalCancelOrExitEvent
  };
};
