/* eslint-disable react-hooks/exhaustive-deps */
import { Heading, Paragraph } from '@hexa-ui/components';
import {
  appHeaderConfig as headerConfig,
  useAppHeader,
  useUserMetadata
} from 'admin-portal-shared-services';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SellerSelector from '../../components/SellerSelector/SellerSelector';
import Styled from '../../components/SellerSelector/SellerSelector.styles';
import { BASE_URL, LOCAL_STORAGE_KEYS, STEP_ROUTES } from '../../constants';
import useGenericDialog from '../../hooks/useGenericDialog/useGenericDialog';
import useLeverRoutes, { pagesUrl } from '../../hooks/useLeverRoutes/useLeverRoutes';
import { usePageTitle } from '../../hooks/usePageTitle/usePageTitle';
import { useUserInfo } from '../../hooks/useUserInfo/useUserInfo';
import { removeSlashFromEnd } from '../../utils/formatters/formatters';
import { getHeadingMessageId } from '../../utils/getHeadingMessageId/getHeadingMessageId';

const configureHeader = (userInfo, setAppHeaderConfig, data) => {
  setAppHeaderConfig({
    countrySelect: true,
    vendorSelect: true,
    defaultCountry: userInfo.selectedCountry,
    defaultVendor: userInfo.selectedVendor,
    countryOptions: userInfo.availableCountries?.map((country) => country.code),
    vendorOptions: data?.vendors || [],
    loadingVendors: false
  });
};

const openDialog = (onOpenDialog, formatMessage, config) => {
  onOpenDialog({
    title: formatMessage({ id: config.titleId }),
    body: <Paragraph>{formatMessage({ id: config.bodyId })}</Paragraph>,
    confirmMessage: formatMessage({ id: config.confirmMessageId }),
    cancelMessage: formatMessage({ id: config.cancelMessageId }),
    buttonConfirmColor: config.buttonConfirmColor,
    onConfirm: config.onConfirm,
    onCancel: config.onCancel
  });
};

function HeaderService() {
  const [appHeaderConfig, setAppHeaderConfig] = useAppHeader();
  const { pathname } = useLocation();
  const LEVER_ROUTES = useLeverRoutes();
  const url = removeSlashFromEnd(pathname);
  const userInfo = useUserInfo();
  const { getPageTitle } = usePageTitle();
  const [hasOptionalButtons, setHasOptionalButtons] = useState(false);
  const { data } = useUserMetadata();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { onOpenDialog, Dialog, onCloseDialog } = useGenericDialog();
  const isOfferCreationPage = STEP_ROUTES.some((route) => pathname.includes(route));
  const [changeCountry, setChangeCountry] = useState(false);
  const [showCountryDialog, setShowCountryDialog] = useState(false);
  const [showVendorDialog, setShowVendorDialog] = useState(false);
  const [showSellerSelector, setshowSellerSelector] = useState(false);

  const getButtons = (path: string) => {
    const route = LEVER_ROUTES.find((r) => r.path === path);
    return route?.buttons ?? [];
  };

  useEffect(() => {
    if (!isOfferCreationPage) {
      setAppHeaderConfig({
        defaultCountry: appHeaderConfig.selectedCountry,
        defaultVendor: appHeaderConfig.selectedVendor
      });
      userInfo.setUserInfo({
        ...userInfo,
        selectedCountry: appHeaderConfig.selectedCountry,
        selectedVendor: appHeaderConfig.selectedVendor
      });
    }
  }, [appHeaderConfig.selectedCountry, appHeaderConfig.selectedVendor]);

  useEffect(() => {
    configureHeader(userInfo, setAppHeaderConfig, data);
  }, [setAppHeaderConfig, changeCountry]);

  const handleOpenCountryDialog = () => {
    setShowCountryDialog(true);
    openDialog(onOpenDialog, formatMessage, {
      titleId: 'countryChangeConfirmationModal.Title',
      bodyId: 'countryChangeConfirmationModal.Description',
      confirmMessageId: 'countryChangeConfirmationModal.Confirm',
      cancelMessageId: 'countryChangeConfirmationModal.Cancel',
      buttonConfirmColor: 'destructive',
      onConfirm: () => {
        userInfo.setUserInfo({
          ...userInfo,
          selectedCountry: appHeaderConfig.selectedCountry,
          selectedVendor: appHeaderConfig.selectedVendor
        });
        setChangeCountry(!changeCountry);
        navigate(`${BASE_URL}`);
        onCloseDialog();
        setShowCountryDialog(false);
      },
      onCancel: () => {
        headerConfig.onChangeSelectedCountry(userInfo.selectedCountry);
        onCloseDialog();
        setShowCountryDialog(false);
      }
    });
  };

  const handleOpenVendorDialog = () => {
    setShowVendorDialog(true);
    openDialog(onOpenDialog, formatMessage, {
      titleId: 'vendorChangeConfirmationModal.Title',
      bodyId: 'vendorChangeConfirmationModal.Description',
      confirmMessageId: 'vendorChangeConfirmationModal.Confirm',
      cancelMessageId: 'vendorChangeConfirmationModal.Cancel',
      buttonConfirmColor: 'destructive',
      onConfirm: () => {
        userInfo.setUserInfo({ ...userInfo, selectedVendor: appHeaderConfig.selectedVendor });
        setAppHeaderConfig({
          defaultVendor: appHeaderConfig.selectedVendor
        });

        headerConfig.onChangeSelectedVendor(appHeaderConfig.selectedVendor);
        onCloseDialog();
        setShowVendorDialog(false);
        navigate(`${BASE_URL}`);
      },
      onCancel: () => {
        userInfo.setUserInfo({ ...userInfo, selectedVendor: userInfo.selectedVendor });
        headerConfig.onChangeSelectedVendor(userInfo.selectedVendor);
        onCloseDialog();
        setShowVendorDialog(false);
      }
    });
  };

  useEffect(() => {
    if (
      isOfferCreationPage &&
      appHeaderConfig.selectedCountry &&
      appHeaderConfig.selectedCountry !== userInfo.selectedCountry &&
      !showVendorDialog
    ) {
      handleOpenCountryDialog();
    }
  }, [appHeaderConfig.selectedVendor]);

  useEffect(() => {
    const buttons = getButtons(url);
    setHasOptionalButtons(buttons.length > 0);
    setAppHeaderConfig({
      pageTitle: getPageTitle(url),
      optionalButtons: getButtons(url),
      countryOptions: userInfo.availableCountries?.map((country) => country.code)
    });
  }, [url, setAppHeaderConfig]);

  useEffect(() => {
    if (appHeaderConfig.selectedVendor && !showCountryDialog) {
      userInfo.setUserInfo({
        ...userInfo,
        selectedVendor: appHeaderConfig.selectedVendor
      });
    }
    if (
      isOfferCreationPage &&
      appHeaderConfig.selectedVendor &&
      appHeaderConfig.selectedVendor !== userInfo.selectedVendor &&
      !showCountryDialog
    ) {
      handleOpenVendorDialog();
    }
  }, [appHeaderConfig.selectedVendor, showCountryDialog]);

  const selectedVendor = useMemo(() => {
    return data?.vendors?.find((vendor) => vendor.id === appHeaderConfig.selectedVendor);
  }, [appHeaderConfig.selectedVendor]);

  useEffect(() => {
    if (selectedVendor) {
      if (
        appHeaderConfig &&
        appHeaderConfig.vendorOptions?.length > 0 &&
        selectedVendor?.isManufacturer
      ) {
        setshowSellerSelector(true);
        localStorage.setItem('isVendorManufacturer', 'true');
      } else {
        setshowSellerSelector(false);
        localStorage.removeItem('isVendorManufacturer');
      }
    }
  }, [selectedVendor, appHeaderConfig]);

  const detailsPageBasePath = `${BASE_URL}/${pagesUrl.details}`;
  const isDetailsPage = pathname.startsWith(detailsPageBasePath);

  const headingMessageId = getHeadingMessageId(hasOptionalButtons, isDetailsPage);

  useEffect(() => {
    if (appHeaderConfig.selectedVendor && !showSellerSelector) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedSeller);
    }
  }, [showSellerSelector, userInfo.selectedVendor]);

  return (
    <>
      <Dialog />
      <Styled.HeaderWrapper hasOptionalButtons={hasOptionalButtons}>
        {headingMessageId && <Heading size="H2">{formatMessage({ id: headingMessageId })}</Heading>}
        {showSellerSelector && (
          <SellerSelector
            isOfferCreationPage={isOfferCreationPage}
            selectedVendor={appHeaderConfig.selectedVendor}
          />
        )}
      </Styled.HeaderWrapper>

      <Outlet />
    </>
  );
}

export default HeaderService;
