export function TargetIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 6.3499999 6.3499999"
      version="1.1"
      id="svg5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs2" />
      <g id="layer2" transform="translate(0.16526128,0.08583866)">
        <path
          id="path331"
          fill="none"
          fillOpacity="0.57979"
          stroke="#222222"
          strokeWidth="0.459"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="none"
          strokeOpacity="1"
          stopColor="#000000"
          d="m 5.3478561,1.8667039 c 0.04107,0.075603 0.078591,0.1534132 0.1123418,0.233207 0.1349999,0.3191755 0.2096518,0.6700911 0.2096519,1.0384425 -2e-7,1.4734056 -1.1944314,2.6678368 -2.667837,2.6678369 -1.4734057,0 -2.66783676,-1.1944313 -2.6678369,-2.6678369 1.1e-7,-1.4734057 1.1944313,-2.66783687 2.6678369,-2.66783696 0.3683515,1.6e-7 0.7192669,0.0746521 1.0384425,0.20965192 0.079794,0.0337494 0.1576041,0.0712718 0.2332069,0.11234159"
        />
        <path
          id="path331-3"
          fill="none"
          fillOpacity="0.57979"
          stroke="#222222"
          strokeWidth="0.459"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="none"
          strokeOpacity="1"
          stopColor="#000000"
          d="m 4.5483468,3.1390198 c 0,0.8543846 -0.6926157,1.5470003 -1.5470003,1.5470003 -0.8543847,0 -1.5470003,-0.6926157 -1.5470003,-1.5470003 0,-0.8543847 0.6926156,-1.5470003 1.5470003,-1.5470003"
        />
        <path
          fill="none"
          stroke="#222222"
          strokeWidth="0.459"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="none"
          strokeOpacity="1"
          d="m 3.0349425,3.1105054 0.900537,-0.900537 H 4.8038159 L 5.6994501,1.3143343 4.8565345,1.2781496 4.8301657,0.44504988 C 4.3633649,0.91185067 3.9345315,1.340684 3.9345315,1.340684 v 0.8683365"
          id="path512-7"
        />
      </g>
    </svg>
  );
}
