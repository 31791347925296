import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { getSellersById } from '../../sellersService';
import { GetSellersByIdResponse } from '../../sellersService.d';

export default function useGetSellersById(manufacturerId: string) {
  const { data, isSuccess, error, isLoading } = useQuery<GetSellersByIdResponse, AxiosError>(
    ['id', manufacturerId],
    () => getSellersById({ manufacturerId }),
    {
      staleTime: Infinity,
      retry: false
    }
  );

  return {
    data,
    error,
    isLoading,
    isSuccess
  };
}
