import { useEffect } from 'react';
import { useAppHeader } from 'admin-portal-shared-services';
import useGenericDialog from '../../../hooks/useGenericDialog/useGenericDialog';

import { HOME_PATH } from '../../../hooks/useBreadCrumbItems/useBreadCrumbItems.consts';
import { useBreadCrumbDialogCreationCampaignActions } from './useBreadCrumbDialogCreationCampaignActions';
import { useBreadCrumbDialogCreationCampaignHelper } from './useBreadCrumbDialogCreationCampaignHelper';

export const useBreadCrumbDialogCreationCampaign = () => {
  const homeBreadcrumb = document.querySelector('a[aria-label="link to home"]');
  const [, setAppHeaderConfig] = useAppHeader();

  const { trackModalCancelOrExitEvent } = useBreadCrumbDialogCreationCampaignHelper();
  const { onOpenDialog, Dialog: BreadCrumbDialog } = useGenericDialog(trackModalCancelOrExitEvent);
  const { breadCrumbItemsWithOnClick, handleOpenDialog } =
    useBreadCrumbDialogCreationCampaignActions(onOpenDialog);

  useEffect(() => {
    setAppHeaderConfig({
      breadcrumbConfig: {
        homePath: HOME_PATH,
        items: breadCrumbItemsWithOnClick
      }
    });
  }, [setAppHeaderConfig, breadCrumbItemsWithOnClick]);

  /* istanbul ignore next */
  useEffect(() => {
    function handleOpenDialogFromHomeButtom(e) {
      e.preventDefault();
      e.stopPropagation();
      handleOpenDialog('/');
    }

    if (homeBreadcrumb) {
      homeBreadcrumb.addEventListener('click', handleOpenDialogFromHomeButtom);
    }

    return () => {
      if (homeBreadcrumb) {
        homeBreadcrumb.removeEventListener('click', handleOpenDialogFromHomeButtom);
      }
    };
  }, [homeBreadcrumb]);

  return {
    BreadCrumbDialog
  };
};

export default useBreadCrumbDialogCreationCampaign;
