import { Paragraph, Select } from '@hexa-ui/components';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, LOCAL_STORAGE_KEYS } from '../../constants';
import { useAllLeversContext } from '../../context/AllLeversContext/AllLeversContext';
import useGenericDialog from '../../hooks/useGenericDialog/useGenericDialog';
import useGetSellersById from '../../services/sellers/hook/useGetSellers/useGetSellersById';
import Styled from './SellerSelector.styles';

interface SellerSelectorProps {
  isOfferCreationPage: boolean;
  selectedVendor: string;
}

const SellerSelector = ({ isOfferCreationPage, selectedVendor }: SellerSelectorProps) => {
  const { data, isLoading } = useGetSellersById(selectedVendor);
  const { onOpenDialog, Dialog, onCloseDialog } = useGenericDialog();
  const { refetch } = useAllLeversContext();

  const { formatMessage } = useIntl();
  const [selectedSeller, setSelectedSeller] = useState(() => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.selectedSeller) || data?.vendors[0]?.id;
  });
  const navigate = useNavigate();

  const handleOpenDialog = (e) => {
    onOpenDialog({
      title: formatMessage({ id: 'sellerChangeConfirmationModal.Title' }),
      body: (
        <Paragraph>{formatMessage({ id: 'sellerChangeConfirmationModal.Description' })}</Paragraph>
      ),
      confirmMessage: formatMessage({ id: 'sellerChangeConfirmationModal.Confirm' }),
      cancelMessage: formatMessage({ id: 'sellerChangeConfirmationModal.Cancel' }),
      buttonConfirmColor: 'destructive',
      onConfirm: () => {
        onCloseDialog();
        setSelectedSeller(e);
        navigate(`${BASE_URL}`);
        localStorage.setItem(LOCAL_STORAGE_KEYS.selectedSeller, e);
      }
    });
  };

  const handleChangeSeller = (e) => {
    setSelectedSeller(e);
    localStorage.setItem(LOCAL_STORAGE_KEYS.selectedSeller, e);
    refetch();
  };

  useEffect(() => {
    if (data?.vendors[0]?.id && !localStorage.getItem(LOCAL_STORAGE_KEYS.selectedSeller)) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.selectedSeller, data?.vendors[0]?.id);
      setSelectedSeller(data?.vendors[0]?.id);
    }

    if (!data?.vendors[0].id && localStorage.getItem(LOCAL_STORAGE_KEYS.selectedSeller)) {
      setSelectedSeller(localStorage.getItem(LOCAL_STORAGE_KEYS.selectedSeller));
    }
  }, [data]);

  return (
    <>
      <Dialog />
      {!isLoading && (
        <Styled.SellerSelectorWrapper>
          <Select.Root
            defaultValue={selectedSeller}
            data-testid="seller-selector"
            shape="pill"
            size="small"
            value={selectedSeller}
            key={selectedSeller}
            onChange={(e) => {
              return isOfferCreationPage ? handleOpenDialog(e) : handleChangeSeller(e);
            }}
          >
            {data?.vendors.map((seller) => (
              <Select.Option
                value={seller.id}
                key={`option-${seller.name}`}
                data-testid={`seller-options-${seller.name}`}
              >
                {seller.name}
              </Select.Option>
            ))}
          </Select.Root>
        </Styled.SellerSelectorWrapper>
      )}
    </>
  );
};

export default SellerSelector;
