import { Paragraph } from '@hexa-ui/components';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AUDIENCES_URL } from '../../constants';
import useLever from '../../context/LeverContext/hooks/useLever/useLever';
import useGenericDialog from '../useGenericDialog/useGenericDialog';
import useSegment from '../useSegment/useSegment';
import { getScreenName } from '../useSegment/utils';

const useHandleOpenModal = (actionHandler?: VoidFunction) => {
  const [params] = useSearchParams();
  const { modalCancelOrExitEvent, campaignCreationFlowAbandonmentEvent } = useSegment();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { data } = useLever();
  const currentPage = window.location.pathname.split('/');
  const arrayPosition = 2;
  const { onOpenDialog, Dialog } = useGenericDialog((buttonLabel, buttonName) => {
    modalCancelOrExitEvent({
      buttonLabel,
      buttonName,
      modalName: formatMessage({ id: 'LeaveDialog.Title' }),
      screenName: getScreenName(currentPage[arrayPosition]),
      campaignId: data?.id
    });
    navigate(-1);
  });

  const handleOpenDialog = useCallback(() => {
    onOpenDialog({
      title: formatMessage({ id: 'LeaveDialog.Title' }),
      body: <Paragraph>{formatMessage({ id: 'LeaveDialog.Description' })}</Paragraph>,
      confirmMessage: formatMessage({ id: 'LeaveDialog.Leave' }),
      cancelMessage: formatMessage({ id: 'LeaveDialog.CancelOnSideBar' }),
      buttonConfirmColor: 'destructive',
      onConfirm: () => {
        campaignCreationFlowAbandonmentEvent({
          buttonLabel: formatMessage({ id: 'LeaveDialog.Leave' }),
          buttonName: 'Yes, leave'
        });
        navigate(AUDIENCES_URL, { replace: true });
      }
    });
  }, []);

  useEffect(() => {
    const action = params.get('action');
    const isAudienceBuilder = action === 'audience-builder';

    if (isAudienceBuilder && actionHandler) actionHandler();
    if (isAudienceBuilder && !actionHandler) handleOpenDialog();
  }, [params, handleOpenDialog, actionHandler]);

  return {
    Dialog
  };
};

export default useHandleOpenModal;
