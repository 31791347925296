import { createContext, useCallback, useMemo, useReducer } from 'react';
import { ISummaryContext, ISummaryContextData } from './SummaryContext.d';

const SummaryContext = createContext({} as ISummaryContext);

function reducer(state: any, action): ISummaryContextData {
  return { ...state, ...action.payload };
}

const initialState: ISummaryContextData = {
  popParams: {
    id: '',
    value: null,
    status: ''
  }
};

function SummaryProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onUpdatePopEstimatedId = useCallback(
    (popEstimatedId: string) => {
      if (popEstimatedId)
        dispatch({ payload: { popParams: { ...state.popParams, id: popEstimatedId } } });
    },
    [dispatch, state.popParams]
  );

  const onUpdatePopParams = useCallback((popParams) => {
    dispatch({ payload: { popParams } });
  }, []);

  const contextValue = useMemo(() => {
    return {
      updatePopEstimatedId: onUpdatePopEstimatedId,
      updatePopParams: onUpdatePopParams,
      popParams: {
        id: state.popParams.id,
        value: state.popParams.value,
        status: state.popParams.status
      }
    };
  }, [onUpdatePopEstimatedId, onUpdatePopParams, state.popParams]);

  return <SummaryContext.Provider value={contextValue}>{children}</SummaryContext.Provider>;
}

export { SummaryContext, SummaryProvider };
