import { BASE_URL } from '../../../constants';
import {
  campaignListingPageViewed,
  listingActionPerformed,
  listingCreateNewCampaignClicked,
  listingItemsPerPageChange,
  listingSortInteraction
} from '../../../services/segment/segment';
import { segmentScreenNames } from '../utils';
import {
  ListingActionPerformedProps,
  ListingItemsPerPageChangeProps,
  ListingPageViewedProps,
  ListingSortInteractionProps
} from './campaignListingPageEvents.d';

const currentUrl = window.location.href;
const actualEnvironmentUrl = window.location.origin;
const listCampaignsScreenName = segmentScreenNames.CampaignListPage;

export const campaignListingPageViewedEvent = ({
  campaignCount,
  itemsPerPage,
  pageNumber,
  referrer
}: ListingPageViewedProps) => {
  campaignListingPageViewed({
    campaign_count: campaignCount,
    items_per_page: itemsPerPage,
    page_number: pageNumber,
    screen_name: listCampaignsScreenName,
    url: `${actualEnvironmentUrl}${BASE_URL}`,
    referrer
  });
};

export const listingItemsPerPageChangeEvent = ({
  buttonLabel,
  buttonName,
  itemsPerPage,
  previousItemsPerPage
}: ListingItemsPerPageChangeProps) => {
  listingItemsPerPageChange({
    button_label: buttonLabel,
    button_name: buttonName,
    items_per_page: itemsPerPage,
    previous_items_per_page: previousItemsPerPage,
    url: currentUrl,
    screen_name: listCampaignsScreenName
  });
};

export const listingSortInteractionEvent = ({
  sortColumnHeaderLabel,
  sortColumnHeaderName,
  sortType,
  referrer
}: ListingSortInteractionProps) => {
  listingSortInteraction({
    sort_column_header_label: sortColumnHeaderLabel,
    sort_column_header_name: sortColumnHeaderName,
    sort_type: sortType,
    screen_name: listCampaignsScreenName,
    url: currentUrl,
    referrer
  });
};

export const listingCreateNewCampaignClickedEvent = (label: string) => {
  listingCreateNewCampaignClicked({
    button_label: label,
    button_name: 'Create campaign button',
    screen_name: listCampaignsScreenName,
    url: currentUrl
  });
};

export const listingActionPerformedEvent = ({
  buttonAction,
  buttonLabel,
  buttonName,
  campaignId,
  campaignPagePosition,
  campaignPeriod,
  campaignStatus,
  destinationUrl,
  itemsPerPage,
  pageNumber,
  sortColumnHeaderName,
  sortType
}: ListingActionPerformedProps) => {
  listingActionPerformed({
    button_action: buttonAction,
    button_label: buttonLabel,
    button_name: buttonName,
    campaign_id: campaignId,
    campaign_page_position: campaignPagePosition,
    campaign_period: campaignPeriod,
    campaign_status: campaignStatus,
    destination_url: `${actualEnvironmentUrl}${destinationUrl}`,
    items_per_page: itemsPerPage,
    page_number: pageNumber,
    sort_column_header_name: sortColumnHeaderName,
    sort_type: sortType,
    screen_name: listCampaignsScreenName,
    url: `${actualEnvironmentUrl}${BASE_URL}`
  });
};
