// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter

/**
 * Triggered when a user updates the allocation percentage determining the portion of the
 * defined audience to receive the promotion
 */
export interface AllocationPercentageUpdated {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The country where the offer will act
   */
  country: null | string;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: AllocationPercentageUpdatedMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * The updated allocation percentage determining the portion of the defined audience to
   * receive the promotion.
   */
  updated_allocation_percentage: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface AllocationPercentageUpdatedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered every time the total estimated accounts is recalculated if the portfolio
 * optimization is active for the selected audience
 */
export interface AudienceCalculationCompleted {
  /**
   * The ID Key for the audience being used in the campaign
   */
  audience_id: null | string;
  /**
   * The audience name provided when creating the audience
   */
  audience_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The initial amount of estimated targeted accounts for this audience before applying
   * portfolio optimization model
   */
  initial_accounts_targeted: number | null;
  /**
   * The total amount of estimated targeted accounts for this audience after SKU selection
   * when portfolio optimization is active
   */
  recalculated_accounts_targeted: number | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The sku list array. Attention: Array props should directly contain the sub objects, not
   * the 'items' as an object like in the Protocols UI
   */
  sku_list: AudienceCalculationCompletedSkuList[] | null;
  /**
   * The Strategy label displayed to the user. Samples:'Introduce, 'Introduzir''
   */
  strategy_label: null | string;
  /**
   * The Strategy name. Samples: Introduce
   */
  strategy_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface AudienceCalculationCompletedSkuList {
  /**
   * Represent the SKUs selected for the campaign
   */
  sku: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user starts the creation of a new Audience by clicking on the "Create
 * new audience" button.
 */
export interface AudienceCreationStarted {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The country where the offer will act
   */
  country: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clicks on "Save Audiences" when creating or editing an audience.
 */
export interface AudienceSaved {
  /**
   * The ID Key for the audience being used in the campaign
   */
  audience_id: null | string;
  /**
   * The audience name provided when creating the audience
   */
  audience_name: null | string;
  /**
   * How many Audience traits were on the modal traits list
   */
  audience_traits_count: number | null;
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The country where the offer will act
   */
  country: null | string;
  /**
   * The creation method being used, whether the user selected filters or uploaded CSVs
   */
  creation_method: null | string;
  /**
   * The current number of CSV files uploaded (Null for 'Select filters' creation method)
   */
  csv_files_count: number | null;
  /**
   * Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the
   * url the user will be redirected to)
   */
  destination_url: null | string;
  /**
   * The initial amount of estimated targeted accounts for this audience before applying
   * portfolio optimization model
   */
  initial_accounts_targeted: number | null;
  /**
   * If portfolio optimization is active for the audience selected
   */
  is_portfolio_optimization_active: IsPortfolioOptimizationActive | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

export enum IsPortfolioOptimizationActive {
  No = 'NO',
  Yes = 'YES'
}

/**
 * Triggered when the user selects an audience in the Audience Selection page.
 */
export interface AudienceSelected {
  /**
   * The ID Key for the audience being used in the campaign
   */
  audience_id: null | string;
  /**
   * The audience name provided when creating the audience
   */
  audience_name: null | string;
  /**
   * How many Audience traits were on the modal traits list
   */
  audience_traits_count: number | null;
  /**
   * The country where the offer will act
   */
  country: null | string;
  /**
   * The creation method being used, whether the user selected filters or uploaded CSVs
   */
  creation_method: null | string;
  /**
   * Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the
   * url the user will be redirected to)
   */
  destination_url: null | string;
  /**
   * The initial amount of estimated targeted accounts for this audience before applying
   * portfolio optimization model
   */
  initial_accounts_targeted: number | null;
  /**
   * If portfolio optimization is active for the audience selected
   */
  is_portfolio_optimization_active: IsPortfolioOptimizationActive | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user loads the Audience Selection page on the offer creation flow.
 */
export interface AudienceSelectionPageViewed {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default
   * Page Viewed Event Property
   */
  referrer: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user loads the Show all Audience Traits modal
 */
export interface AudienceTraitsModalViewed {
  /**
   * How many Audience traits were on the modal traits list
   */
  audience_traits_count: number | null;
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The modal name
   */
  modal_name: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when a user updates the Budget ID
 */
export interface BudgetIDUpdated {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The country where the offer will act
   */
  country: null | string;
  /**
   * A flag indicating whether an error message was shown to the user due to an invalid input
   * (e.g., 0 or empty value).
   */
  error_message_shown: boolean | null;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: BudgetIDUpdatedMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * The updated Budget ID
   */
  updated_budget_id: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface BudgetIDUpdatedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clicks on the button to finalize campaign creation
 */
export interface CampaignCreationFinished {
  /**
   * How many Audience traits were on the modal traits list
   */
  audience_traits_count: number | null;
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The country where the offer will act
   */
  country: null | string;
  /**
   * Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the
   * url the user will be redirected to)
   */
  destination_url: null | string;
  /**
   * The offer end date
   */
  end_date: null | string;
  /**
   * The execution type selected by the user (e.g. Single SKU, Multiple SKUs).
   */
  execution_type: null | string;
  /**
   * The initial amount of estimated targeted accounts for this audience before applying
   * portfolio optimization model
   */
  initial_accounts_targeted: number | null;
  /**
   * If portfolio optimization is active for the audience selected
   */
  is_portfolio_optimization_active: IsPortfolioOptimizationActive | null;
  /**
   * How many mechanism types were selected for the campaign
   */
  mechanism_count: number | null;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: CampaignCreationFinishedMechanismType[] | null;
  /**
   * The total amount of estimated targeted accounts for this audience after SKU selection
   * when portfolio optimization is active
   */
  recalculated_accounts_targeted: number | null;
  /**
   * Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default
   * Page Viewed Event Property
   */
  referrer: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * How many skus were listed on the SKU component when the event occured
   */
  sku_list_count: number | null;
  /**
   * The offer start date
   */
  start_date: null | string;
  /**
   * The Strategy label displayed to the user. Samples:'Introduce, 'Introduzir''
   */
  strategy_label: null | string;
  /**
   * The Strategy name. Samples: Introduce
   */
  strategy_name: null | string;
  /**
   * The Sub Strategy label displayed to the user. Samples:'I1: Attract customers to try new
   * products, 'G6: Trade program - discount per target achievement ''
   */
  sub_strategy_label: null | string;
  /**
   * The Sub Strategy name. Samples: I1, S1
   */
  sub_strategy_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface CampaignCreationFinishedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when the leaves the creation flow without saving
 */
export interface CampaignCreationFlowAbandonment {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * Boolean to indicate rather the event was triggered in a modal
   */
  modal_scenario: ModalScenario | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

export enum ModalScenario {
  False = 'FALSE',
  True = 'TRUE'
}

/**
 * Triggered when the user loads the Campaign Details page
 */
export interface CampaignDetailsPageViewed {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default
   * Page Viewed Event Property
   */
  referrer: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when a user updates the campaign group ID for a mechanism or touchpoint.
 */
export interface CampaignGroupIDUpdated {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: CampaignGroupIDUpdatedMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * The updated campaign group ID as typed by the user.
   */
  updated_group_id: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface CampaignGroupIDUpdatedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user loads the Campaign Listing page on the creation flow
 */
export interface CampaignListingPageViewed {
  /**
   * The total campaign count
   */
  campaign_count: number | null;
  /**
   * The current items per page option
   */
  items_per_page: number | null;
  /**
   * The number of the page the user was at when the event occurred
   */
  page_number: number | null;
  /**
   * Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default
   * Page Viewed Event Property
   */
  referrer: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user loads the Campaign Settings page on the creation flow
 */
export interface CampaignSettingsPageViewed {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default
   * Page Viewed Event Property
   */
  referrer: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user loads the Campaign strategy page on the creation flow
 */
export interface CampaignStrategyPageViewed {
  /**
   * Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default
   * Page Viewed Event Property
   */
  referrer: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user loads the Campaign Summary page on the creation flow
 */
export interface CampaignSummaryPageViewed {
  /**
   * The status of the recalculation of accounts targeted when the audience has portfolio
   * optiomization active
   */
  accounts_recalculation_status: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The initial amount of estimated targeted accounts for this audience before applying
   * portfolio optimization model
   */
  initial_accounts_targeted: number | null;
  /**
   * If portfolio optimization is active for the audience selected
   */
  is_portfolio_optimization_active: IsPortfolioOptimizationActive | null;
  /**
   * Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default
   * Page Viewed Event Property
   */
  referrer: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clicks on the button to delete the selected cover image or the
 * button to change the selected cover image.
 */
export interface CoverImageModificationTriggered {
  /**
   * The type of action the user is taking, it could be 'delete', 'change' or 'add'.
   */
  action_type: null | string;
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the
   * url the user will be redirected to)
   */
  destination_url: null | string;
  /**
   * The file name of the selected image in the file explorer.
   */
  image_file_name: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user selects a date range.
 */
export interface DateRangeSelected {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The offer end date
   */
  end_date: null | string;
  /**
   * The end date selected by the user for the touchpoint within the campaign date range.
   */
  mechanism_end_date: null | string;
  /**
   * The start date selected by the user for the touchpoint within the campaign date range.
   */
  mechanism_start_date: null | string;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: DateRangeSelectedMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The offer start date
   */
  start_date: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface DateRangeSelectedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when a user updates the discount in a promotion
 */
export interface DiscountValueUpdated {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The country where the offer will act
   */
  country: null | string;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: DiscountValueUpdatedMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * The array of updated discount. Attention ! Array props should directly contain the sub
   * objects, not the ''items'' as an object like in the Protocols UI
   */
  updated_discount?: DiscountValueUpdatedUpdatedDiscount[] | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface DiscountValueUpdatedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array.
 */
export interface DiscountValueUpdatedUpdatedDiscount {
  /**
   * The field tittle property.
   */
  field_title: null | string;
  /**
   * The updated value property.
   */
  updated_value: number | null;
  [property: string]: any;
}

/**
 * Triggered when the user selects an execution type for a specific touchpoint.
 */
export interface ExecutionTypeSelected {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The execution type selected by the user (e.g. Single SKU, Multiple SKUs).
   */
  execution_type: null | string;
  /**
   * If portfolio optimization is active for the audience selected
   */
  is_portfolio_optimization_active: IsPortfolioOptimizationActive | null;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: ExecutionTypeSelectedMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface ExecutionTypeSelectedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clicks os the "Next" Button on Creation Flow
 */
export interface ForwardStepperNavigation {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the
   * url the user will be redirected to)
   */
  destination_url: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clicks on the Go to Touchpoitn details button  on the Show SKUs
 * modal
 */
export interface GoToTouchpointDetailsButtonClicked {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * How many SKUs without Touchpoinsts associated were on the SKU list
   */
  skus_without_touchpoints_count: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clicks on a Action inside the Action list for a Offer on the
 * Offer Listing page
 */
export interface ListingActionPerformed {
  /**
   * The action performed by the user within the available actions list
   */
  button_action: null | string;
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The number that correspods to the position of the campaign beign clicked on the current
   * page
   */
  campaign_page_position: number | null;
  /**
   * The Campaign period that has been selected
   */
  campaign_period: null | string;
  /**
   * The current campaign status
   */
  campaign_status: null | string;
  /**
   * Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the
   * url the user will be redirected to)
   */
  destination_url: null | string;
  /**
   * The current items per page option
   */
  items_per_page: number | null;
  /**
   * The number of the page the user was at when the event occurred
   */
  page_number: number | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The sorted column header name. Samples: Status, Campaign Name
   */
  sort_column_header_name: null | string;
  /**
   * The type of sorting the user is performing
   */
  sort_type: SortType | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

export enum SortType {
  Ascending = 'ASCENDING',
  Default = 'DEFAULT',
  Descending = 'DESCENDING'
}

/**
 * Triggered when the user clicks on the "Create new campaign" Button on the Campaign
 * Listing page
 */
export interface ListingCreateNewCampaignClicked {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered  when the user changes the current items per page option
 */
export interface ListingItemsPerPageChange {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The current items per page option
   */
  items_per_page: number | null;
  /**
   * Previous items per page option
   */
  previous_items_per_page: number | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user sorts a column by clicking on its header
 */
export interface ListingSortInteraction {
  /**
   * Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default
   * Page Viewed Event Property
   */
  referrer: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The sorted  column header label displayed to the user. Samples:'Campaign Name", "Nome da
   * Campanha"
   */
  sort_column_header_label: null | string;
  /**
   * The sorted column header name. Samples: Status, Campaign Name
   */
  sort_column_header_name: null | string;
  /**
   * The type of sorting the user is performing
   */
  sort_type: SortType | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when a user updates the description of a mechanism or touchpoint.
 */
export interface MechanismDescriptionUpdated {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The description of the mechanism as updated by the user. This description will be
   * displayed to users on the customer app.
   */
  mechanism_description: null | string;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: MechanismDescriptionUpdatedMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface MechanismDescriptionUpdatedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when a user updates the title of a mechanism or touchpoint.
 */
export interface MechanismTitleUpdated {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The title of the mechanism as updated by the user. This title will be displayed to users
   * on the customer app.
   */
  mechanism_title: null | string;
  /**
   * The error generated by a new campaign name that is not valid.
   */
  mechanism_title_error: EError | null;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: MechanismTitleUpdatedMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

export enum EError {
  AlreadyInUse = 'ALREADY_IN_USE',
  TooShort = 'TOO_SHORT'
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface MechanismTitleUpdatedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clicks on a Touchpoint Mechanism Type check box
 */
export interface MechanismTypeCheckBoxInteraction {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The toggle state the user is changing the check box to.
   */
  check_box_action: CheckBoxAction | null;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: MechanismTypeCheckBoxInteractionMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The search text being used when the event occured, if there is any
   */
  search_text: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

export enum CheckBoxAction {
  Select = 'SELECT',
  Unselect = 'UNSELECT'
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface MechanismTypeCheckBoxInteractionMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered  when the user interacts with the side bar menu in the application
 */
export interface MenuInteraction {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the
   * url the user will be redirected to)
   */
  destination_url: null | string;
  /**
   * Top level Menu Category in the given context, always in English. Samples:  Dashboard,
   * Report_orders, Report_Customer.
   */
  menu_category: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user does not confirm a action by leaving a modal, or clicking on its
 * "cancel" button
 */
export interface ModalCancelOrExit {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The modal name
   */
  modal_name: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered to indicate the success or failure of the offer publication process
 */
export interface OfferPublicationStatus {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the
   * url the user will be redirected to)
   */
  destination_url: null | string;
  /**
   * If an error occurred
   */
  publish_status: PublishStatus | null;
  /**
   * Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default
   * Page Viewed Event Property
   */
  referrer: null | string;
  [property: string]: any;
}

export enum PublishStatus {
  Error = 'ERROR',
  Success = 'SUCCESS'
}

/**
 * Triggered when a user updates the amount of times an account can make a purchase within
 * this promotion
 */
export interface OrderAvailabilityUpdated {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The country where the offer will act
   */
  country: null | string;
  /**
   * A flag indicating whether an error message was shown to the user due to an invalid input
   * (e.g., 0 or empty value).
   */
  error_message_shown: boolean | null;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: OrderAvailabilityUpdatedMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * The updated order availability data
   */
  updated_order_availability: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface OrderAvailabilityUpdatedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when a user modifies the number of points that can be earned for completing a
 * points-based offer.
 */
export interface PointsBasedOfferPointsModified {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * A flag indicating whether an error message was shown to the user due to an invalid input
   * (e.g., 0 or empty value).
   */
  error_message_shown: boolean | null;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: PointsBasedOfferPointsModifiedMechanismType[] | null;
  /**
   * The method used by the user to modify the points (e.g., "typing", "arrow_up",
   * "arrow_down").
   */
  modification_method: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * The updated number of points that can be earned for completing the points-based offer.
   */
  updated_points: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface PointsBasedOfferPointsModifiedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clicks on a SKU check box
 */
export interface SKUCheckBoxInteraction {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The toggle state the user is changing the check box to.
   */
  check_box_action: CheckBoxAction | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The search text being used when the event occured, if there is any
   */
  search_text: null | string;
  /**
   * How many skus were listed on the SKU component when the event occured
   */
  sku_list_count: number | null;
  /**
   * The position of the SKU being selected or unselected on the current list ordering from 1
   * to n.
   */
  sku_list_position: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clears the current search by clicking on the search "x" or on the
 * "Clear search" button or if they delete all text from the search bar.
 */
export interface SKUClearSearch {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The search text being used when the event occured, if there is any
   */
  search_text: null | string;
  /**
   * The method used by the user to perform an action that triggered the event
   */
  trigger_method: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user unselects all SKU currently selected clicking on "x Clear
 * selection"
 */
export interface SKUClearSelection {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The search text being used when the event occured, if there is any
   */
  search_text: null | string;
  /**
   * How many skus were listed on the SKU component when the event occured
   */
  sku_list_count: number | null;
  /**
   * How many new skus were selected when the event occurred
   */
  sku_new_selection_count?: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when a user updates the total limit of SKUs in a promotion
 */
export interface SKULimitUpdated {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The country where the offer will act
   */
  country: null | string;
  /**
   * A flag indicating whether an error message was shown to the user due to an invalid input
   * (e.g., 0 or empty value).
   */
  error_message_shown: boolean | null;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: SKULimitUpdatedMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * The updated total amount of SKUs a POC can buy with this discount, among all orders
   */
  updated_sku_limit_quantity: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface SKULimitUpdatedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when the SKU list fails to load
 */
export interface SKULoadError {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default
   * Page Viewed Event Property
   */
  referrer: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when a user updates the maximum quantity of SKUs in a promotion
 */
export interface SKUMaximumQuantityUpdated {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The country where the offer will act
   */
  country: null | string;
  /**
   * A flag indicating whether an error message was shown to the user due to an invalid input
   * (e.g., 0 or empty value).
   */
  error_message_shown: boolean | null;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: SKUMaximumQuantityUpdatedMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * The updated maximum amount of products the POC can buy in a single order with this
   * promotional price
   */
  updated_maximum_quantity: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface SKUMaximumQuantityUpdatedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when a user updates the minimum quantity of SKUs in a promotion
 */
export interface SKUMinimumQuantityUpdated {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The country where the offer will act
   */
  country: null | string;
  /**
   * A flag indicating whether an error message was shown to the user due to an invalid input
   * (e.g., 0 or empty value).
   */
  error_message_shown: boolean | null;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: SKUMinimumQuantityUpdatedMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * The updated minimum amount of SKUs that a POC needs to buy to earn this offer
   */
  updated_minimum_quantity: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface SKUMinimumQuantityUpdatedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user confirms the quantity of each SKU included in a challenge within
 * the modal window.
 */
export interface SKUQuantityUpdated {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the
   * url the user will be redirected to)
   */
  destination_url: null | string;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: SKUQuantityUpdatedMechanismType[] | null;
  /**
   * The modal name
   */
  modal_name: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The sku updates list array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  sku_updates: SkuUpdate[] | null;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface SKUQuantityUpdatedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface SkuUpdate {
  /**
   * The number of times the user clicked the "+" or "-" buttons to update the SKU quantity
   * before confirming the changes
   */
  action_count: number | null;
  /**
   * The previous quatity
   */
  previous_quantity: number | null;
  /**
   * The SKU Identification.
   */
  sku_id: null | string;
  /**
   * The update quantity
   */
  updated_quantity: number | null;
  [property: string]: any;
}

/**
 * Triggered when the user reloads the current SKU list by clicking on the "Reload list"
 * button
 */
export interface SKUReloadListClicked {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The reload status. FAIL is for when the SKUs list load fails again. SUCCESS if for when
   * the SKUs list load is successful after the reload.
   */
  reload_status: ReloadStatus | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

export enum ReloadStatus {
  Fail = 'FAIL',
  Success = 'SUCCESS'
}

/**
 * Triggered when a user removes an SKU from their selection in the table.
 */
export interface SKURemoved {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: SKURemovedMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The ID of the SKU involved in the user action.
   */
  sku_id: null | string;
  /**
   * How many skus were listed on the SKU component when the event occured
   */
  sku_list_count: number | null;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface SKURemovedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user triggers the SKU search engine by any means
 */
export interface SKUSearch {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * How many skus were on the search resulting list
   */
  sku_search_result_count: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when a user SKU Select All Clicked.
 */
export interface SKUSelectAllClicked {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The search text being used when the event occured, if there is any
   */
  search_text: null | string;
  /**
   * How many skus were listed on the SKU component when the event occured
   */
  sku_list_count: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user confirms a SKU selection by clicking on the "Select SKUs" button
 */
export interface SKUSelectionConfirmation {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The search text being used when the event occured, if there is any
   */
  search_text: null | string;
  /**
   * How many skus were listed on the SKU component when the event occured
   */
  sku_list_count: number | null;
  /**
   * How many new skus were selected when the event occurred
   */
  sku_new_selection_count?: number | null;
  /**
   * How many skus were previously selected before the user opened the SKU component for this
   * time
   */
  sku_previous_selection_count: number | null;
  /**
   * How many skus were unselected when the event occurred
   */
  sku_unselection_count: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user leaves the SKU selection component by clicking on the "x" button
 */
export interface SKUSelectionMenuExit {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The search text being used when the event occured, if there is any
   */
  search_text: null | string;
  /**
   * How many skus were listed on the SKU component when the event occured
   */
  sku_list_count: number | null;
  /**
   * How many new skus were selected when the event occurred
   */
  sku_new_selection_count?: number | null;
  /**
   * How many skus were previously selected before the user opened the SKU component for this
   * time
   */
  sku_previous_selection_count: number | null;
  /**
   * How many skus were unselected when the event occurred
   */
  sku_unselection_count: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clicks on the button to open a modal window and update the
 * quantity of each SKU or the SKU list included in a touchpoint.
 */
export interface SKUSelectionTriggered {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the
   * url the user will be redirected to)
   */
  destination_url: null | string;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: SKUSelectionTriggeredMechanismType[] | null;
  /**
   * Boolean to indicate rather the event was triggered in a modal
   */
  modal_scenario: ModalScenario | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The sku list array. Attention: Array props should directly contain the sub objects, not
   * the 'items' as an object like in the Protocols UI
   */
  sku_list: SKUSelectionTriggeredSkuList[] | null;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface SKUSelectionTriggeredMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface SKUSelectionTriggeredSkuList {
  /**
   * Represent the SKUs selected for the campaign
   */
  sku: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clicks os the "Save and Exit" Button on Creation Flow
 */
export interface SaveAndExitClicked {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the
   * url the user will be redirected to)
   */
  destination_url: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user opens the touchpoint selection modal by clicking in a button
 */
export interface SelectTouchpointButtonClicked {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clicks os the "Add SKU" Button on the Campaign Creation Settings
 * page
 */
export interface SettingsAddSKUClicked {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * Boolean to indicate rather the event was triggered in a modal
   */
  modal_scenario: ModalScenario | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * How many skus were selected when the event occurred
   */
  sku_selection_count: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user fills or changes the Name Field on the Campaign Settings page on
 * the creation flow
 */
export interface SettingsNameProvided {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The new campaign name being provided by the user
   */
  campaign_name: null | string;
  /**
   * The error generated by a new campaign name that is not valid
   */
  campaign_name_error: EError | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user fills or changes the Period Field on the Campaign Settings page
 * on the creation flow
 */
export interface SettingsPeriodSelected {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The Campaign period that has been selected
   */
  campaign_period: null | string;
  /**
   * Boolean to indicate rather the expired campaign period warning is on display
   */
  expired_campaign_period: ModalScenario | null;
  /**
   * Boolean to indicate rather the event was triggered in a modal
   */
  modal_scenario: ModalScenario | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clicks os the trash can Button on the Campaign Creation Settings
 * page to remove a Sku or when they confirm this on the modal
 */
export interface SettingsRemoveSKU {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * Boolean to indicate rather the event was triggered in a modal
   */
  modal_scenario: ModalScenario | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * How many skus were selected when the event occurred
   */
  sku_selection_count: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clicks on the Show all button inside the Audience card
 */
export interface ShowAllAudiencesButtonClicked {
  /**
   * How many Audience traits were on the modal traits list
   */
  audience_traits_count: number | null;
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clicks on the See Analytics button
 */
export interface ShowAnalyticsClicked {
  /**
   * How many Audience traits were on the modal traits list
   */
  audience_traits_count: number | null;
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The country where the offer will act
   */
  country: null | string;
  /**
   * Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the
   * url the user will be redirected to)
   */
  destination_url: null | string;
  /**
   * The offer end date
   */
  end_date: null | string;
  /**
   * How many mechanism types were selected for the campaign
   */
  mechanism_count: number | null;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: ShowAnalyticsClickedMechanismType[] | null;
  /**
   * The category of the offer. Samples: Promotions, Rewards
   */
  offer_category: null | string;
  /**
   * The offer status at the moment the event occured
   */
  offer_status: null | string;
  /**
   * Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default
   * Page Viewed Event Property
   */
  referrer: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * How many skus were listed on the SKU component when the event occured
   */
  sku_list_count: number | null;
  /**
   * The offer start date
   */
  start_date: null | string;
  /**
   * The Strategy label displayed to the user. Samples:'Introduce, 'Introduzir''
   */
  strategy_label: null | string;
  /**
   * The Strategy name. Samples: Introduce
   */
  strategy_name: null | string;
  /**
   * The Sub Strategy label displayed to the user. Samples:'I1: Attract customers to try new
   * products, 'G6: Trade program - discount per target achievement ''
   */
  sub_strategy_label: null | string;
  /**
   * The Sub Strategy name. Samples: I1, S1
   */
  sub_strategy_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface ShowAnalyticsClickedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clicks on the Show SKUs button inside the SKUs without touchpoint
 * associated warning message
 */
export interface ShowSKUsButtonClicked {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * How many SKUs without Touchpoinsts associated were on the SKU list
   */
  skus_without_touchpoints_count: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user loads the Show SKUs modal
 */
export interface ShowSKUsModalViewed {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The modal name
   */
  modal_name: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * How many SKUs without Touchpoinsts associated were on the SKU list
   */
  skus_without_touchpoints_count: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user clicks on a Edit button from Summary Page
 */
export interface StepEditionFromSummaryPage {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the
   * url the user will be redirected to)
   */
  destination_url: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user selects a Strategy
 */
export interface StrategySelected {
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The Strategy label displayed to the user. Samples:'Introduce, 'Introduzir''
   */
  strategy_label: null | string;
  /**
   * The Strategy name. Samples: Introduce
   */
  strategy_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user selects a Sub Strategy
 */
export interface SubStrategySelected {
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The Strategy label displayed to the user. Samples:'Introduce, 'Introduzir''
   */
  strategy_label: null | string;
  /**
   * The Strategy name. Samples: Introduce
   */
  strategy_name: null | string;
  /**
   * The Sub Strategy label displayed to the user. Samples:'I1: Attract customers to try new
   * products, 'G6: Trade program - discount per target achievement ''
   */
  sub_strategy_label: null | string;
  /**
   * The Sub Strategy name. Samples: I1, S1
   */
  sub_strategy_name: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Triggered when a user updates the volume or money target for a points-based offer
 */
export interface TargetUpdated {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: TargetUpdatedMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The type of target updated by the user. This could be "volume" for volume-based offers or
   * "money" for spend-based offers.
   */
  target_type: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * The updated target set by the user.
   */
  updated_target: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface TargetUpdatedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when a user adds or removes a tier in a Stepped Discounts promotion.
 */
export interface TierActionPerformed {
  /**
   * The type of action the user is taking, it could be 'delete', 'change' or 'add'.
   */
  action_type: null | string;
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the
   * url the user will be redirected to)
   */
  destination_url: null | string;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: TierActionPerformedMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The number of the tier being added.
   */
  tier_number: number | null;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * The array of updated discount. Attention ! Array props should directly contain the sub
   * objects, not the ''items'' as an object like in the Protocols UI
   */
  updated_discount?: TierActionPerformedUpdatedDiscount[] | null;
  /**
   * The updated maximum amount of products the POC can buy in a single order with this
   * promotional price
   */
  updated_maximum_quantity: number | null;
  /**
   * The updated minimum amount of SKUs that a POC needs to buy to earn this offer
   */
  updated_minimum_quantity: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface TierActionPerformedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array.
 */
export interface TierActionPerformedUpdatedDiscount {
  /**
   * The field tittle property.
   */
  field_title: null | string;
  /**
   * The updated value property.
   */
  updated_value: number | null;
  [property: string]: any;
}

/**
 * Triggered when a user interacts with any toggle in the application
 */
export interface ToggleInteraction {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The country where the offer will act
   */
  country: null | string;
  /**
   * The modal name
   */
  modal_name: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The Strategy label displayed to the user. Samples:'Introduce, 'Introduzir''
   */
  strategy_label: null | string;
  /**
   * The Strategy name. Samples: Introduce
   */
  strategy_name: null | string;
  /**
   * The Sub Strategy label displayed to the user. Samples:'I1: Attract customers to try new
   * products, 'G6: Trade program - discount per target achievement ''
   */
  sub_strategy_label: null | string;
  /**
   * The Sub Strategy name. Samples: I1, S1
   */
  sub_strategy_name: null | string;
  /**
   * The state of the toggle after interaction
   */
  toggle_state: ToggleState;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * The state of the toggle after interaction
 */
export enum ToggleState {
  Off = 'OFF',
  On = 'ON'
}

/**
 * Triggered when the user interacts with a touchpoint category by collapsing or expanding
 * the category.
 */
export interface TouchpointCategoryInteraction {
  /**
   * The modal name
   */
  modal_name: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * Indicates the final state of the touchpoint type after interaction.
   */
  touchpoint_action: TouchpointAction | null;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

export enum TouchpointAction {
  Collapsed = 'COLLAPSED',
  Expanded = 'EXPANDED'
}

/**
 * Triggered when the user excludes a touchpoint
 */
export interface TouchpointDeleted {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: TouchpointDeletedMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface TouchpointDeletedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user loads the Touchpoints and Mechanics Selection page on the
 * creation flow.
 */
export interface TouchpointDetailsPageViewed {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: TouchpointDetailsPageViewedMechanismType[] | null;
  /**
   * Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default
   * Page Viewed Event Property
   */
  referrer: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface TouchpointDetailsPageViewedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user confirms the selection of a touchpoint.
 */
export interface TouchpointSelected {
  /**
   * The button label displayed to the user, including localization
   */
  button_label: null | string;
  /**
   * The button name on the back end
   */
  button_name: null | string;
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: TouchpointSelectedMechanismType[] | null;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface TouchpointSelectedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user loads the Touchpoints and Mechanics Selection page on the
 * creation flow
 */
export interface TouchpointSelectionPageViewed {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The machanism_types array. Attention: Array props should directly contain the sub
   * objects, not the 'items' as an object like in the Protocols UI
   */
  mechanism_types: TouchpointSelectionPageViewedMechanismType[] | null;
  /**
   * Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default
   * Page Viewed Event Property
   */
  referrer: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The type of touchpoint selected. Samples: Promotions, Rewards
   */
  touchpoint_type?: null | string;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Do not implement with the 'items' structure/object, it's a normal array
 */
export interface TouchpointSelectionPageViewedMechanismType {
  /**
   * Which touchpoints were selected.
   */
  mechanism_type: null | string;
  [property: string]: any;
}

/**
 * Triggered when the user triggers the touchpoints search engine by any means
 */
export interface TouchpointsSearch {
  /**
   * The Campaign id
   */
  campaign_id: null | string;
  /**
   * The name of the screen where the user is taking this action. Samples: Campaign Strategy
   * Page
   */
  screen_name: null | string;
  /**
   * The search query entered by the user.
   */
  touchpoint_search_query: null | string;
  /**
   * How many touchpoints were on the search resulting list
   */
  touchpoint_search_result_count: number | null;
  /**
   * Full URL of the page. First we look for the canonical url. If the canonical url is not
   * provided, we use location.href fr...
   */
  url: null | string;
  [property: string]: any;
}

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv, { ErrorObject } from 'ajv';

/**
 * The analytics.js snippet should be available via window.analytics.
 * You can install it by following instructions at: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 * Make sure to also include the TypeScript declarations with: `npm install @segment/analytics-next` (install with --save-dev for type definitions only).
 *
 * If you don't want to use the snippet, you can also install the `@segment/analytics-next` library as a *production* dependency and use it like this:
 * ```ts
 * import { AnalyticsBrowser } from '@segment/analytics-next'
 * import { setTypewriterOptions } from './analytics'
 *
 * const analytics = AnalyticsBrowser.load({ writeKey: 'SEGMENT_WRITE_KEY' })
 *
 * setTypewriterOptions({ analytics: analytics })
 */
import type {
  AnalyticsSnippet,
  Analytics,
  AnalyticsBrowser,
  Options
} from '@segment/analytics-next';

declare global {
  interface Window {
    analytics: AnalyticsSnippet;
  }
}

type AnyAnalytics = AnalyticsSnippet | Analytics | AnalyticsBrowser;

/** The callback exposed by analytics.js. */
export type Callback = () => void;

export type ViolationHandler = (message: Record<string, any>, violations: ErrorObject[]) => void;

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (message, violations) => {
  const msg = JSON.stringify(
    {
      type: 'Typewriter JSON Schema Validation Error',
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        'Tracking Plan spec.',
      errors: violations
    },
    undefined,
    2
  );

  console.warn(msg);
};

let onViolation = defaultValidationErrorHandler;

let analytics: () => AnyAnalytics | undefined = () => {
  return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: AnyAnalytics;
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics ? () => options.analytics || window.analytics : analytics;
  onViolation = options.onViolation || onViolation;
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
  const ajv = new Ajv({ allErrors: true, verbose: true });

  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors);
  }
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Options = {}): Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: 'typescript',
        version: '9.2.0'
      }
    }
  };
}

/**
 * Fires a 'AllocationPercentageUpdated' track call.
 *
 * @param AllocationPercentageUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function allocationPercentageUpdated(
  props: AllocationPercentageUpdated,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Allocation_Percentage_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when a user updates the allocation percentage determining the portion of the defined audience to receive the promotion',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      country: { description: 'The country where the offer will act', type: ['string', 'null'] },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      updated_allocation_percentage: {
        description:
          'The updated allocation percentage determining the portion of the defined audience to receive the promotion.',
        type: ['number', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'mechanism_types',
      'screen_name',
      'url',
      'campaign_id',
      'updated_allocation_percentage',
      'country'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Allocation Percentage Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'AudienceCalculationCompleted' track call.
 *
 * @param AudienceCalculationCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function audienceCalculationCompleted(
  props: AudienceCalculationCompleted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Audience_Calculation_Completed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered every time the total estimated accounts is recalculated if the portfolio optimization is active for the selected audience',
    properties: {
      audience_id: {
        description: 'The ID Key for the audience being used in the campaign',
        type: ['string', 'null']
      },
      audience_name: {
        description: 'The audience name provided when creating the audience',
        type: ['string', 'null']
      },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      initial_accounts_targeted: {
        description:
          'The initial amount of estimated targeted accounts for this audience before applying portfolio optimization model',
        type: ['integer', 'null']
      },
      recalculated_accounts_targeted: {
        description:
          'The total amount of estimated targeted accounts for this audience after SKU selection when portfolio optimization is active',
        type: ['integer', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      sku_list: {
        description:
          "The sku list array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            sku: {
              description: 'Represent the SKUs selected for the campaign',
              type: ['string', 'null']
            }
          },
          required: ['sku'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      strategy_label: {
        description: "The Strategy label displayed to the user. Samples:'Introduce, 'Introduzir''",
        type: ['string', 'null']
      },
      strategy_name: {
        description: 'The Strategy name. Samples: Introduce',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      }
    },
    required: [
      'audience_id',
      'audience_name',
      'campaign_id',
      'initial_accounts_targeted',
      'recalculated_accounts_targeted',
      'screen_name',
      'sku_list',
      'strategy_label',
      'strategy_name'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Audience Calculation Completed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'AudienceCreationStarted' track call.
 *
 * @param AudienceCreationStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function audienceCreationStarted(
  props: AudienceCreationStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Audience_Creation_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user starts the creation of a new Audience by clicking on the "Create new audience" button.',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      country: { description: 'The country where the offer will act', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['button_label', 'button_name', 'country', 'screen_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Audience Creation Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'AudienceSaved' track call.
 *
 * @param AudienceSaved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function audienceSaved(props: AudienceSaved, options?: Options, callback?: Callback): void {
  const schema = {
    $id: 'Audience_Saved',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user clicks on "Save Audiences" when creating or editing an audience.',
    properties: {
      audience_id: {
        description: 'The ID Key for the audience being used in the campaign',
        type: ['string', 'null']
      },
      audience_name: {
        description: 'The audience name provided when creating the audience',
        type: ['string', 'null']
      },
      audience_traits_count: {
        description: 'How many Audience traits were on the modal traits list',
        type: ['integer', 'null']
      },
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      country: { description: 'The country where the offer will act', type: ['string', 'null'] },
      creation_method: {
        description:
          'The creation method being used, whether the user selected filters or uploaded CSVs',
        type: ['string', 'null']
      },
      csv_files_count: {
        description:
          "The current number of CSV files uploaded (Null for 'Select filters' creation method)",
        type: ['integer', 'null']
      },
      destination_url: {
        description:
          'Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
        type: ['string', 'null']
      },
      initial_accounts_targeted: {
        description:
          'The initial amount of estimated targeted accounts for this audience before applying portfolio optimization model',
        type: ['integer', 'null']
      },
      is_portfolio_optimization_active: {
        description: 'If portfolio optimization is active for the audience selected',
        enum: ['NO', 'YES', null],
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'audience_id',
      'audience_name',
      'audience_traits_count',
      'button_label',
      'button_name',
      'country',
      'creation_method',
      'csv_files_count',
      'destination_url',
      'initial_accounts_targeted',
      'is_portfolio_optimization_active',
      'screen_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Audience Saved',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'AudienceSelected' track call.
 *
 * @param AudienceSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function audienceSelected(
  props: AudienceSelected,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Audience_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user selects an audience in the Audience Selection page.',
    properties: {
      audience_id: {
        description: 'The ID Key for the audience being used in the campaign',
        type: ['string', 'null']
      },
      audience_name: {
        description: 'The audience name provided when creating the audience',
        type: ['string', 'null']
      },
      audience_traits_count: {
        description: 'How many Audience traits were on the modal traits list',
        type: ['integer', 'null']
      },
      country: { description: 'The country where the offer will act', type: ['string', 'null'] },
      creation_method: {
        description:
          'The creation method being used, whether the user selected filters or uploaded CSVs',
        type: ['string', 'null']
      },
      destination_url: {
        description:
          'Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
        type: ['string', 'null']
      },
      initial_accounts_targeted: {
        description:
          'The initial amount of estimated targeted accounts for this audience before applying portfolio optimization model',
        type: ['integer', 'null']
      },
      is_portfolio_optimization_active: {
        description: 'If portfolio optimization is active for the audience selected',
        enum: ['NO', 'YES', null],
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'audience_id',
      'audience_name',
      'audience_traits_count',
      'country',
      'creation_method',
      'destination_url',
      'initial_accounts_targeted',
      'is_portfolio_optimization_active',
      'screen_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Audience Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'AudienceSelectionPageViewed' track call.
 *
 * @param AudienceSelectionPageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function audienceSelectionPageViewed(
  props: AudienceSelectionPageViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Audience_Selection_Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user loads the Audience Selection page on the offer creation flow.',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      referrer: {
        description:
          'Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default Page Viewed Event Property',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['campaign_id', 'referrer', 'screen_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Audience Selection Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'AudienceTraitsModalViewed' track call.
 *
 * @param AudienceTraitsModalViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function audienceTraitsModalViewed(
  props: AudienceTraitsModalViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Audience_Traits_Modal_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user loads the Show all Audience Traits modal',
    properties: {
      audience_traits_count: {
        description: 'How many Audience traits were on the modal traits list',
        type: ['integer', 'null']
      },
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      modal_name: { description: 'The modal name', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'audience_traits_count',
      'button_label',
      'button_name',
      'campaign_id',
      'modal_name',
      'screen_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Audience Traits Modal Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'BudgetIDUpdated' track call.
 *
 * @param BudgetIDUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function budgetIDUpdated(
  props: BudgetIDUpdated,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Budget_ID_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when a user updates the Budget ID',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      country: { description: 'The country where the offer will act', type: ['string', 'null'] },
      error_message_shown: {
        description:
          'A flag indicating whether an error message was shown to the user due to an invalid input (e.g., 0 or empty value).',
        type: ['boolean', 'null']
      },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      updated_budget_id: { description: 'The updated Budget ID', type: ['string', 'null'] },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'campaign_id',
      'country',
      'error_message_shown',
      'mechanism_types',
      'screen_name',
      'updated_budget_id',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Budget ID Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'CampaignCreationFinished' track call.
 *
 * @param CampaignCreationFinished props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function campaignCreationFinished(
  props: CampaignCreationFinished,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Campaign_Creation_Finished',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user clicks on the button to finalize campaign creation',
    properties: {
      audience_traits_count: {
        description: 'How many Audience traits were on the modal traits list',
        type: ['integer', 'null']
      },
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      country: { description: 'The country where the offer will act', type: ['string', 'null'] },
      destination_url: {
        description:
          'Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
        type: ['string', 'null']
      },
      end_date: { description: 'The offer end date', type: ['string', 'null'] },
      execution_type: {
        description: 'The execution type selected by the user (e.g. Single SKU, Multiple SKUs).',
        type: ['string', 'null']
      },
      initial_accounts_targeted: {
        description:
          'The initial amount of estimated targeted accounts for this audience before applying portfolio optimization model',
        type: ['integer', 'null']
      },
      is_portfolio_optimization_active: {
        description: 'If portfolio optimization is active for the audience selected',
        enum: ['NO', 'YES', null],
        type: ['string', 'null']
      },
      mechanism_count: {
        description: 'How many mechanism types were selected for the campaign',
        type: ['integer', 'null']
      },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      recalculated_accounts_targeted: {
        description:
          'The total amount of estimated targeted accounts for this audience after SKU selection when portfolio optimization is active',
        type: ['integer', 'null']
      },
      referrer: {
        description:
          'Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default Page Viewed Event Property',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      sku_list_count: {
        description: 'How many skus were listed on the SKU component when the event occured',
        type: ['integer', 'null']
      },
      start_date: { description: 'The offer start date', type: ['string', 'null'] },
      strategy_label: {
        description: "The Strategy label displayed to the user. Samples:'Introduce, 'Introduzir''",
        type: ['string', 'null']
      },
      strategy_name: {
        description: 'The Strategy name. Samples: Introduce',
        type: ['string', 'null']
      },
      sub_strategy_label: {
        description:
          "The Sub Strategy label displayed to the user. Samples:'I1: Attract customers to try new products, 'G6: Trade program - discount per target achievement ''",
        type: ['string', 'null']
      },
      sub_strategy_name: {
        description: 'The Sub Strategy name. Samples: I1, S1',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      }
    },
    required: [
      'audience_traits_count',
      'button_label',
      'button_name',
      'campaign_id',
      'country',
      'destination_url',
      'end_date',
      'execution_type',
      'initial_accounts_targeted',
      'is_portfolio_optimization_active',
      'mechanism_count',
      'mechanism_types',
      'recalculated_accounts_targeted',
      'referrer',
      'screen_name',
      'sku_list_count',
      'start_date',
      'strategy_label',
      'strategy_name',
      'sub_strategy_label',
      'sub_strategy_name'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Campaign Creation Finished',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'CampaignCreationFlowAbandonment' track call.
 *
 * @param CampaignCreationFlowAbandonment props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function campaignCreationFlowAbandonment(
  props: CampaignCreationFlowAbandonment,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Campaign_Creation_Flow_Abandonment',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the leaves the creation flow without saving\n\n\n\n\n',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      modal_scenario: {
        description: 'Boolean to indicate rather the event was triggered in a modal',
        enum: ['FALSE', 'TRUE', null],
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'modal_scenario',
      'screen_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Campaign Creation Flow Abandonment',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'CampaignDetailsPageViewed' track call.
 *
 * @param CampaignDetailsPageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function campaignDetailsPageViewed(
  props: CampaignDetailsPageViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Campaign_Details_Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user loads the Campaign Details page',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      referrer: {
        description:
          'Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default Page Viewed Event Property',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['campaign_id', 'referrer', 'screen_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Campaign Details Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'CampaignGroupIDUpdated' track call.
 *
 * @param CampaignGroupIDUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function campaignGroupIDUpdated(
  props: CampaignGroupIDUpdated,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Campaign_Group_ID_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when a user updates the campaign group ID for a mechanism or touchpoint.',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      updated_group_id: {
        description: 'The updated campaign group ID as typed by the user.',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['campaign_id', 'mechanism_types', 'screen_name', 'updated_group_id', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Campaign Group ID Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'CampaignListingPageViewed' track call.
 *
 * @param CampaignListingPageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function campaignListingPageViewed(
  props: CampaignListingPageViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Campaign_Listing_Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user loads the Campaign Listing page on the creation flow',
    properties: {
      campaign_count: { description: 'The total campaign count', type: ['integer', 'null'] },
      items_per_page: {
        description: 'The current items per page option',
        type: ['integer', 'null']
      },
      page_number: {
        description: 'The number of the page the user was at when the event occurred',
        type: ['integer', 'null']
      },
      referrer: {
        description:
          'Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default Page Viewed Event Property',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['campaign_count', 'items_per_page', 'page_number', 'referrer', 'screen_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Campaign Listing Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'CampaignSettingsPageViewed' track call.
 *
 * @param CampaignSettingsPageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function campaignSettingsPageViewed(
  props: CampaignSettingsPageViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Campaign_Settings_Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user loads the Campaign Settings page on the creation flow\n\n\n',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      referrer: {
        description:
          'Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default Page Viewed Event Property',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['campaign_id', 'referrer', 'screen_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Campaign Settings Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'CampaignStrategyPageViewed' track call.
 *
 * @param CampaignStrategyPageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function campaignStrategyPageViewed(
  props: CampaignStrategyPageViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Campaign_Strategy_Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user loads the Campaign strategy page on the creation flow\n\n',
    properties: {
      referrer: {
        description:
          'Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default Page Viewed Event Property',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['referrer', 'screen_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Campaign Strategy Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'CampaignSummaryPageViewed' track call.
 *
 * @param CampaignSummaryPageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function campaignSummaryPageViewed(
  props: CampaignSummaryPageViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Campaign_Summary_Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user loads the Campaign Summary page on the creation flow',
    properties: {
      accounts_recalculation_status: {
        description:
          'The status of the recalculation of accounts targeted when the audience has portfolio optiomization active',
        type: ['string', 'null']
      },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      initial_accounts_targeted: {
        description:
          'The initial amount of estimated targeted accounts for this audience before applying portfolio optimization model',
        type: ['integer', 'null']
      },
      is_portfolio_optimization_active: {
        description: 'If portfolio optimization is active for the audience selected',
        enum: ['NO', 'YES', null],
        type: ['string', 'null']
      },
      referrer: {
        description:
          'Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default Page Viewed Event Property',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'accounts_recalculation_status',
      'campaign_id',
      'initial_accounts_targeted',
      'is_portfolio_optimization_active',
      'referrer',
      'screen_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Campaign Summary Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'CoverImageModificationTriggered' track call.
 *
 * @param CoverImageModificationTriggered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function coverImageModificationTriggered(
  props: CoverImageModificationTriggered,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Cover_Image_Modification_Triggered',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user clicks on the button to delete the selected cover image or the button to change the selected cover image.',
    properties: {
      action_type: {
        description:
          "The type of action the user is taking, it could be 'delete', 'change' or 'add'.",
        type: ['string', 'null']
      },
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      destination_url: {
        description:
          'Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
        type: ['string', 'null']
      },
      image_file_name: {
        description: 'The file name of the selected image in the file explorer.',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'action_type',
      'button_label',
      'button_name',
      'campaign_id',
      'destination_url',
      'image_file_name',
      'screen_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Cover Image Modification Triggered',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'DateRangeSelected' track call.
 *
 * @param DateRangeSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function dateRangeSelected(
  props: DateRangeSelected,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Date_Range_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user selects a date range.',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      end_date: { description: 'The offer end date', type: ['string', 'null'] },
      mechanism_end_date: {
        description:
          'The end date selected by the user for the touchpoint within the campaign date range.',
        type: ['string', 'null']
      },
      mechanism_start_date: {
        description:
          'The start date selected by the user for the touchpoint within the campaign date range.',
        type: ['string', 'null']
      },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      start_date: { description: 'The offer start date', type: ['string', 'null'] },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'campaign_id',
      'end_date',
      'mechanism_end_date',
      'mechanism_start_date',
      'mechanism_types',
      'screen_name',
      'start_date',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Date Range Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'DiscountValueUpdated' track call.
 *
 * @param DiscountValueUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function discountValueUpdated(
  props: DiscountValueUpdated,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Discount_Value_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when a user updates the discount in a promotion',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      country: { description: 'The country where the offer will act', type: ['string', 'null'] },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      updated_discount: {
        description:
          "The array of updated discount. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array.",
          properties: {
            field_title: { description: 'The field tittle property.', type: ['string', 'null'] },
            updated_value: { description: 'The updated value property.', type: ['number', 'null'] }
          },
          required: ['field_title', 'updated_value'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['campaign_id', 'country', 'mechanism_types', 'screen_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Discount Value Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'ExecutionTypeSelected' track call.
 *
 * @param ExecutionTypeSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function executionTypeSelected(
  props: ExecutionTypeSelected,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Execution_Type_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user selects an execution type for a specific touchpoint.',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      execution_type: {
        description: 'The execution type selected by the user (e.g. Single SKU, Multiple SKUs).',
        type: ['string', 'null']
      },
      is_portfolio_optimization_active: {
        description: 'If portfolio optimization is active for the audience selected',
        enum: ['NO', 'YES', null],
        type: ['string', 'null']
      },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'execution_type',
      'is_portfolio_optimization_active',
      'mechanism_types',
      'screen_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Execution Type Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'ForwardStepperNavigation' track call.
 *
 * @param ForwardStepperNavigation props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function forwardStepperNavigation(
  props: ForwardStepperNavigation,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Forward_Stepper_Navigation',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user clicks os the "Next" Button on Creation Flow',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      destination_url: {
        description:
          'Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'destination_url',
      'screen_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Forward Stepper Navigation',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'GoToTouchpointDetailsButtonClicked' track call.
 *
 * @param GoToTouchpointDetailsButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function goToTouchpointDetailsButtonClicked(
  props: GoToTouchpointDetailsButtonClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Go_To_Touchpoint_Details_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user clicks on the Go to Touchpoitn details button  on the Show SKUs modal',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      skus_without_touchpoints_count: {
        description: 'How many SKUs without Touchpoinsts associated were on the SKU list',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'screen_name',
      'skus_without_touchpoints_count',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Go To Touchpoint Details Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'ListingActionPerformed' track call.
 *
 * @param ListingActionPerformed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function listingActionPerformed(
  props: ListingActionPerformed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Listing_Action_Performed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user clicks on a Action inside the Action list for a Offer on the Offer Listing page',
    properties: {
      button_action: {
        description: 'The action performed by the user within the available actions list',
        type: ['string', 'null']
      },
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      campaign_page_position: {
        description:
          'The number that correspods to the position of the campaign beign clicked on the current page',
        type: ['integer', 'null']
      },
      campaign_period: {
        description: 'The Campaign period that has been selected',
        type: ['string', 'null']
      },
      campaign_status: { description: 'The current campaign status', type: ['string', 'null'] },
      destination_url: {
        description:
          'Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
        type: ['string', 'null']
      },
      items_per_page: {
        description: 'The current items per page option',
        type: ['integer', 'null']
      },
      page_number: {
        description: 'The number of the page the user was at when the event occurred',
        type: ['integer', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      sort_column_header_name: {
        description: 'The sorted column header name. Samples: Status, Campaign Name',
        type: ['string', 'null']
      },
      sort_type: {
        description: 'The type of sorting the user is performing',
        enum: ['ASCENDING', 'DEFAULT', 'DESCENDING', null],
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'button_action',
      'campaign_id',
      'campaign_page_position',
      'campaign_period',
      'campaign_status',
      'destination_url',
      'items_per_page',
      'page_number',
      'screen_name',
      'sort_column_header_name',
      'sort_type',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Listing Action Performed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'ListingCreateNewCampaignClicked' track call.
 *
 * @param ListingCreateNewCampaignClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function listingCreateNewCampaignClicked(
  props: ListingCreateNewCampaignClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Listing_Create_New_Campaign_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user clicks on the "Create new campaign" Button on the Campaign Listing page',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['button_label', 'button_name', 'screen_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Listing Create New Campaign Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'ListingItemsPerPageChange' track call.
 *
 * @param ListingItemsPerPageChange props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function listingItemsPerPageChange(
  props: ListingItemsPerPageChange,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Listing_Items_Per_Page_Change',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered  when the user changes the current items per page option\n\n\n\n\n',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      items_per_page: {
        description: 'The current items per page option',
        type: ['integer', 'null']
      },
      previous_items_per_page: {
        description: 'Previous items per page option',
        type: ['integer', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'items_per_page',
      'previous_items_per_page',
      'screen_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Listing Items Per Page Change',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'ListingSortInteraction' track call.
 *
 * @param ListingSortInteraction props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function listingSortInteraction(
  props: ListingSortInteraction,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Listing_Sort_Interaction',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user sorts a column by clicking on its header',
    properties: {
      referrer: {
        description:
          'Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default Page Viewed Event Property',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      sort_column_header_label: {
        description:
          'The sorted  column header label displayed to the user. Samples:\'Campaign Name", "Nome da Campanha"',
        type: ['string', 'null']
      },
      sort_column_header_name: {
        description: 'The sorted column header name. Samples: Status, Campaign Name',
        type: ['string', 'null']
      },
      sort_type: {
        description: 'The type of sorting the user is performing',
        enum: ['ASCENDING', 'DEFAULT', 'DESCENDING', null],
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'referrer',
      'screen_name',
      'sort_column_header_label',
      'sort_column_header_name',
      'sort_type',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Listing Sort Interaction',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'MechanismDescriptionUpdated' track call.
 *
 * @param MechanismDescriptionUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function mechanismDescriptionUpdated(
  props: MechanismDescriptionUpdated,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Mechanism_Description_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when a user updates the description of a mechanism or touchpoint.',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      mechanism_description: {
        description:
          'The description of the mechanism as updated by the user. This description will be displayed to users on the customer app.',
        type: ['string', 'null']
      },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['campaign_id', 'mechanism_description', 'mechanism_types', 'screen_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Mechanism Description Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'MechanismTitleUpdated' track call.
 *
 * @param MechanismTitleUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function mechanismTitleUpdated(
  props: MechanismTitleUpdated,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Mechanism_Title_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when a user updates the title of a mechanism or touchpoint.',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      mechanism_title: {
        description:
          'The title of the mechanism as updated by the user. This title will be displayed to users on the customer app.',
        type: ['string', 'null']
      },
      mechanism_title_error: {
        description: 'The error generated by a new campaign name that is not valid.',
        enum: ['ALREADY_IN_USE', 'TOO_SHORT', null],
        type: ['string', 'null']
      },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'campaign_id',
      'mechanism_title',
      'mechanism_title_error',
      'mechanism_types',
      'screen_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Mechanism Title Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'MechanismTypeCheckBoxInteraction' track call.
 *
 * @param MechanismTypeCheckBoxInteraction props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function mechanismTypeCheckBoxInteraction(
  props: MechanismTypeCheckBoxInteraction,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Mechanism_Type_Check_Box_Interaction',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user clicks on a Touchpoint Mechanism Type check box',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      check_box_action: {
        description: 'The toggle state the user is changing the check box to.',
        enum: ['SELECT', 'UNSELECT', null],
        type: ['string', 'null']
      },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      search_text: {
        description: 'The search text being used when the event occured, if there is any',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'campaign_id',
      'check_box_action',
      'mechanism_types',
      'screen_name',
      'search_text',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Mechanism Type Check Box Interaction',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'MenuInteraction' track call.
 *
 * @param MenuInteraction props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function menuInteraction(
  props: MenuInteraction,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Menu_Interaction',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered  when the user interacts with the side bar menu in the application\n\n\n\n',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      destination_url: {
        description:
          'Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
        type: ['string', 'null']
      },
      menu_category: {
        description:
          'Top level Menu Category in the given context, always in English. Samples:  Dashboard, Report_orders, Report_Customer. ',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['campaign_id', 'destination_url', 'menu_category', 'screen_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Menu Interaction',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'ModalCancelOrExit' track call.
 *
 * @param ModalCancelOrExit props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function modalCancelOrExit(
  props: ModalCancelOrExit,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Modal_Cancel_Or_Exit',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user does not confirm a action by leaving a modal, or clicking on its "cancel" button\n\n\n\n',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      modal_name: { description: 'The modal name', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['button_label', 'button_name', 'campaign_id', 'modal_name', 'screen_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Modal Cancel Or Exit',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'OfferPublicationStatus' track call.
 *
 * @param OfferPublicationStatus props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function offerPublicationStatus(
  props: OfferPublicationStatus,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Offer_Publication_Status',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: '\nTriggered to indicate the success or failure of the offer publication process',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      destination_url: {
        description:
          'Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
        type: ['string', 'null']
      },
      publish_status: {
        description: 'If an error occurred',
        enum: ['ERROR', 'SUCCESS', null],
        type: ['string', 'null']
      },
      referrer: {
        description:
          'Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default Page Viewed Event Property',
        type: ['string', 'null']
      }
    },
    required: ['campaign_id', 'destination_url', 'publish_status', 'referrer'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Publication Status',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'OrderAvailabilityUpdated' track call.
 *
 * @param OrderAvailabilityUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function orderAvailabilityUpdated(
  props: OrderAvailabilityUpdated,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Order_Availability_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when a user updates the amount of times an account can make a purchase within this promotion',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      country: { description: 'The country where the offer will act', type: ['string', 'null'] },
      error_message_shown: {
        description:
          'A flag indicating whether an error message was shown to the user due to an invalid input (e.g., 0 or empty value).',
        type: ['boolean', 'null']
      },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      updated_order_availability: {
        description: 'The updated order availability data',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'campaign_id',
      'country',
      'error_message_shown',
      'mechanism_types',
      'screen_name',
      'updated_order_availability',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Order Availability Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'PointsBasedOfferPointsModified' track call.
 *
 * @param PointsBasedOfferPointsModified props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pointsBasedOfferPointsModified(
  props: PointsBasedOfferPointsModified,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Points_Based_Offer_Points_Modified',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when a user modifies the number of points that can be earned for completing a points-based offer.',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      error_message_shown: {
        description:
          'A flag indicating whether an error message was shown to the user due to an invalid input (e.g., 0 or empty value).',
        type: ['boolean', 'null']
      },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      modification_method: {
        description:
          'The method used by the user to modify the points (e.g., "typing", "arrow_up", "arrow_down").',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      updated_points: {
        description:
          'The updated number of points that can be earned for completing the points-based offer.',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'campaign_id',
      'error_message_shown',
      'mechanism_types',
      'modification_method',
      'screen_name',
      'updated_points',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Points Based Offer Points Modified',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SKUCheckBoxInteraction' track call.
 *
 * @param SKUCheckBoxInteraction props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sKUCheckBoxInteraction(
  props: SKUCheckBoxInteraction,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'SKU_Check_Box_Interaction',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user clicks on a SKU check box\n\n\n\n\n\n',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      check_box_action: {
        description: 'The toggle state the user is changing the check box to.',
        enum: ['SELECT', 'UNSELECT', null],
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      search_text: {
        description: 'The search text being used when the event occured, if there is any',
        type: ['string', 'null']
      },
      sku_list_count: {
        description: 'How many skus were listed on the SKU component when the event occured',
        type: ['integer', 'null']
      },
      sku_list_position: {
        description:
          'The position of the SKU being selected or unselected on the current list ordering from 1 to n.',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'campaign_id',
      'check_box_action',
      'screen_name',
      'search_text',
      'sku_list_count',
      'sku_list_position',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'SKU Check Box Interaction',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SKUClearSearch' track call.
 *
 * @param SKUClearSearch props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sKUClearSearch(
  props: SKUClearSearch,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'SKU_Clear_Search',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user clears the current search by clicking on the search "x" or on the "Clear search" button or if they delete all text from the search bar.\n\n\n\n\n',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      search_text: {
        description: 'The search text being used when the event occured, if there is any',
        type: ['string', 'null']
      },
      trigger_method: {
        description: 'The method used by the user to perform an action that triggered the event',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'screen_name',
      'search_text',
      'trigger_method',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'SKU Clear Search',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SKUClearSelection' track call.
 *
 * @param SKUClearSelection props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sKUClearSelection(
  props: SKUClearSelection,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'SKU_Clear_Selection',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user unselects all SKU currently selected clicking on "x Clear selection"\n\n\n\n\n\n\n',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      search_text: {
        description: 'The search text being used when the event occured, if there is any',
        type: ['string', 'null']
      },
      sku_list_count: {
        description: 'How many skus were listed on the SKU component when the event occured',
        type: ['integer', 'null']
      },
      sku_new_selection_count: {
        description: 'How many new skus were selected when the event occurred',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'screen_name',
      'search_text',
      'sku_list_count',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'SKU Clear Selection',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SKULimitUpdated' track call.
 *
 * @param SKULimitUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sKULimitUpdated(
  props: SKULimitUpdated,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'SKU_Limit_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when a user updates the total limit of SKUs in a promotion',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      country: { description: 'The country where the offer will act', type: ['string', 'null'] },
      error_message_shown: {
        description:
          'A flag indicating whether an error message was shown to the user due to an invalid input (e.g., 0 or empty value).',
        type: ['boolean', 'null']
      },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      updated_sku_limit_quantity: {
        description:
          'The updated total amount of SKUs a POC can buy with this discount, among all orders',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'campaign_id',
      'country',
      'error_message_shown',
      'mechanism_types',
      'screen_name',
      'updated_sku_limit_quantity',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'SKU Limit Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SKULoadError' track call.
 *
 * @param SKULoadError props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sKULoadError(props: SKULoadError, options?: Options, callback?: Callback): void {
  const schema = {
    $id: 'SKU_Load_Error',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the SKU list fails to load\n\n\n',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      referrer: {
        description:
          'Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default Page Viewed Event Property',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['campaign_id', 'referrer', 'screen_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'SKU Load Error',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SKUMaximumQuantityUpdated' track call.
 *
 * @param SKUMaximumQuantityUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sKUMaximumQuantityUpdated(
  props: SKUMaximumQuantityUpdated,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'SKU_Maximum_Quantity_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when a user updates the maximum quantity of SKUs in a promotion',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      country: { description: 'The country where the offer will act', type: ['string', 'null'] },
      error_message_shown: {
        description:
          'A flag indicating whether an error message was shown to the user due to an invalid input (e.g., 0 or empty value).',
        type: ['boolean', 'null']
      },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      updated_maximum_quantity: {
        description:
          'The updated maximum amount of products the POC can buy in a single order with this promotional price',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'campaign_id',
      'country',
      'error_message_shown',
      'mechanism_types',
      'screen_name',
      'updated_maximum_quantity',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'SKU Maximum Quantity Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SKUMinimumQuantityUpdated' track call.
 *
 * @param SKUMinimumQuantityUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sKUMinimumQuantityUpdated(
  props: SKUMinimumQuantityUpdated,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'SKU_Minimum_Quantity_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when a user updates the minimum quantity of SKUs in a promotion',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      country: { description: 'The country where the offer will act', type: ['string', 'null'] },
      error_message_shown: {
        description:
          'A flag indicating whether an error message was shown to the user due to an invalid input (e.g., 0 or empty value).',
        type: ['boolean', 'null']
      },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      updated_minimum_quantity: {
        description:
          'The updated minimum amount of SKUs that a POC needs to buy to earn this offer',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'campaign_id',
      'country',
      'error_message_shown',
      'mechanism_types',
      'screen_name',
      'updated_minimum_quantity',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'SKU Minimum Quantity Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SKUQuantityUpdated' track call.
 *
 * @param SKUQuantityUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sKUQuantityUpdated(
  props: SKUQuantityUpdated,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'SKU_Quantity_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user confirms the quantity of each SKU included in a challenge within the modal window.',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      destination_url: {
        description:
          'Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
        type: ['string', 'null']
      },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      modal_name: { description: 'The modal name', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      sku_updates: {
        description:
          "The sku updates list array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            action_count: {
              description:
                'The number of times the user clicked the "+" or "-" buttons to update the SKU quantity before confirming the changes',
              type: ['integer', 'null']
            },
            previous_quantity: { description: 'The previous quatity ', type: ['integer', 'null'] },
            sku_id: { description: 'The SKU Identification.', type: ['string', 'null'] },
            updated_quantity: { description: 'The update quantity', type: ['integer', 'null'] }
          },
          required: ['action_count', 'previous_quantity', 'sku_id', 'updated_quantity'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'destination_url',
      'mechanism_types',
      'modal_name',
      'screen_name',
      'sku_updates',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'SKU Quantity Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SKUReloadListClicked' track call.
 *
 * @param SKUReloadListClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sKUReloadListClicked(
  props: SKUReloadListClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'SKU_Reload_List_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user reloads the current SKU list by clicking on the "Reload list" button\n\n\n\n\n',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      reload_status: {
        description:
          'The reload status. FAIL is for when the SKUs list load fails again. SUCCESS if for when the SKUs list load is successful after the reload.',
        enum: ['FAIL', 'SUCCESS', null],
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['button_label', 'button_name', 'campaign_id', 'reload_status', 'screen_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'SKU Reload List Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SKURemoved' track call.
 *
 * @param SKURemoved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sKURemoved(props: SKURemoved, options?: Options, callback?: Callback): void {
  const schema = {
    $id: 'SKU_Removed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when a user removes an SKU from their selection in the table.',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      sku_id: {
        description: 'The ID of the SKU involved in the user action.',
        type: ['string', 'null']
      },
      sku_list_count: {
        description: 'How many skus were listed on the SKU component when the event occured',
        type: ['integer', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'mechanism_types',
      'screen_name',
      'sku_id',
      'sku_list_count',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'SKU Removed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SKUSearch' track call.
 *
 * @param SKUSearch props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sKUSearch(props: SKUSearch, options?: Options, callback?: Callback): void {
  const schema = {
    $id: 'SKU_Search',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user triggers the SKU search engine by any means\n\n\n',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      sku_search_result_count: {
        description: 'How many skus were on the search resulting list',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['campaign_id', 'screen_name', 'sku_search_result_count', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'SKU Search',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SKUSelectAllClicked' track call.
 *
 * @param SKUSelectAllClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sKUSelectAllClicked(
  props: SKUSelectAllClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'SKU_Select_All_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when a user SKU Select All Clicked.',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      search_text: {
        description: 'The search text being used when the event occured, if there is any',
        type: ['string', 'null']
      },
      sku_list_count: {
        description: 'How many skus were listed on the SKU component when the event occured',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'screen_name',
      'search_text',
      'sku_list_count',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'SKU Select All Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SKUSelectionConfirmation' track call.
 *
 * @param SKUSelectionConfirmation props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sKUSelectionConfirmation(
  props: SKUSelectionConfirmation,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'SKU_Selection_Confirmation',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user confirms a SKU selection by clicking on the "Select SKUs" button\n\n\n\n\n\n\n\n\n',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      search_text: {
        description: 'The search text being used when the event occured, if there is any',
        type: ['string', 'null']
      },
      sku_list_count: {
        description: 'How many skus were listed on the SKU component when the event occured',
        type: ['integer', 'null']
      },
      sku_new_selection_count: {
        description: 'How many new skus were selected when the event occurred',
        type: ['integer', 'null']
      },
      sku_previous_selection_count: {
        description:
          'How many skus were previously selected before the user opened the SKU component for this time',
        type: ['integer', 'null']
      },
      sku_unselection_count: {
        description: 'How many skus were unselected when the event occurred',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'screen_name',
      'search_text',
      'sku_list_count',
      'sku_previous_selection_count',
      'sku_unselection_count',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'SKU Selection Confirmation',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SKUSelectionMenuExit' track call.
 *
 * @param SKUSelectionMenuExit props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sKUSelectionMenuExit(
  props: SKUSelectionMenuExit,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'SKU_Selection_Menu_Exit',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user leaves the SKU selection component by clicking on the "x" button\n\n\n\n\n\n\n\n\n',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      search_text: {
        description: 'The search text being used when the event occured, if there is any',
        type: ['string', 'null']
      },
      sku_list_count: {
        description: 'How many skus were listed on the SKU component when the event occured',
        type: ['integer', 'null']
      },
      sku_new_selection_count: {
        description: 'How many new skus were selected when the event occurred',
        type: ['integer', 'null']
      },
      sku_previous_selection_count: {
        description:
          'How many skus were previously selected before the user opened the SKU component for this time',
        type: ['integer', 'null']
      },
      sku_unselection_count: {
        description: 'How many skus were unselected when the event occurred',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'screen_name',
      'search_text',
      'sku_list_count',
      'sku_previous_selection_count',
      'sku_unselection_count',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'SKU Selection Menu Exit',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SKUSelectionTriggered' track call.
 *
 * @param SKUSelectionTriggered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sKUSelectionTriggered(
  props: SKUSelectionTriggered,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'SKU_Selection_Triggered',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user clicks on the button to open a modal window and update the quantity of each SKU or the SKU list included in a touchpoint.',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      destination_url: {
        description:
          'Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
        type: ['string', 'null']
      },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      modal_scenario: {
        description: 'Boolean to indicate rather the event was triggered in a modal',
        enum: ['FALSE', 'TRUE', null],
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      sku_list: {
        description:
          "The sku list array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            sku: {
              description: 'Represent the SKUs selected for the campaign',
              type: ['string', 'null']
            }
          },
          required: ['sku'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'destination_url',
      'mechanism_types',
      'modal_scenario',
      'screen_name',
      'sku_list',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'SKU Selection Triggered',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SaveAndExitClicked' track call.
 *
 * @param SaveAndExitClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function saveAndExitClicked(
  props: SaveAndExitClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Save_And_Exit_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user clicks os the "Save and Exit" Button on Creation Flow\n\n\n\n\n',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      destination_url: {
        description:
          'Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'destination_url',
      'screen_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Save And Exit Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SelectTouchpointButtonClicked' track call.
 *
 * @param SelectTouchpointButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function selectTouchpointButtonClicked(
  props: SelectTouchpointButtonClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Select_Touchpoint_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user opens the touchpoint selection modal by clicking in a button',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['button_label', 'button_name', 'campaign_id', 'screen_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Select Touchpoint Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SettingsAddSKUClicked' track call.
 *
 * @param SettingsAddSKUClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function settingsAddSKUClicked(
  props: SettingsAddSKUClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Settings_Add_SKU_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user clicks os the "Add SKU" Button on the Campaign Creation Settings page\n\n\n\n\n\n',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      modal_scenario: {
        description: 'Boolean to indicate rather the event was triggered in a modal',
        enum: ['FALSE', 'TRUE', null],
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      sku_selection_count: {
        description: 'How many skus were selected when the event occurred',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'modal_scenario',
      'screen_name',
      'sku_selection_count',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Settings Add SKU Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SettingsNameProvided' track call.
 *
 * @param SettingsNameProvided props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function settingsNameProvided(
  props: SettingsNameProvided,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Settings_Name_Provided',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user fills or changes the Name Field on the Campaign Settings page on the creation flow\n\n\n\n',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      campaign_name: {
        description: 'The new campaign name being provided by the user',
        type: ['string', 'null']
      },
      campaign_name_error: {
        description: 'The error generated by a new campaign name that is not valid',
        enum: ['ALREADY_IN_USE', 'TOO_SHORT', null],
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['campaign_id', 'campaign_name', 'campaign_name_error', 'screen_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Settings Name Provided',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SettingsPeriodSelected' track call.
 *
 * @param SettingsPeriodSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function settingsPeriodSelected(
  props: SettingsPeriodSelected,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Settings_Period_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user fills or changes the Period Field on the Campaign Settings page on the creation flow\n\n\n\n\n\n',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      campaign_period: {
        description: 'The Campaign period that has been selected',
        type: ['string', 'null']
      },
      expired_campaign_period: {
        description: 'Boolean to indicate rather the expired campaign period warning is on display',
        enum: ['FALSE', 'TRUE', null],
        type: ['string', 'null']
      },
      modal_scenario: {
        description: 'Boolean to indicate rather the event was triggered in a modal',
        enum: ['FALSE', 'TRUE', null],
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'campaign_id',
      'campaign_period',
      'expired_campaign_period',
      'modal_scenario',
      'screen_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Settings Period Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SettingsRemoveSKU' track call.
 *
 * @param SettingsRemoveSKU props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function settingsRemoveSKU(
  props: SettingsRemoveSKU,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Settings_Remove_SKU',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user clicks os the trash can Button on the Campaign Creation Settings page to remove a Sku or when they confirm this on the modal',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      modal_scenario: {
        description: 'Boolean to indicate rather the event was triggered in a modal',
        enum: ['FALSE', 'TRUE', null],
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      sku_selection_count: {
        description: 'How many skus were selected when the event occurred',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'modal_scenario',
      'screen_name',
      'sku_selection_count',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Settings Remove SKU',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'ShowAllAudiencesButtonClicked' track call.
 *
 * @param ShowAllAudiencesButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function showAllAudiencesButtonClicked(
  props: ShowAllAudiencesButtonClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Show_All_Audiences_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user clicks on the Show all button inside the Audience card',
    properties: {
      audience_traits_count: {
        description: 'How many Audience traits were on the modal traits list',
        type: ['integer', 'null']
      },
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'audience_traits_count',
      'button_label',
      'button_name',
      'campaign_id',
      'screen_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Show All Audiences Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'ShowAnalyticsClicked' track call.
 *
 * @param ShowAnalyticsClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function showAnalyticsClicked(
  props: ShowAnalyticsClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Show_Analytics_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user clicks on the See Analytics button',
    properties: {
      audience_traits_count: {
        description: 'How many Audience traits were on the modal traits list',
        type: ['integer', 'null']
      },
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      country: { description: 'The country where the offer will act', type: ['string', 'null'] },
      destination_url: {
        description:
          'Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
        type: ['string', 'null']
      },
      end_date: { description: 'The offer end date', type: ['string', 'null'] },
      mechanism_count: {
        description: 'How many mechanism types were selected for the campaign',
        type: ['integer', 'null']
      },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      offer_category: {
        description: 'The category of the offer. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      offer_status: {
        description: 'The offer status at the moment the event occured',
        type: ['string', 'null']
      },
      referrer: {
        description:
          'Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default Page Viewed Event Property',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      sku_list_count: {
        description: 'How many skus were listed on the SKU component when the event occured',
        type: ['integer', 'null']
      },
      start_date: { description: 'The offer start date', type: ['string', 'null'] },
      strategy_label: {
        description: "The Strategy label displayed to the user. Samples:'Introduce, 'Introduzir''",
        type: ['string', 'null']
      },
      strategy_name: {
        description: 'The Strategy name. Samples: Introduce',
        type: ['string', 'null']
      },
      sub_strategy_label: {
        description:
          "The Sub Strategy label displayed to the user. Samples:'I1: Attract customers to try new products, 'G6: Trade program - discount per target achievement ''",
        type: ['string', 'null']
      },
      sub_strategy_name: {
        description: 'The Sub Strategy name. Samples: I1, S1',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'audience_traits_count',
      'button_label',
      'button_name',
      'campaign_id',
      'country',
      'destination_url',
      'end_date',
      'mechanism_count',
      'mechanism_types',
      'offer_category',
      'offer_status',
      'referrer',
      'screen_name',
      'sku_list_count',
      'start_date',
      'strategy_label',
      'strategy_name',
      'sub_strategy_label',
      'sub_strategy_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Show Analytics Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'ShowSKUsButtonClicked' track call.
 *
 * @param ShowSKUsButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function showSKUsButtonClicked(
  props: ShowSKUsButtonClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Show_SKUs_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user clicks on the Show SKUs button inside the SKUs without touchpoint associated warning message',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      skus_without_touchpoints_count: {
        description: 'How many SKUs without Touchpoinsts associated were on the SKU list',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'screen_name',
      'skus_without_touchpoints_count',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Show SKUs Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'ShowSKUsModalViewed' track call.
 *
 * @param ShowSKUsModalViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function showSKUsModalViewed(
  props: ShowSKUsModalViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Show_SKUs_Modal_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user loads the Show SKUs modal',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      modal_name: { description: 'The modal name', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      skus_without_touchpoints_count: {
        description: 'How many SKUs without Touchpoinsts associated were on the SKU list',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['campaign_id', 'modal_name', 'screen_name', 'skus_without_touchpoints_count', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Show SKUs Modal Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'StepEditionFromSummaryPage' track call.
 *
 * @param StepEditionFromSummaryPage props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function stepEditionFromSummaryPage(
  props: StepEditionFromSummaryPage,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Step_Edition_From_Summary_Page',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user clicks on a Edit button from Summary Page',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      destination_url: {
        description:
          'Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'destination_url',
      'screen_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Step Edition From Summary Page',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'StrategySelected' track call.
 *
 * @param StrategySelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function strategySelected(
  props: StrategySelected,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Strategy_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user selects a Strategy\n\n\n',
    properties: {
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      strategy_label: {
        description: "The Strategy label displayed to the user. Samples:'Introduce, 'Introduzir''",
        type: ['string', 'null']
      },
      strategy_name: {
        description: 'The Strategy name. Samples: Introduce',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['screen_name', 'strategy_label', 'strategy_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Strategy Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'SubStrategySelected' track call.
 *
 * @param SubStrategySelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function subStrategySelected(
  props: SubStrategySelected,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Sub_Strategy_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user selects a Sub Strategy\n\n\n',
    properties: {
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      strategy_label: {
        description: "The Strategy label displayed to the user. Samples:'Introduce, 'Introduzir''",
        type: ['string', 'null']
      },
      strategy_name: {
        description: 'The Strategy name. Samples: Introduce',
        type: ['string', 'null']
      },
      sub_strategy_label: {
        description:
          "The Sub Strategy label displayed to the user. Samples:'I1: Attract customers to try new products, 'G6: Trade program - discount per target achievement ''",
        type: ['string', 'null']
      },
      sub_strategy_name: {
        description: 'The Sub Strategy name. Samples: I1, S1',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'screen_name',
      'strategy_label',
      'strategy_name',
      'sub_strategy_label',
      'sub_strategy_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Sub Strategy Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'TargetUpdated' track call.
 *
 * @param TargetUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function targetUpdated(props: TargetUpdated, options?: Options, callback?: Callback): void {
  const schema = {
    $id: 'Target_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when a user updates the volume or money target for a points-based offer',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      target_type: {
        description:
          'The type of target updated by the user. This could be "volume" for volume-based offers or "money" for spend-based offers.',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      updated_target: {
        description: 'The updated target set by the user. ',
        type: ['number', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'campaign_id',
      'mechanism_types',
      'screen_name',
      'target_type',
      'updated_target',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Target Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'TierActionPerformed' track call.
 *
 * @param TierActionPerformed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function tierActionPerformed(
  props: TierActionPerformed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Tier_Action_Performed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when a user adds or removes a tier in a Stepped Discounts promotion.',
    properties: {
      action_type: {
        description:
          "The type of action the user is taking, it could be 'delete', 'change' or 'add'.",
        type: ['string', 'null']
      },
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      destination_url: {
        description:
          'Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
        type: ['string', 'null']
      },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      tier_number: { description: 'The number of the tier being added.', type: ['number', 'null'] },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      updated_discount: {
        description:
          "The array of updated discount. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array.",
          properties: {
            field_title: { description: 'The field tittle property.', type: ['string', 'null'] },
            updated_value: { description: 'The updated value property.', type: ['number', 'null'] }
          },
          required: ['field_title', 'updated_value'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      updated_maximum_quantity: {
        description:
          'The updated maximum amount of products the POC can buy in a single order with this promotional price',
        type: ['integer', 'null']
      },
      updated_minimum_quantity: {
        description:
          'The updated minimum amount of SKUs that a POC needs to buy to earn this offer',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'destination_url',
      'screen_name',
      'url',
      'tier_number',
      'mechanism_types',
      'updated_minimum_quantity',
      'updated_maximum_quantity',
      'action_type',
      'campaign_id'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Tier Action Performed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'ToggleInteraction' track call.
 *
 * @param ToggleInteraction props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function toggleInteraction(
  props: ToggleInteraction,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Toggle_Interaction',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when a user interacts with any toggle in the application',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      country: { description: 'The country where the offer will act', type: ['string', 'null'] },
      modal_name: { description: 'The modal name', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      strategy_label: {
        description: "The Strategy label displayed to the user. Samples:'Introduce, 'Introduzir''",
        type: ['string', 'null']
      },
      strategy_name: {
        description: 'The Strategy name. Samples: Introduce',
        type: ['string', 'null']
      },
      sub_strategy_label: {
        description:
          "The Sub Strategy label displayed to the user. Samples:'I1: Attract customers to try new products, 'G6: Trade program - discount per target achievement ''",
        type: ['string', 'null']
      },
      sub_strategy_name: {
        description: 'The Sub Strategy name. Samples: I1, S1',
        type: ['string', 'null']
      },
      toggle_state: {
        description: 'The state of the toggle after interaction',
        enum: ['ON', 'OFF'],
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'screen_name',
      'campaign_id',
      'url',
      'country',
      'modal_name',
      'strategy_label',
      'strategy_name',
      'sub_strategy_label',
      'sub_strategy_name',
      'toggle_state'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Toggle Interaction',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'TouchpointCategoryInteraction' track call.
 *
 * @param TouchpointCategoryInteraction props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function touchpointCategoryInteraction(
  props: TouchpointCategoryInteraction,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Touchpoint_Category_Interaction',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user interacts with a touchpoint category by collapsing or expanding the category.',
    properties: {
      modal_name: { description: 'The modal name', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_action: {
        description: 'Indicates the final state of the touchpoint type after interaction. ',
        enum: ['COLLAPSED', 'EXPANDED', null],
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['modal_name', 'screen_name', 'touchpoint_action', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Touchpoint Category Interaction',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'TouchpointDeleted' track call.
 *
 * @param TouchpointDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function touchpointDeleted(
  props: TouchpointDeleted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Touchpoint_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user excludes a touchpoint',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'mechanism_types',
      'screen_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Touchpoint Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'TouchpointDetailsPageViewed' track call.
 *
 * @param TouchpointDetailsPageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function touchpointDetailsPageViewed(
  props: TouchpointDetailsPageViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Touchpoint_Details_Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user loads the Touchpoints and Mechanics Selection page on the creation flow.',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      referrer: {
        description:
          'Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default Page Viewed Event Property',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['campaign_id', 'mechanism_types', 'referrer', 'screen_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Touchpoint Details Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'TouchpointSelected' track call.
 *
 * @param TouchpointSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function touchpointSelected(
  props: TouchpointSelected,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Touchpoint_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user confirms the selection of a touchpoint.',
    properties: {
      button_label: {
        description: 'The button label displayed to the user, including localization',
        type: ['string', 'null']
      },
      button_name: { description: 'The button name on the back end', type: ['string', 'null'] },
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'button_label',
      'button_name',
      'campaign_id',
      'mechanism_types',
      'screen_name',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Touchpoint Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'TouchpointSelectionPageViewed' track call.
 *
 * @param TouchpointSelectionPageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function touchpointSelectionPageViewed(
  props: TouchpointSelectionPageViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Touchpoint_Selection_Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user loads the Touchpoints and Mechanics Selection page on the creation flow',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      mechanism_types: {
        description:
          "The machanism_types array. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
        items: {
          description: "Do not implement with the 'items' structure/object, it's a normal array",
          properties: {
            mechanism_type: {
              description: 'Which touchpoints were selected.',
              type: ['string', 'null']
            }
          },
          required: ['mechanism_type'],
          type: ['object']
        },
        type: ['array', 'null']
      },
      referrer: {
        description:
          'Full URL of the previous page. Equivalent to document. Referrer from the DOM API. Default Page Viewed Event Property',
        type: ['string', 'null']
      },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_type: {
        description: 'The type of touchpoint selected. Samples: Promotions, Rewards',
        type: ['string', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: ['campaign_id', 'mechanism_types', 'referrer', 'screen_name', 'url'],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Touchpoint Selection Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}
/**
 * Fires a 'TouchpointsSearch' track call.
 *
 * @param TouchpointsSearch props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function touchpointsSearch(
  props: TouchpointsSearch,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Touchpoints_Search',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user triggers the touchpoints search engine by any means',
    properties: {
      campaign_id: { description: 'The Campaign id', type: ['string', 'null'] },
      screen_name: {
        description:
          'The name of the screen where the user is taking this action. Samples: Campaign Strategy Page',
        type: ['string', 'null']
      },
      touchpoint_search_query: {
        description: 'The search query entered by the user.',
        type: ['string', 'null']
      },
      touchpoint_search_result_count: {
        description: 'How many touchpoints were on the search resulting list',
        type: ['integer', 'null']
      },
      url: {
        description:
          'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
        type: ['string', 'null']
      }
    },
    required: [
      'campaign_id',
      'screen_name',
      'touchpoint_search_query',
      'touchpoint_search_result_count',
      'url'
    ],
    type: 'object'
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Touchpoints Search',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: ''
          }
        }
      },
      callback
    );
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,

  /**
   * Fires a 'AllocationPercentageUpdated' track call.
   *
   * @param AllocationPercentageUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  allocationPercentageUpdated,
  /**
   * Fires a 'AudienceCalculationCompleted' track call.
   *
   * @param AudienceCalculationCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  audienceCalculationCompleted,
  /**
   * Fires a 'AudienceCreationStarted' track call.
   *
   * @param AudienceCreationStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  audienceCreationStarted,
  /**
   * Fires a 'AudienceSaved' track call.
   *
   * @param AudienceSaved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  audienceSaved,
  /**
   * Fires a 'AudienceSelected' track call.
   *
   * @param AudienceSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  audienceSelected,
  /**
   * Fires a 'AudienceSelectionPageViewed' track call.
   *
   * @param AudienceSelectionPageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  audienceSelectionPageViewed,
  /**
   * Fires a 'AudienceTraitsModalViewed' track call.
   *
   * @param AudienceTraitsModalViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  audienceTraitsModalViewed,
  /**
   * Fires a 'BudgetIDUpdated' track call.
   *
   * @param BudgetIDUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  budgetIDUpdated,
  /**
   * Fires a 'CampaignCreationFinished' track call.
   *
   * @param CampaignCreationFinished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  campaignCreationFinished,
  /**
   * Fires a 'CampaignCreationFlowAbandonment' track call.
   *
   * @param CampaignCreationFlowAbandonment props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  campaignCreationFlowAbandonment,
  /**
   * Fires a 'CampaignDetailsPageViewed' track call.
   *
   * @param CampaignDetailsPageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  campaignDetailsPageViewed,
  /**
   * Fires a 'CampaignGroupIDUpdated' track call.
   *
   * @param CampaignGroupIDUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  campaignGroupIDUpdated,
  /**
   * Fires a 'CampaignListingPageViewed' track call.
   *
   * @param CampaignListingPageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  campaignListingPageViewed,
  /**
   * Fires a 'CampaignSettingsPageViewed' track call.
   *
   * @param CampaignSettingsPageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  campaignSettingsPageViewed,
  /**
   * Fires a 'CampaignStrategyPageViewed' track call.
   *
   * @param CampaignStrategyPageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  campaignStrategyPageViewed,
  /**
   * Fires a 'CampaignSummaryPageViewed' track call.
   *
   * @param CampaignSummaryPageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  campaignSummaryPageViewed,
  /**
   * Fires a 'CoverImageModificationTriggered' track call.
   *
   * @param CoverImageModificationTriggered props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  coverImageModificationTriggered,
  /**
   * Fires a 'DateRangeSelected' track call.
   *
   * @param DateRangeSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  dateRangeSelected,
  /**
   * Fires a 'DiscountValueUpdated' track call.
   *
   * @param DiscountValueUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  discountValueUpdated,
  /**
   * Fires a 'ExecutionTypeSelected' track call.
   *
   * @param ExecutionTypeSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  executionTypeSelected,
  /**
   * Fires a 'ForwardStepperNavigation' track call.
   *
   * @param ForwardStepperNavigation props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  forwardStepperNavigation,
  /**
   * Fires a 'GoToTouchpointDetailsButtonClicked' track call.
   *
   * @param GoToTouchpointDetailsButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  goToTouchpointDetailsButtonClicked,
  /**
   * Fires a 'ListingActionPerformed' track call.
   *
   * @param ListingActionPerformed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  listingActionPerformed,
  /**
   * Fires a 'ListingCreateNewCampaignClicked' track call.
   *
   * @param ListingCreateNewCampaignClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  listingCreateNewCampaignClicked,
  /**
   * Fires a 'ListingItemsPerPageChange' track call.
   *
   * @param ListingItemsPerPageChange props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  listingItemsPerPageChange,
  /**
   * Fires a 'ListingSortInteraction' track call.
   *
   * @param ListingSortInteraction props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  listingSortInteraction,
  /**
   * Fires a 'MechanismDescriptionUpdated' track call.
   *
   * @param MechanismDescriptionUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  mechanismDescriptionUpdated,
  /**
   * Fires a 'MechanismTitleUpdated' track call.
   *
   * @param MechanismTitleUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  mechanismTitleUpdated,
  /**
   * Fires a 'MechanismTypeCheckBoxInteraction' track call.
   *
   * @param MechanismTypeCheckBoxInteraction props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  mechanismTypeCheckBoxInteraction,
  /**
   * Fires a 'MenuInteraction' track call.
   *
   * @param MenuInteraction props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  menuInteraction,
  /**
   * Fires a 'ModalCancelOrExit' track call.
   *
   * @param ModalCancelOrExit props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  modalCancelOrExit,
  /**
   * Fires a 'OfferPublicationStatus' track call.
   *
   * @param OfferPublicationStatus props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  offerPublicationStatus,
  /**
   * Fires a 'OrderAvailabilityUpdated' track call.
   *
   * @param OrderAvailabilityUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  orderAvailabilityUpdated,
  /**
   * Fires a 'PointsBasedOfferPointsModified' track call.
   *
   * @param PointsBasedOfferPointsModified props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pointsBasedOfferPointsModified,
  /**
   * Fires a 'SKUCheckBoxInteraction' track call.
   *
   * @param SKUCheckBoxInteraction props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sKUCheckBoxInteraction,
  /**
   * Fires a 'SKUClearSearch' track call.
   *
   * @param SKUClearSearch props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sKUClearSearch,
  /**
   * Fires a 'SKUClearSelection' track call.
   *
   * @param SKUClearSelection props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sKUClearSelection,
  /**
   * Fires a 'SKULimitUpdated' track call.
   *
   * @param SKULimitUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sKULimitUpdated,
  /**
   * Fires a 'SKULoadError' track call.
   *
   * @param SKULoadError props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sKULoadError,
  /**
   * Fires a 'SKUMaximumQuantityUpdated' track call.
   *
   * @param SKUMaximumQuantityUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sKUMaximumQuantityUpdated,
  /**
   * Fires a 'SKUMinimumQuantityUpdated' track call.
   *
   * @param SKUMinimumQuantityUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sKUMinimumQuantityUpdated,
  /**
   * Fires a 'SKUQuantityUpdated' track call.
   *
   * @param SKUQuantityUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sKUQuantityUpdated,
  /**
   * Fires a 'SKUReloadListClicked' track call.
   *
   * @param SKUReloadListClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sKUReloadListClicked,
  /**
   * Fires a 'SKURemoved' track call.
   *
   * @param SKURemoved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sKURemoved,
  /**
   * Fires a 'SKUSearch' track call.
   *
   * @param SKUSearch props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sKUSearch,
  /**
   * Fires a 'SKUSelectAllClicked' track call.
   *
   * @param SKUSelectAllClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sKUSelectAllClicked,
  /**
   * Fires a 'SKUSelectionConfirmation' track call.
   *
   * @param SKUSelectionConfirmation props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sKUSelectionConfirmation,
  /**
   * Fires a 'SKUSelectionMenuExit' track call.
   *
   * @param SKUSelectionMenuExit props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sKUSelectionMenuExit,
  /**
   * Fires a 'SKUSelectionTriggered' track call.
   *
   * @param SKUSelectionTriggered props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sKUSelectionTriggered,
  /**
   * Fires a 'SaveAndExitClicked' track call.
   *
   * @param SaveAndExitClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  saveAndExitClicked,
  /**
   * Fires a 'SelectTouchpointButtonClicked' track call.
   *
   * @param SelectTouchpointButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  selectTouchpointButtonClicked,
  /**
   * Fires a 'SettingsAddSKUClicked' track call.
   *
   * @param SettingsAddSKUClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  settingsAddSKUClicked,
  /**
   * Fires a 'SettingsNameProvided' track call.
   *
   * @param SettingsNameProvided props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  settingsNameProvided,
  /**
   * Fires a 'SettingsPeriodSelected' track call.
   *
   * @param SettingsPeriodSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  settingsPeriodSelected,
  /**
   * Fires a 'SettingsRemoveSKU' track call.
   *
   * @param SettingsRemoveSKU props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  settingsRemoveSKU,
  /**
   * Fires a 'ShowAllAudiencesButtonClicked' track call.
   *
   * @param ShowAllAudiencesButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  showAllAudiencesButtonClicked,
  /**
   * Fires a 'ShowAnalyticsClicked' track call.
   *
   * @param ShowAnalyticsClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  showAnalyticsClicked,
  /**
   * Fires a 'ShowSKUsButtonClicked' track call.
   *
   * @param ShowSKUsButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  showSKUsButtonClicked,
  /**
   * Fires a 'ShowSKUsModalViewed' track call.
   *
   * @param ShowSKUsModalViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  showSKUsModalViewed,
  /**
   * Fires a 'StepEditionFromSummaryPage' track call.
   *
   * @param StepEditionFromSummaryPage props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  stepEditionFromSummaryPage,
  /**
   * Fires a 'StrategySelected' track call.
   *
   * @param StrategySelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  strategySelected,
  /**
   * Fires a 'SubStrategySelected' track call.
   *
   * @param SubStrategySelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  subStrategySelected,
  /**
   * Fires a 'TargetUpdated' track call.
   *
   * @param TargetUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  targetUpdated,
  /**
   * Fires a 'TierActionPerformed' track call.
   *
   * @param TierActionPerformed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tierActionPerformed,
  /**
   * Fires a 'ToggleInteraction' track call.
   *
   * @param ToggleInteraction props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  toggleInteraction,
  /**
   * Fires a 'TouchpointCategoryInteraction' track call.
   *
   * @param TouchpointCategoryInteraction props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  touchpointCategoryInteraction,
  /**
   * Fires a 'TouchpointDeleted' track call.
   *
   * @param TouchpointDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  touchpointDeleted,
  /**
   * Fires a 'TouchpointDetailsPageViewed' track call.
   *
   * @param TouchpointDetailsPageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  touchpointDetailsPageViewed,
  /**
   * Fires a 'TouchpointSelected' track call.
   *
   * @param TouchpointSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  touchpointSelected,
  /**
   * Fires a 'TouchpointSelectionPageViewed' track call.
   *
   * @param TouchpointSelectionPageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  touchpointSelectionPageViewed,
  /**
   * Fires a 'TouchpointsSearch' track call.
   *
   * @param TouchpointsSearch props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  touchpointsSearch
};

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI];
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(method)}) that can't be found. Either:
         a) Re-generate your typewriter client: \`npx typewriter\`
         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE`);
      const a = analytics();
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method
          },
          withTypewriterContext()
        );
      }
    };
  }
});
