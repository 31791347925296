import { Grid } from '@hexa-ui/components';
import { StylesProvider, createGenerateClassName } from '@material-ui/core';
import { createFeatureToggleServiceV2, useAnalyticsService } from 'admin-portal-shared-services';
import { QueryClientProvider } from 'react-query';
import Router from './Router';
import queryClient from './config/queryClient';
import { OPTIMIZELY_PROJECT_NAME } from './constants';
import { SummaryProvider } from './context/SummaryContext/SummaryContext';
import { IntlProvider } from './i18n';
import { EnvConfig, EnvProvider, useEnvContext } from './provider/EnvProvider';
import fullstorySnippet from './utils/snippets/fullstory';

export default function App(props: EnvConfig) {
  const { segmentKey, useFullstory, optimizelyKey } = props;

  /* istanbul ignore else */
  if (useFullstory) {
    fullstorySnippet();
  }

  useEnvContext();

  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-dlb-lever-mfe',
    seed: 'bees-hub-dlb-lever-mfe'
  });

  useAnalyticsService().load(segmentKey);

  const sdkKey = optimizelyKey;
  createFeatureToggleServiceV2(OPTIMIZELY_PROJECT_NAME, sdkKey);

  return (
    <EnvProvider env={props}>
      <QueryClientProvider client={queryClient}>
        <StylesProvider generateClassName={generateClassName}>
          <IntlProvider>
            <Grid.Container
              style={{
                rowGap: '1rem',
                display: 'block'
              }}
              type="fluid"
              sidebar
            >
              <SummaryProvider>
                <Router />
              </SummaryProvider>
            </Grid.Container>
          </IntlProvider>
        </StylesProvider>
      </QueryClientProvider>
    </EnvProvider>
  );
}
