export interface ValidateNameParams {
  name: string;
  id?: string;
}

export interface GetAllParams {
  page: number;
  pageSize: number;
  sort: string;
  sortField: string;
}

interface Lever {
  id: string;
  status: string;
  name: string;
  touchpoints: string;
  startDate: string;
  endDate: string;
}

interface Pagination {
  page: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
}

export interface GetAllLeversContentResponse {
  content: Lever[];
  pagination: Pagination;
}

export enum ResponseStatus {
  ERROR = 409,
  SUCCESS = 200,
  NOT_MODIFIED = 304
}

export interface GetTouchpointsParams {
  touchpoint: string;
  personalized: boolean;
  country: string;
}
