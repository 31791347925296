import { BASE_URL } from '../../../constants';
import {
  IsPortfolioOptimizationActive,
  audienceCreationStarted,
  audienceSelected,
  audienceSelectionPageViewed
} from '../../../services/segment/segment';
import { getScreenName } from '../utils';
import {
  AudienceCreationStartedEventProps,
  AudienceSelectedEventProps,
  AudienceSelectionPageViewedEventProps
} from './audiencePageEvents.d';

const DOCUMENT_REFERRER = document.location.origin;
const CURRENT_URL = window.location.href;

export const audienceSelectionPageViewedEvent = ({
  campaignId,
  referrer
}: AudienceSelectionPageViewedEventProps) => {
  audienceSelectionPageViewed({
    campaign_id: campaignId,
    referrer,
    screen_name: getScreenName('audience'),
    url: `${DOCUMENT_REFERRER}${BASE_URL}/audience/${campaignId}`
  });
};

export const audienceCreationStartedEvent = ({
  buttonLabel,
  buttonName,
  country,
  campaignId
}: AudienceCreationStartedEventProps) => {
  audienceCreationStarted({
    button_label: buttonLabel,
    button_name: buttonName,
    screen_name: getScreenName('audience'),
    url: `${DOCUMENT_REFERRER}${BASE_URL}/audience/${campaignId}`,
    country
  });
};

export const audienceSelectedEvent = ({
  audienceId,
  audienceName,
  isCsv,
  country,
  initialAccountsTargeted,
  audienceTraitsCount,
  isPortfolioOptimizationActive
}: AudienceSelectedEventProps) => {
  audienceSelected({
    audience_id: audienceId,
    audience_name: audienceName,
    destination_url: CURRENT_URL,
    screen_name: getScreenName('Audience'),
    creation_method: isCsv ? 'csv' : 'filters',
    initial_accounts_targeted: initialAccountsTargeted,
    audience_traits_count: audienceTraitsCount,
    url: CURRENT_URL,
    is_portfolio_optimization_active: isPortfolioOptimizationActive
      ? IsPortfolioOptimizationActive.Yes
      : IsPortfolioOptimizationActive.No,
    country
  });
};
