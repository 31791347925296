import { Button, Dialog, Heading } from '@hexa-ui/components';
import { useState } from 'react';
import { onCloseProps, useGenericDialogProps } from './useGenericDialog.d';
import Styled from './useGenericDialog.styles';

const useGenericDialog = (onClose?: onCloseProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogProps, setDialogProps] = useState<useGenericDialogProps>(
    {} as useGenericDialogProps
  );

  const onOpenDialog = (loadedData: useGenericDialogProps) => {
    setIsOpen(true);
    setDialogProps(loadedData);
  };

  const onCloseDialog = (
    triggerSegment = false,
    buttonLabel = 'X',
    buttonName = 'X',
    modalName = ''
  ) => {
    setIsOpen(false);
    if (triggerSegment) {
      onClose?.(buttonLabel, buttonName, modalName);
    }
  };

  return {
    onOpenDialog,
    onCloseDialog,
    setIsLoading,
    Dialog: () =>
      isOpen ? (
        <Dialog.Root
          contentCss={{
            overflowY: 'hidden',
            maxWidth: dialogProps.maxWidth ?? '100%'
          }}
          data-testid="dialog"
          title={<Heading size="H2">{dialogProps.title}</Heading>}
          headerHeight="auto"
          onClose={
            dialogProps.onCancel
              ? dialogProps.onCancel
              : () => onCloseDialog(true, 'X', 'X', dialogProps.modalName)
          }
          open={isOpen}
          closeButton={!dialogProps.removeCloseButton}
          actions={
            !dialogProps.removeConfirmButton &&
            !dialogProps.removeCancelButton && (
              <Styled.WrapperDialogButtons>
                <Styled.ButtonsSide reverse={dialogProps.reverse ? 'true' : 'false'}>
                  {!dialogProps.removeConfirmButton && (
                    <Dialog.Close>
                      <Button
                        isLoading={isLoading}
                        disabled={isLoading}
                        variant={dialogProps.buttonConfirmColor ?? 'primary'}
                        onClick={dialogProps.onConfirm}
                      >
                        {dialogProps.confirmMessage}
                      </Button>
                    </Dialog.Close>
                  )}
                  {!dialogProps.removeCancelButton && (
                    <Dialog.Close>
                      <Button
                        disabled={isLoading}
                        variant={dialogProps.buttonCancelColor ?? 'secondary'}
                        onClick={
                          dialogProps.onCancel
                            ? dialogProps.onCancel
                            : () =>
                                onCloseDialog(
                                  true,
                                  dialogProps.cancelMessage,
                                  'Cancel',
                                  dialogProps.modalName
                                )
                        }
                      >
                        {dialogProps.cancelMessage}
                      </Button>
                    </Dialog.Close>
                  )}
                </Styled.ButtonsSide>
              </Styled.WrapperDialogButtons>
            )
          }
        >
          {dialogProps.body}
        </Dialog.Root>
      ) : null
  };
};

export default useGenericDialog;
