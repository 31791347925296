import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { getSellersById } from '../../sellersService';
import { GetSellersByIdResponse } from '../../sellersService.d';
import { useBeesContext } from '../../../../context/BeesContext/BeesContext';

export default function useGetSellersById(manufacturerId: string) {
  const { userInfo } = useBeesContext();

  const { data, isSuccess, error, isLoading } = useQuery<GetSellersByIdResponse, AxiosError>(
    ['id', manufacturerId],
    () => {
      if (manufacturerId) {
        return getSellersById({
          manufacturerId,
          headers: { vendorId: userInfo.selectedVendor, country: userInfo.selectedCountry }
        });
      }
    },
    {
      staleTime: Infinity,
      retry: false
    }
  );

  return {
    data,
    error,
    isLoading,
    isSuccess
  };
}
