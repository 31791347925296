/* eslint-disable no-unused-expressions */
/* eslint-disable no-constant-condition */
import { format, parse } from 'date-fns';
import { DynamicFields } from '../../components/DynamicFormBuilder/components/DynamicRowsProcessor/DynamicRowsProcessor.types';
import { Touchpoints } from '../../domain/Touchpoints';
import { DiscountAndQuantityVariantsEnum } from '../../pages/TouchpointDetailsPage/components/DiscountAndQuantities/DiscountAndQuantities.types';

export function removeSlashFromEnd(url: string) {
  return url.endsWith('/') ? url.slice(0, -1) : url;
}

export function stringToDate(date: string) {
  return parse(date, 'yyyy-MM-dd', new Date());
}

export function dateToString(date: Date) {
  return format(date, 'yyyy-MM-dd');
}

export function pluralAndSingularMessages(count: number | undefined, one: string, many: string) {
  if (count === undefined) {
    return undefined;
  }
  if (count === 1) {
    return `${count} ${one}`;
  }
  return `${count} ${many}`;
}

export const formatDate = (dateString: string, text: string) => {
  if (!dateString || !text) {
    return '';
  }

  const HOUR_AFTER_PM = 12;
  const HOUR_AND_MINUTES = 5;

  const date = new Date(dateString);
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  const hoursMinutesSecondes = dateString.split('T')[1].split('.')[0];

  const hoursMinutes = hoursMinutesSecondes.slice(0, HOUR_AND_MINUTES);

  const hour = hoursMinutesSecondes.split(':')[0];

  const ampm = +hour >= HOUR_AFTER_PM ? 'PM' : 'AM';

  return `${month} ${day}, ${year} ${text} ${hoursMinutes}${ampm}`;
};

export const formatTimeZone = (timeZone: string, country: string): string => {
  const parts = timeZone.split('/');
  const partsQuantity = 2;

  if (parts.length === partsQuantity) {
    const city = parts[1].replace('_', ' ');
    return `(${city}, ${country})`;
  }
  return `(${country})`;
};

export const removeWhiteSpaces = (name: string): string => {
  const newString = name?.replace(/\s/g, '');

  return newString;
};

export const getPageAndIdFromUrl = () => {
  const PAGE = 2;
  const LEVER_ID = 3;

  const { pathname, href } = window.location;
  const pageName = pathname.split('/')[PAGE];
  const leverId = pathname.split('/')[LEVER_ID];

  return { pageName, leverId, url: href };
};

export const formatEmptyValue = (value: string | number) => {
  if (!value) return '-';

  return value;
};

export enum SkuFormatNames {
  'POINTS_BASED_MULTIPLE_ORDER' = 'POINTS_BASED_MULTIPLE_ORDER',
  'POINTS_BASED_VOLUME_FIXED' = 'POINTS_BASED_VOLUME_FIXED',
  'PROMOTIONS_DISCOUNT' = 'PROMOTIONS_DISCOUNT',
  'PROMOTIONS_TIERED_DISCOUNT' = 'PROMOTIONS_TIERED_DISCOUNT'
}

export function arrayCheckerData(data: Touchpoints[]) {
  const propertiesToCheck = [
    'title',
    'coverImageUrl',
    'description',
    'pointsValue',
    'rewardsCampaignId',
    'numberOfUnits',
    'startDate',
    'endDate',
    'budgetId',
    'eligibleOrderMaximum',
    'allocation'
  ];

  return data?.some((obj) => {
    const hasValue = propertiesToCheck.some(
      (prop) => obj[prop] !== undefined && obj[prop] !== null && obj[prop] !== ''
    );
    const hasItems = Array.isArray(obj.items) && obj.items.length > 0;
    const hasDiscounts =
      obj.budgetId ||
      obj.eligibleOrderMaximum ||
      Object.keys(obj.discountAndQuantities || {}).length > 0;

    return hasValue || hasItems || hasDiscounts;
  });
}

export function getCountryCodeByUrl(url: string) {
  const MAX_CHAR = 3;

  const regex = /\/[A-Z]{2}\d{8}-\d+/;
  const match = url.match(regex);
  return match ? match[0].substring(1, MAX_CHAR) : undefined;
}

export function validateAndLimitInputNumber(value: any, min: number, max: number) {
  const regex = /^[0-9,.]*$/;
  if (value === '' || !regex.test(value)) {
    return '';
  }

  if (value.includes('.')) {
    const [integer] = value.split('.');

    if (Number(integer) === max) {
      return integer;
    }

    return value;
  }

  let validatedValue = Number(value);

  validatedValue = Math.max(min, validatedValue);
  validatedValue = Math.min(max, validatedValue);

  return validatedValue;
}

export function validateAndLimitInputNaturalNumber(value: any) {
  const regex = /^\d*$/;
  if (value === '' || !regex.test(value)) {
    return '';
  }

  const validatedValue = Number(value);

  return validatedValue;
}

export function getRequiredFields(data: DynamicFields[]) {
  const nameMapping = {
    startDateAndEndDate: ['startDate', 'endDate'],
    dateRange: ['startDate', 'endDate'],
    selectSKUOffer: ['items'],
    skuConfigMultipleOrder: ['items'],
    eligibleOrderMaximum: ['orderAvailability']
  };

  const componentMapping = {
    [DiscountAndQuantityVariantsEnum.nonPersonalized]: [
      'minQuantity',
      'maxQuantity',
      'fixDiscount'
    ],
    [DiscountAndQuantityVariantsEnum.personalized]: [
      'minQuantity',
      'maxQuantity',
      'minDiscount',
      'maxDiscount'
    ],
    [DiscountAndQuantityVariantsEnum.tieredNonPersonalized]: ['steps'],
    [DiscountAndQuantityVariantsEnum.tieredPersonalized]: ['steps']
  };

  const requiredFields = data.reduce((fields, field) => {
    if (field.validations?.required) {
      const { name, component } = field;
      if (name in nameMapping) {
        fields.push(...nameMapping[name]);
      } else if (component in componentMapping) {
        fields.push(...componentMapping[component]);
      } else {
        fields.push(name);
      }
      // @TO-DO Add Tiered DiscountAndQuantities variants here
    }
    return fields;
  }, []);

  return requiredFields;
}

export function formatTouchpointsDataItems(data: Touchpoints) {
  if (data.items.length === 0) return data;

  const {
    orderAvailability = null,
    skuLimit = null,
    minDiscount = null,
    maxDiscount = null,
    minQuantity = null,
    maxQuantity = null,
    fixDiscount,
    steps
  } = data.items[0];

  return {
    ...data,
    orderAvailability,
    skuLimit,
    minDiscount,
    maxDiscount,
    minQuantity,
    maxQuantity,
    fixDiscount,
    steps
  };
}
