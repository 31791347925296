/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticationService } from 'admin-portal-shared-services';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { useUserInfo } from '../hooks/useUserInfo/useUserInfo';
import { LOCAL_STORAGE_KEYS } from '../constants';

export interface IRequest {
  url: string;
  body?: Record<string, any> | FormData;
  params?: Record<string, any>;
  headers?: Record<string, any>;
  status?: any;
}

export interface IError extends AxiosError {
  hasError: boolean;
  status?: any;
}

export type IResponse<T> = AxiosResponse<T> | IError;

const initialAxios = Axios.create();

const authentication = useAuthenticationService();
const user = useUserInfo();

authentication.enhancedAxios(
  initialAxios as unknown as Parameters<typeof authentication.enhancedAxios>[0],
  {
    headers: [
      {
        country: user.selectedCountry,
        vendorId: user.selectedVendor,
        manufacturerId: localStorage.getItem(LOCAL_STORAGE_KEYS.selectedSeller) || undefined,
        noorders: localStorage.getItem('noOrders') || ''
      }
    ]
  }
);
initialAxios.interceptors.request.use((config) => {
  const { headers } = config;

  const userInfo = useUserInfo();

  if (userInfo.selectedCountry) {
    headers.country = userInfo.selectedCountry;
  } else {
    delete headers.country;
  }

  const { selectedVendor } = userInfo;
  const selectedSeller = localStorage.getItem(LOCAL_STORAGE_KEYS.selectedSeller);
  const isManufacturer = localStorage.getItem('isVendorManufacturer') === 'true';

  if (isManufacturer) {
    headers.vendorId = selectedSeller;
    headers.manufacturerId = selectedVendor;
  } else {
    headers.vendorId = selectedVendor;
    headers.manufacturerId = undefined;
  }

  return { ...config, headers };
});

const Api = {
  post: <T = any>({ url, body, headers }: IRequest): Promise<IResponse<T>> =>
    initialAxios.post(url, body, { headers }).catch((error: AxiosError) => ({
      hasError: true,
      ...error
    })),

  delete: ({ url }: IRequest): Promise<any> =>
    initialAxios.delete(url).catch((error: AxiosError) => ({ hasError: true, ...error })),

  get: <T = any>({ url, params, headers }: IRequest): Promise<IResponse<T>> =>
    initialAxios.get(url, { params, headers }).catch((error) => ({ hasError: true, ...error })),

  put: ({ url, body, headers }: IRequest): Promise<any> =>
    initialAxios.put(url, body, { headers }).catch((error) => ({
      hasError: true,
      ...error
    })),

  patch: ({ url, body, headers }: IRequest): Promise<any> =>
    initialAxios.patch(url, body, { headers }).catch((error) => ({
      hasError: true,
      ...error
    }))
};

export default Api;
