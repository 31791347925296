import {
  EError,
  ModalScenario,
  campaignSettingsPageViewed,
  settingsAddSKUClicked,
  settingsNameProvided,
  settingsPeriodSelected,
  settingsRemoveSKU
} from '../../../services/segment/segment';
import { segmentScreenNames } from '../utils';

import {
  campaignSettingsAddSkuClickedEventProps,
  campaignSettingsPageViewedEventProps,
  campaignSettingsPeriodSelectedEventProps,
  campaignSettingsRemoveSKUEventProps
} from './settingsPageEvents.d';

const SETTINGS_PAGE_SCREEN_NAME = segmentScreenNames.CampaignSettingsPage;
const CURRENT_URL = window.location.href;

export const campaignSettingsPageViewedEvent = ({
  referrer,
  campaignId,
  url
}: campaignSettingsPageViewedEventProps) => {
  campaignSettingsPageViewed({
    referrer,
    url,
    screen_name: SETTINGS_PAGE_SCREEN_NAME,
    campaign_id: campaignId
  });
};
export const settingsNameProvidedEvent = (
  error: EError,
  campaignName: string,
  campaignId?: string
) => {
  settingsNameProvided({
    screen_name: SETTINGS_PAGE_SCREEN_NAME,
    campaign_name_error: error,
    campaign_id: campaignId ?? null,
    campaign_name: campaignName,
    url: CURRENT_URL
  });
};
export const campaignSettingsAddSkuClickedEvent = ({
  buttonLabel,
  buttonName,
  campaignId,
  modalScenario,
  skuSelectCount
}: campaignSettingsAddSkuClickedEventProps) => {
  settingsAddSKUClicked({
    button_label: buttonLabel,
    button_name: buttonName,
    campaign_id: campaignId,
    modal_scenario: modalScenario,
    screen_name: SETTINGS_PAGE_SCREEN_NAME,
    sku_selection_count: skuSelectCount,
    url: CURRENT_URL
  });
};
export const campaignSettingsPeriodSelectedEvent = ({
  campaignId,
  campaignPeriod,
  expiredCampaignPeriod
}: campaignSettingsPeriodSelectedEventProps) => {
  settingsPeriodSelected({
    campaign_id: campaignId,
    campaign_period: campaignPeriod,
    expired_campaign_period: expiredCampaignPeriod,
    screen_name: SETTINGS_PAGE_SCREEN_NAME,
    modal_scenario: ModalScenario.False,
    url: CURRENT_URL
  });
};
export const campaignSettingsRemoveSKUEvent = ({
  buttonLabel,
  buttonName,
  campaignId,
  modalScenario,
  skuSelectCount
}: campaignSettingsRemoveSKUEventProps) => {
  settingsRemoveSKU({
    button_label: buttonLabel,
    button_name: buttonName,
    campaign_id: campaignId,
    modal_scenario: modalScenario,
    screen_name: SETTINGS_PAGE_SCREEN_NAME,
    sku_selection_count: skuSelectCount,
    url: CURRENT_URL
  });
};
