import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import LeverNotFound from '../../components/LeverNotFound/LeverNotFound';
import { LOCAL_STORAGE_KEYS } from '../../constants';
import { Lever, LeverModel, UpdateLeverParams } from '../../domain/Lever';
import { create, publish, update } from '../../services/lever/LeverService';
import useGetLeverById from '../../services/lever/hook/useGetLeverById/useGetLeverById';
import useSummary from '../SummaryContext/hooks/useSummary/useSummary';
import { ILeverContext } from './LeverContext.d';

export const initialStateLeverContext: LeverModel = {
  id: null,
  strategy: '',
  subStrategy: '',
  name: '',
  status: 'DRAFT',
  startDate: null,
  endDate: null,
  items: [],
  step: '',
  audiencesSegmentation: [],
  notAssociatedItems: [],
  popEstimatedId: ''
};

const LeverContext = createContext({} as ILeverContext);

function LeverProvider({
  children,
  mockInitialState
}: {
  children?: React.ReactNode;
  mockInitialState?: LeverModel;
}) {
  const [initialState, setInitialState] = useState(mockInitialState || initialStateLeverContext);
  const [currentStep, setCurrentStep] = useState('');
  const { updatePopEstimatedId } = useSummary();

  const {
    refetch,
    data: fetchedData,
    changeId,
    error,
    isLoading,
    isEditing
  } = useGetLeverById(initialState);

  const { mutateAsync: updateLeverMutation, isLoading: loadingUpdate } = useMutation<
    Lever,
    unknown,
    Lever
  >((data) => update(data, (resp: Lever) => updatePopEstimatedId(resp.popEstimatedId)));

  const { mutateAsync: createLeverMutation, isLoading: loadingCreate } = useMutation<
    Lever,
    unknown,
    Lever
  >((data) => create(data));

  const { mutateAsync: publishLeverMutation, isLoading: loadingPublish } = useMutation<
    Lever,
    unknown,
    Lever
  >((data) => publish(data));

  const handleUpdate = useCallback(
    async (data: Lever) => {
      await updateLeverMutation(data);
      refetch();
    },
    [updateLeverMutation, refetch]
  );

  const handlePublish = useCallback(
    async (data: Lever, successCallback: () => void, failCallback: () => void) => {
      publishLeverMutation(
        { ...data },
        {
          onError: failCallback,
          onSuccess: () => {
            successCallback();
            refetch();
          }
        }
      );
    },
    [publishLeverMutation, refetch]
  );

  const handleCreate = useCallback(
    async (data: Lever, redirectFunction: (id: string) => void) => {
      const { id: leverId } = await createLeverMutation(data);
      changeId(leverId);
      redirectFunction(leverId);
    },
    [changeId, createLeverMutation]
  );

  const handleSubmit = useCallback(
    async (
      data: Partial<UpdateLeverParams | LeverModel>,
      onSuccess: (id?: string) => void,
      isToPublish: boolean,
      onError?: () => void
    ) => {
      const newData = { ...fetchedData, ...data, currentStep };
      delete newData.audiencesSegmentation?.[0]?.touchpoints?.[0]?.touchpointId;

      if (isToPublish) {
        handlePublish(newData, onSuccess, onError);
      } else if (isEditing) {
        await handleUpdate(newData);
        onSuccess?.();
      } else {
        await handleCreate(newData, onSuccess);
      }
    },
    [fetchedData, isEditing, handleUpdate, handleCreate, handlePublish, currentStep]
  );

  const setStrategyData = useCallback(
    (data: Pick<Lever, 'strategy' | 'subStrategy'>) =>
      setInitialState((prev) => ({ ...prev, ...data })),
    []
  );
  const location = useLocation();
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    const strategy = localStorage.getItem(LOCAL_STORAGE_KEYS.selectedStrategy);
    const subStrategy = localStorage.getItem(LOCAL_STORAGE_KEYS.selectedSubStrategy);
    if (strategy && subStrategy && firstLoad) {
      setStrategyData({ strategy, subStrategy });
      setFirstLoad(false);
    }
  }, [firstLoad, location, setStrategyData]);

  const contextValue: ILeverContext = useMemo(() => {
    return {
      error,
      currentStep,
      setCurrentStep,
      data: fetchedData,
      isLoadingLever: isLoading,
      updateLever: handleSubmit,
      isUpdating: loadingCreate || loadingUpdate || loadingPublish
    };
  }, [
    fetchedData,
    isLoading,
    error,
    handleSubmit,
    loadingCreate,
    loadingUpdate,
    loadingPublish,
    currentStep,
    setCurrentStep
  ]);

  return (
    <LeverContext.Provider value={contextValue}>
      <LeverNotFound hasError={!!error} />
      {!isLoading && !error && children}
    </LeverContext.Provider>
  );
}

export { LeverContext, LeverProvider };
