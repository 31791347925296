import { styled } from '@bees/vision-tokens';
import { theme } from '@hexa-ui/theme';
import { THEME_SPACE } from '../../constants';

const CampaignGoalBarContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  background: 'white',
  boxShadow: `0 0 0 1px rgb(0 0 0 / 16%)`,
  minHeight: '74px',
  alignItems: 'center',
  fontFamily: 'Work Sans',
  borderRadius: theme.space[THEME_SPACE.SPACE_8_PX],
  marginBottom: '16px',
  marginTop: '40px'
});

const LeftContainer = styled('div', {
  gap: '12px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 85px 0 20px',
  height: '74px',
  backgroundColor: '#f2f2f2',
  marginRight: theme.space[THEME_SPACE.SPACE_16_PX],
  borderRadius: '8px 0 0 8px'
});

const RightContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  flex: '1 1'
});

const InfoContainer = styled('div', {
  display: 'flex',
  flex: '1 1'
});

const InfoTitle = styled('span', {
  fontSize: '12px',
  fontWeight: 'bold',
  lineHeight: '18px',
  color: '#808080'
});

const InfoContent = styled('span', {
  fontSize: '14px',
  fontWeight: 'normal',
  lineHeight: '20px'
});

const InfoColumn = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginRight: theme.space[THEME_SPACE.SPACE_48_PX]
});

const Styled = {
  CampaignGoalBarContainer,
  InfoColumn,
  InfoContainer,
  InfoContent,
  InfoTitle,
  LeftContainer,
  RightContainer
};

export default Styled;
