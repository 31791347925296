import { List, Users } from '@hexa-ui/icons';
import { useSidebar } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import { AUDIENCES_URL, BASE_URL } from '../constants';
import { menuInteractionEvent } from './useSegment/generalEvents/generalEvents';

export const IconList = (): React.ReactElement => <List />;
export const IconUsers = (): React.ReactElement => <Users />;

export default function useAppSidebar() {
  const { formatMessage } = useIntl();

  const dispatchMenuInteractionEvent = (menuCategory: string, destinationUrl: string) => {
    const currentPage = window.location.href.split('/');
    const campaignId = localStorage.getItem('CampaignId') || null;
    menuInteractionEvent({
      destinationUrl,
      menuCategory,
      campaignId,
      currentPage
    });
  };

  useSidebar({
    items: [
      {
        id: '1',
        title: formatMessage({ id: 'NewCampaign.CampaignPageTitle' }),
        path: BASE_URL,
        icon: IconList,
        onPageChange: () => {
          dispatchMenuInteractionEvent('CAMPAIGN_LIST', BASE_URL);
        }
      },
      {
        id: '2',
        title: formatMessage({ id: 'SideBar.AudienceBuilder' }),
        path: '?action=audience-builder',
        icon: IconUsers,
        onPageChange: () => {
          dispatchMenuInteractionEvent('AUDIENCE_EDITOR', AUDIENCES_URL);
        }
      }
    ],
    utils: []
  });
}
