import { styled } from '@bees/vision-tokens';
import { Megaphone } from '@hexa-ui/icons';

const StepperContainer = styled('div', {
  padding: '40px 0px',
  width: '1080px'
});

const Actions = styled('div', {
  display: 'flex',
  justifyContent: 'space-between'
});

const ContentStrategy = styled('div', {
  marginTop: '40px'
});

const ContentChildren = styled('div', {
  marginBottom: '32px'
});

const ContentActions = styled('div', {
  display: 'flex',
  gap: '8px'
});

const IconPublish = styled(Megaphone, {
  borderRadius: 100,
  marginRight: 8,
  variants: {
    disabled: {
      isDisabled: {
        background: '#FFFFFF',
        color: '#0000008c !important'
      },
      isEnabled: {
        background: '#FFFF00'
      }
    }
  }
});

const Loading = styled('div', {
  display: 'flex',
  justifyContent: 'center'
});

const StyledStepper = styled('div', {
  button: {
    svg: {
      width: '24px !important',
      height: '24px !important'
    }
  }
});

const Styled = {
  StyledStepper,
  StepperContainer,
  ContentStrategy,
  ContentChildren,
  Actions,
  ContentActions,
  IconPublish,
  Loading
};

export default Styled;
