import {
  campaignStrategyPageViewed,
  strategySelected,
  subStrategySelected
} from '../../../services/segment/segment';
import { segmentScreenNames } from '../utils';
import { StrategySelectedProps, SubStrategySelectedProps } from './campaignsPageEvents.d';

const campainStrategyScreenName = segmentScreenNames.CampaignStrategyPage;
const CURRENT_URL = window.location.href;

export const campaignStrategyPageViewedEvent = (referrer: string) => {
  campaignStrategyPageViewed({
    referrer,
    url: CURRENT_URL,
    screen_name: campainStrategyScreenName
  });
};

export const strategySelectedEvent = ({
  strategyLabel,
  strategyName,
  url
}: StrategySelectedProps) => {
  strategySelected({
    screen_name: campainStrategyScreenName,
    strategy_label: strategyLabel,
    strategy_name: strategyName,
    url
  });
};

export const subStrategySelectedEvent = ({
  strategyLabel,
  strategyName,
  subStrategyLabel,
  subStrategyName,
  url
}: SubStrategySelectedProps) => {
  subStrategySelected({
    screen_name: campainStrategyScreenName,
    strategy_label: strategyLabel,
    strategy_name: strategyName,
    sub_strategy_label: subStrategyLabel,
    sub_strategy_name: subStrategyName,
    url
  });
};
