import { BASE_URL } from '../../constants';
import { pagesUrl } from '../useLeverRoutes/useLeverRoutes';

const HOME_PATH = '/';

const breadcrumbItemsForHomePage = [{ label: 'Breadcrumb.CampaignList', path: BASE_URL }];

const breadcrumbItemsForNewCampaignPage = [
  { label: 'Breadcrumb.CampaignList', path: BASE_URL },
  { label: 'NewCampaign.Heading', path: `${BASE_URL}/${pagesUrl.campaignStrategy}` }
];

const breadcrumbItemsForDetailsPage = [
  { label: 'Breadcrumb.CampaignList', path: BASE_URL },
  { label: 'DetailsPage.Title', path: `${BASE_URL}/${pagesUrl.details}` }
];

export {
  HOME_PATH,
  breadcrumbItemsForHomePage,
  breadcrumbItemsForNewCampaignPage,
  breadcrumbItemsForDetailsPage
};
