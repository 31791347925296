import { BASE_URL } from '../../../constants';
import {
  selectTouchpointButtonClicked,
  touchpointCategoryInteraction,
  touchpointSelected,
  touchpointSelectionPageViewed,
  touchpointsSearch,
  mechanismTypeCheckBoxInteraction,
  touchpointDeleted,
  toggleInteraction,
  ToggleState
} from '../../../services/segment/segment';
import { getScreenName, mapMechanismTypes } from '../utils';
import {
  TouchpointSelectedEventProps,
  mechanismTypesArrayProps,
  selectTouchpointButtonClickedEventProps,
  touchpointCategoryInteractionEventProps,
  touchpointSelectionPageViewedEventProps,
  touchpointsSearchProps,
  mechanismTypeCheckBoxInteractionEventProps,
  touchpointDeletedEventProps,
  ToggleInteractionProps
} from './touchpointsPageEvents.d';

const DOCUMENT_REFERRER = document.location.origin;
const TOUCHPOINTS_PAGE_SCREEN_NAME = getScreenName('touchpoints');

export const touchpointSelectionPageViewedEvent = ({
  campaignId,
  mechanismTypes,
  touchpointType,
  referrer
}: touchpointSelectionPageViewedEventProps) => {
  const mechanismTypesArray: mechanismTypesArrayProps = mapMechanismTypes(mechanismTypes);

  touchpointSelectionPageViewed({
    campaign_id: campaignId,
    screen_name: TOUCHPOINTS_PAGE_SCREEN_NAME,
    mechanism_types: mechanismTypesArray || null,
    touchpoint_type: touchpointType || null,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoints/${campaignId}`,
    referrer
  });
};

export const selectTouchpointButtonClickedEvent = ({
  buttonLabel,
  buttonName,
  campaignId
}: selectTouchpointButtonClickedEventProps) => {
  selectTouchpointButtonClicked({
    button_label: buttonLabel,
    button_name: buttonName,
    campaign_id: campaignId,
    screen_name: TOUCHPOINTS_PAGE_SCREEN_NAME,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoints/${campaignId}`
  });
};

export const touchpointSelectedEvent = ({
  buttonLabel,
  buttonName,
  mechanismTypes,
  touchpointType,
  campaignId
}: TouchpointSelectedEventProps) => {
  const mechanismTypesArray: mechanismTypesArrayProps = mapMechanismTypes(mechanismTypes);

  touchpointSelected({
    button_label: buttonLabel,
    button_name: buttonName,
    mechanism_types: mechanismTypesArray || null,
    touchpoint_type: touchpointType || null,
    campaign_id: campaignId,
    screen_name: TOUCHPOINTS_PAGE_SCREEN_NAME,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoints/${campaignId}`
  });
};

export const touchpointsSearchEvent = ({
  campaignId,
  touchpointSearchQuery,
  touchpointSearchResultCount
}: touchpointsSearchProps) => {
  touchpointsSearch({
    campaign_id: campaignId,
    screen_name: TOUCHPOINTS_PAGE_SCREEN_NAME,
    touchpoint_search_query: touchpointSearchQuery,
    touchpoint_search_result_count: touchpointSearchResultCount,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoints/${campaignId}`
  });
};

export const touchpointCategoryInteractionEvent = ({
  campaignId,
  touchpointAction,
  touchpointType
}: touchpointCategoryInteractionEventProps) => {
  touchpointCategoryInteraction({
    modal_name: 'Select touchpoint',
    screen_name: TOUCHPOINTS_PAGE_SCREEN_NAME,
    touchpoint_action: touchpointAction,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoints/${campaignId}`,
    touchpoint_type: touchpointType
  });
};

export const mechanismTypeCheckBoxInteractionEvent = ({
  mechanismTypes,
  touchpointType,
  campaignId,
  checkboxAction,
  searchText
}: mechanismTypeCheckBoxInteractionEventProps) => {
  const mechanismTypesArray: mechanismTypesArrayProps = mapMechanismTypes(mechanismTypes);

  mechanismTypeCheckBoxInteraction({
    mechanism_types: mechanismTypesArray || null,
    touchpoint_type: touchpointType || null,
    campaign_id: campaignId,
    check_box_action: checkboxAction,
    search_text: searchText,
    screen_name: TOUCHPOINTS_PAGE_SCREEN_NAME,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoints/${campaignId}`
  });
};

export const touchpointDeletedEvent = ({
  buttonLabel,
  buttonName,
  mechanismTypes,
  touchpointType,
  campaignId
}: touchpointDeletedEventProps) => {
  const mechanismTypesArray: mechanismTypesArrayProps = mapMechanismTypes(mechanismTypes);

  touchpointDeleted({
    button_label: buttonLabel || null,
    button_name: buttonName,
    mechanism_types: mechanismTypesArray || null,
    touchpoint_type: touchpointType || null,
    campaign_id: campaignId,
    screen_name: TOUCHPOINTS_PAGE_SCREEN_NAME,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoints/${campaignId}`
  });
};

export const toggleInteractionEvent = ({
  campaignId,
  country,
  toggleState,
  strategyName,
  strategyLabel,
  subStrategyName,
  subStrategyLabel,
  modalName
}: ToggleInteractionProps) => {
  toggleInteraction({
    country,
    campaign_id: campaignId,
    screen_name: TOUCHPOINTS_PAGE_SCREEN_NAME,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/touchpoints/${campaignId}`,
    modal_name: modalName,
    strategy_label: strategyLabel,
    strategy_name: strategyName,
    sub_strategy_label: subStrategyLabel,
    sub_strategy_name: subStrategyName,
    toggle_state: ToggleState[toggleState ? 'On' : 'Off']
  });
};
