import { Paragraph } from '../../pages/StrategyPage/StrategyPage.styles';
import Styled from './LeverNotFound.styles';

const LeverNotFound = ({ hasError }: { hasError: boolean }) => {
  if (hasError) return null;

  return (
    <Styled.ErrorMessage data-testid="error-message">
      <Paragraph hidden>Lever not found.</Paragraph>
    </Styled.ErrorMessage>
  );
};

export default LeverNotFound;
