import { Outlet } from 'react-router-dom';
import Stepper from '../../components/Stepper/Stepper';
import useHandleOpenModal from '../../hooks/useHandleOpenDialog/handleOpenDialog';
import Styled from './CreationCampaign.style';
import useBreadCrumbDialogCreationCampaign from './hooks/useBreadCrumbDialogCreationCampaign';

function CreationCampaign() {
  const { Dialog } = useHandleOpenModal();
  const { BreadCrumbDialog } = useBreadCrumbDialogCreationCampaign();

  return (
    <Styled.CreationCampaignContainer>
      <Styled.StepContainer>
        <Dialog />
        <BreadCrumbDialog />
        <Stepper>
          <Outlet />
        </Stepper>
      </Styled.StepContainer>
    </Styled.CreationCampaignContainer>
  );
}

export default CreationCampaign;
