import { Button, LoadingDots, Paragraph, ProgressTracker } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../constants';
import useLever from '../../context/LeverContext/hooks/useLever/useLever';
import { StepperProvider, useStepperContext } from '../../context/StepperContext/StepperContext';
import useGenericDialog from '../../hooks/useGenericDialog/useGenericDialog';
import useSegment from '../../hooks/useSegment/useSegment';
import StrategyVisibility from '../StrategyVisibility/StrategyVisibility';
import Styled from './Stepper.styles';

const SUMMARY_STEP = 4;

function Stepper({ children }: { children: React.ReactNode }) {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { currentStep, actions } = useStepperContext();
  const {
    forwardStepperNavigationEvent,
    saveAndExitClickedEvent,
    modalCancelOrExitEvent,
    campaignCreationFlowAbandonmentEvent
  } = useSegment();
  const { data, isUpdating } = useLever();

  const { onOpenDialog, Dialog } = useGenericDialog((buttonLabel, buttonName) =>
    modalCancelOrExitEvent({
      buttonLabel,
      buttonName,
      modalName: 'Leave offer creation'
    })
  );

  const handleOpenDialog = () => {
    onOpenDialog({
      title: formatMessage({ id: 'LeaveDialog.Title' }),
      body: <Paragraph>{formatMessage({ id: 'LeaveDialog.Description' })}</Paragraph>,
      confirmMessage: formatMessage({ id: 'LeaveDialog.Leave' }),
      cancelMessage: formatMessage({ id: 'LeaveDialog.Cancel' }),
      buttonConfirmColor: 'destructive',
      onConfirm: () => {
        campaignCreationFlowAbandonmentEvent({
          buttonLabel: formatMessage({ id: 'LeaveDialog.Leave' }),
          buttonName: 'Yes, leave'
        });
        navigate(BASE_URL, {
          state: { currentStep: document.location.href }
        });
      }
    });
  };

  const listSteps = [
    {
      label: formatMessage({ id: 'SettingsPage.Title' }),
      detail: formatMessage({ id: 'SettingsPage.Description' })
    },
    {
      label: formatMessage({ id: 'AudiencePage.Title' }),
      detail: formatMessage({ id: 'AudiencePage.Description' })
    },
    {
      label: formatMessage({ id: 'TouchpointsPage.Title' }),
      detail: formatMessage({ id: 'TouchpointsPage.Description' })
    },
    {
      label: formatMessage({ id: 'TouchpointDetailsPage.Title' }),
      detail: formatMessage({ id: 'TouchpointDetailsPage.Description' })
    },
    {
      label: formatMessage({ id: 'SummaryPage.Title' }),
      detail: formatMessage({ id: 'SummaryPage.Description' })
    }
  ];

  const handleNextClick = () => {
    forwardStepperNavigationEvent({
      buttonLabel: formatMessage({ id: `${actions.next.label}` }),
      campaignId: data?.id,
      url: document.location.href,
      currentStep
    });

    if (actions.next.onClick) actions.next.onClick();
  };

  const handleBackClick = () => {
    if (currentStep !== 0 && actions.back.onClick) {
      actions.back.onClick();
    } else {
      handleOpenDialog();
    }
  };

  const handleSaveAndExitClick = () => {
    saveAndExitClickedEvent({
      currentStep,
      buttonLabel: formatMessage({ id: `${actions.saveAndExit.label}` }),
      campaignId: data?.id,
      url: document.location.href
    });

    if (actions.saveAndExit?.onClick) actions.saveAndExit.onClick();
  };

  const handlePublishOffer = () => {
    actions.publish.onClick();
  };
  const strategyInfo = {
    strategy: formatMessage({ id: `StrategyCard.Strategies.${data.strategy}` }),
    subStrategy: formatMessage({ id: `StrategyCard.SubStrategies.${data.subStrategy}` })
  };

  return (
    <>
      <Dialog />
      <Styled.StepperContainer>
        <Styled.StyledStepper>
          <ProgressTracker.Root
            data-testid="progress-tracker-root"
            size="large"
            orientation="horizontal"
            currentStep={currentStep}
          >
            <ProgressTracker.Header>
              {listSteps.map(({ label, detail }, index) => {
                return (
                  <ProgressTracker.Step index={index} key={label} completed={index < currentStep}>
                    <ProgressTracker.StepLabel
                      alertMessage="alertMessage"
                      label={label}
                      detail={detail}
                    />
                  </ProgressTracker.Step>
                );
              })}
            </ProgressTracker.Header>
          </ProgressTracker.Root>
        </Styled.StyledStepper>
        <Styled.ContentStrategy>
          <StrategyVisibility strategyInfo={strategyInfo} />
        </Styled.ContentStrategy>
        <Styled.ContentChildren>{children}</Styled.ContentChildren>

        {isUpdating ? (
          <Styled.Loading>
            <LoadingDots dataTestId="loading-dots" />
          </Styled.Loading>
        ) : (
          <Styled.Actions>
            <Styled.ContentActions>
              <Button
                variant="secondary"
                disabled={actions.saveAndExit.disabled}
                onClick={handleSaveAndExitClick}
              >
                {formatMessage({ id: actions.saveAndExit.label })}
              </Button>
            </Styled.ContentActions>
            <Styled.ContentActions>
              <Button variant="secondary" onClick={handleBackClick}>
                {formatMessage({ id: actions.back.label })}
              </Button>
              {currentStep === SUMMARY_STEP ? (
                <Button
                  variant="primary"
                  onClick={handlePublishOffer}
                  disabled={actions.publish.disabled}
                >
                  <Styled.IconPublish
                    path={undefined}
                    crossOrigin={undefined}
                    fr={undefined}
                    radius={100}
                    disabled={actions.publish.disabled ? 'isDisabled' : 'isEnabled'}
                  />
                  {formatMessage({ id: actions.publish?.label })}
                </Button>
              ) : (
                <Button disabled={actions.next.disabled} onClick={handleNextClick}>
                  {formatMessage({ id: actions.next.label })}
                </Button>
              )}
            </Styled.ContentActions>
          </Styled.Actions>
        )}
      </Styled.StepperContainer>
    </>
  );
}

function StepperWithProvider({ children }: any) {
  return (
    <StepperProvider>
      <Stepper>{children}</Stepper>
    </StepperProvider>
  );
}

export default StepperWithProvider;
