import {
  ModalScenario,
  campaignCreationFlowAbandonment,
  modalCancelOrExit
} from '../../../services/segment/segment';
import { getPageAndIdFromUrl } from '../../../utils/formatters/formatters';
import { getScreenName } from '../utils';
import { ModalCancelOrExitProps, campaignCreationFlowAbandonmentProps } from './modalEvents.d';

export const modalCancelOrExitEvent = ({
  buttonLabel,
  buttonName,
  campaignId,
  modalName,
  screenName
}: ModalCancelOrExitProps) => {
  const { url, leverId, pageName } = getPageAndIdFromUrl();

  modalCancelOrExit({
    button_label: buttonLabel,
    button_name: buttonName,
    campaign_id: campaignId || leverId,
    modal_name: modalName,
    screen_name: screenName || getScreenName(pageName),
    url
  });
};

export const campaignCreationFlowAbandonmentEvent = ({
  buttonLabel,
  buttonName
}: campaignCreationFlowAbandonmentProps) => {
  const { url, leverId, pageName } = getPageAndIdFromUrl();

  campaignCreationFlowAbandonment({
    url,
    button_label: buttonLabel,
    button_name: buttonName,
    campaign_id: leverId,
    screen_name: getScreenName(pageName),
    modal_scenario: ModalScenario.True
  });
};
