import { Plus } from '@hexa-ui/icons';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../constants';
import useSegment from '../useSegment/useSegment';
import { IRoute } from '../useBreadCrumbItems/useBreadCrumbItems.d';

export const pagesUrl = {
  campaignStrategy: 'campaign-strategy',
  settings: 'settings',
  audience: 'audience',
  touchpoints: 'touchpoints',
  touchpointDetails: 'touchpoint-details',
  summary: 'summary',
  details: 'details'
};

export const PlusIcon = (): ReactNode => Plus;

function useLeverRoutes() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { listingCreateNewCampaignClickedEvent } = useSegment();

  const label = formatMessage({ id: `Breadcrumb.NewCampaign` });

  const leverRoutes: IRoute[] = [
    {
      path: BASE_URL,
      title: formatMessage({ id: `Breadcrumb.CampaignList` }),
      buttons: [
        {
          label,
          onClick: () => {
            listingCreateNewCampaignClickedEvent(label);
            navigate(`${BASE_URL}/${pagesUrl.campaignStrategy}`);
          },
          icon: PlusIcon
        }
      ]
    },
    {
      path: `${BASE_URL}/${pagesUrl.campaignStrategy}`,
      title: ' '
    },
    {
      path: `${BASE_URL}/${pagesUrl.settings}`,
      title: ' '
    },
    {
      path: `${BASE_URL}/${pagesUrl.audience}`,
      title: ' '
    },
    {
      path: `${BASE_URL}/${pagesUrl.touchpoints}`,
      title: ' '
    },
    {
      path: `${BASE_URL}/${pagesUrl.touchpointDetails}`,
      title: ' '
    },
    {
      path: `${BASE_URL}/${pagesUrl.summary}`,
      title: ' '
    },
    {
      path: `${BASE_URL}/${pagesUrl.details}`,
      title: ' '
    }
  ];

  return leverRoutes;
}

export default useLeverRoutes;
