import { BASE_URL } from '../../../constants';
import {
  audienceTraitsModalViewed,
  forwardStepperNavigation,
  menuInteraction,
  saveAndExitClicked
} from '../../../services/segment/segment';
import { getScreenName } from '../utils';
import {
  AudienceTraitsModalViewedProps,
  MenuInteractionEvent,
  StepperProps
} from './generalEvents.d';

const CURRENT_URL = window.location.href;
const DOCUMENT_REFERRER = document.location.origin;

const CURRENT_PAGE = {
  0: 'settings',
  1: 'audience',
  2: 'touchpoints',
  3: 'summary'
};

export const forwardStepperNavigationEvent = ({
  campaignId,
  buttonLabel,
  currentStep,
  url
}: StepperProps) => {
  const step = campaignId
    ? `${CURRENT_PAGE[currentStep + 1]}/${campaignId}`
    : `${CURRENT_PAGE[currentStep + 1]}`;

  forwardStepperNavigation({
    button_name: 'Next',
    button_label: buttonLabel,
    campaign_id: campaignId,
    destination_url: `${DOCUMENT_REFERRER}${BASE_URL}/${step}`,
    screen_name: getScreenName(CURRENT_PAGE[currentStep]),
    url
  });
};

export const saveAndExitClickedEvent = ({
  campaignId,
  buttonLabel,
  currentStep,
  url
}: StepperProps) => {
  saveAndExitClicked({
    url,
    screen_name: getScreenName(CURRENT_PAGE[currentStep]),
    campaign_id: campaignId,
    destination_url: `${DOCUMENT_REFERRER}${BASE_URL}`,
    button_name: 'Save and exit',
    button_label: buttonLabel
  });
};

export const audienceTraitsModalViewedEvent = (props: AudienceTraitsModalViewedProps) => {
  const { audienceTraitsCount, buttonLabel, buttonName, modalName, campaignId, screenName } = props;
  audienceTraitsModalViewed({
    audience_traits_count: audienceTraitsCount,
    button_label: buttonLabel,
    button_name: buttonName,
    modal_name: modalName,
    campaign_id: campaignId,
    screen_name: getScreenName(screenName),
    url: CURRENT_URL
  });
};

export const menuInteractionEvent = (props: MenuInteractionEvent) => {
  const { campaignId, destinationUrl, menuCategory, currentPage } = props;
  const arrayPosition = 4;
  const stepName = currentPage[arrayPosition];

  menuInteraction({
    campaign_id: campaignId || null,
    destination_url: destinationUrl,
    menu_category: menuCategory,
    screen_name: getScreenName(stepName),
    url: currentPage.join('/')
  });
};
