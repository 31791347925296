import { Heading } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { TargetIcon } from '../../assets/img/TargetIcon';
import StrategyVisibilityProps from './StrategyVisibility.d';
import Styled from './StrategyVisibility.styles';

function StrategyVisibility({ strategyInfo }: { strategyInfo: StrategyVisibilityProps }) {
  const { formatMessage } = useIntl();

  return (
    <Styled.CampaignGoalBarContainer data-testid="strategy-visibility-container">
      <Styled.LeftContainer>
        <TargetIcon />
        <Heading size="H5">
          {formatMessage({ id: 'SettingsPage.StrategyComponent.Strategy' })}
        </Heading>
      </Styled.LeftContainer>
      <Styled.RightContainer>
        <Styled.InfoContainer>
          <Styled.InfoColumn>
            <Styled.InfoTitle>
              {formatMessage({ id: 'SettingsPage.StrategyComponent.Strategy' })}
            </Styled.InfoTitle>
            <Styled.InfoContent>{strategyInfo.strategy}</Styled.InfoContent>
          </Styled.InfoColumn>
          <Styled.InfoColumn>
            <Styled.InfoTitle>
              {formatMessage({ id: 'SettingsPage.StrategyComponent.Substrategy' })}
            </Styled.InfoTitle>
            <Styled.InfoContent>{strategyInfo.subStrategy}</Styled.InfoContent>
          </Styled.InfoColumn>
        </Styled.InfoContainer>
      </Styled.RightContainer>
    </Styled.CampaignGoalBarContainer>
  );
}

export default StrategyVisibility;
