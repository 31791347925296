import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useAppHeader } from 'admin-portal-shared-services';
import { usePageTitle } from '../usePageTitle/usePageTitle';
import {
  BreadcrumbItemServiceProps,
  ItemBreadCrumbrops,
  PagesToStaticBreadCrumb
} from './useBreadCrumbItems.d';
import {
  breadcrumbItemsForDetailsPage,
  breadcrumbItemsForHomePage,
  breadcrumbItemsForNewCampaignPage,
  HOME_PATH
} from './useBreadCrumbItems.consts';

const getAllRoutes = (path): Array<string> => {
  const parts = path.split('/').filter(Boolean);
  return parts.reduce((routes, part) => {
    const currentPath = `${routes.length > 0 ? routes[routes.length - 1] : ''}/${part}`;
    return [...routes, currentPath];
  }, []);
};

export function useBreadCrumbItems() {
  const { pathname } = window.location;
  const { getPageTitle } = usePageTitle();
  const { formatMessage } = useIntl();
  const [, setAppHeaderConfig] = useAppHeader();

  const getDynamicBreadcrumbItems = useCallback(
    (url: string): Array<BreadcrumbItemServiceProps> => {
      const routes = getAllRoutes(url);
      return routes.map((route) => ({
        label: getPageTitle(route),
        path: route,
        isCurrentPage: route === url
      }));
    },
    [getPageTitle]
  );

  const getStaticBreadcrumbItems = useCallback(
    (items: ItemBreadCrumbrops): Array<BreadcrumbItemServiceProps> => {
      return items.map((item, index) => ({
        label: formatMessage({ id: item.label }),
        path: item.path,
        isCurrentPage: index === items.length - 1
      }));
    },
    [formatMessage]
  );

  const getCorrectBreadCrumb = useCallback(
    (page: PagesToStaticBreadCrumb) => {
      const pageToFunctionMap = {
        home: () => getStaticBreadcrumbItems(breadcrumbItemsForHomePage),
        campaignStrategy: () => getStaticBreadcrumbItems(breadcrumbItemsForNewCampaignPage),
        details: () => getStaticBreadcrumbItems(breadcrumbItemsForDetailsPage),
        default: () => getDynamicBreadcrumbItems(pathname)
      };
      return (pageToFunctionMap[page] || pageToFunctionMap.default)();
    },
    [pathname]
  );

  const updateBreadcrumbItems = useCallback(
    (page: PagesToStaticBreadCrumb = null) => {
      setAppHeaderConfig({
        breadcrumbConfig: {
          homePath: HOME_PATH,
          items: getCorrectBreadCrumb(page)
        }
      });
    },
    [setAppHeaderConfig]
  );

  return {
    getStaticBreadcrumbItems,
    getDynamicBreadcrumbItems,
    updateBreadcrumbItems
  };
}
