/* eslint-disable react-hooks/rules-of-hooks */
import { useLogService } from 'admin-portal-shared-services';
import Api from '../../Api/Api';
import {
  BASE_URL,
  GET_TOUCHPOINTS_CONFIG,
  GET_TOUCHPOINT_CATEGORIES,
  LEVERS_BUSINESS_SERVICE_BASE_URL
} from '../../constants';
import {
  GetAllLeversContentResponse,
  GetAllParams,
  ResponseStatus,
  ValidateNameParams
} from './LeverService.d';

import { Lever, LeverModel } from '../../domain/Lever';

const VALID_STATUS = [ResponseStatus.SUCCESS, ResponseStatus.NOT_MODIFIED];
async function validateName({ name, id }: ValidateNameParams): Promise<boolean> {
  const log = useLogService();

  try {
    const response = await Api.get({
      url: `${LEVERS_BUSINESS_SERVICE_BASE_URL}/levers-validate-name`,
      params: { name, id }
    });
    if ('response' in response && response.response?.status === ResponseStatus.ERROR) return false;
    if (VALID_STATUS.includes(response?.status)) return true;
    throw response;
  } catch (error) {
    log.error('validate-name-error', error);
    throw error;
  }
}

const getAll = async ({
  page,
  pageSize,
  sort,
  sortField
}: GetAllParams): Promise<GetAllLeversContentResponse> => {
  const log = useLogService();
  try {
    const response = await Api.get({
      url: `${LEVERS_BUSINESS_SERVICE_BASE_URL}/levers`,
      params: { page, pageSize, sort, sortField }
    });
    if ('hasError' in response) throw response;
    return response?.data;
  } catch (error) {
    log.error('get-all-levers-error', error);
    throw error;
  }
};

async function update(data: Lever, callback?: (param: Lever) => void): Promise<Lever> {
  const log = useLogService();
  try {
    const response = await Api.put({
      url: `${LEVERS_BUSINESS_SERVICE_BASE_URL}/levers/${data.id}`,
      body: { ...data, id: '' }
    });
    if (response.hasError) throw response;

    if (callback) callback(response.data);
    return response.data;
  } catch (error) {
    log.error('update-lever', error);
    throw error;
  }
}

async function publish(data: Lever): Promise<Lever> {
  const log = useLogService();
  try {
    const response = await Api.put({
      url: `${LEVERS_BUSINESS_SERVICE_BASE_URL}/levers-publish/${data.id}`,
      body: { ...data, id: '' }
    });
    if (response.hasError) throw response;
    return response.data;
  } catch (error) {
    log.error('publish-lever', error);
    throw error;
  }
}

async function create(data: Lever) {
  const log = useLogService();
  try {
    const response = await Api.post<Lever>({
      url: `${LEVERS_BUSINESS_SERVICE_BASE_URL}/levers`,
      body: data
    });

    if ('hasError' in response) throw response;
    return response.data;
  } catch (error) {
    log.error('create-lever', error);
    throw error;
  }
}

async function getById(id: string) {
  const log = useLogService();

  try {
    const response = await Api.get<LeverModel>({
      url: `${LEVERS_BUSINESS_SERVICE_BASE_URL}/levers/${id}`
    });

    if ('hasError' in response) throw response;
    return response.data;
  } catch (error) {
    log.error('get-lever-by-id-error', error);
    if (error.response?.status === 404) {
      window.history.pushState(null, '', BASE_URL);
    }
    throw error;
  }
}

async function getTouchpointsConfig({
  country,
  personalized,
  touchpoint,
  isMocked
}: {
  touchpoint: string;
  personalized: boolean;
  country: string;
  isMocked?: boolean;
}): Promise<any> {
  const log = useLogService();

  try {
    const response = await Api.get({
      url: GET_TOUCHPOINTS_CONFIG,
      params: {
        touchpoint,
        personalized,
        country,
        isMocked
      }
    });

    if ('hasError' in response) {
      throw response;
    }

    return response.data;
  } catch (error) {
    log.error('get-touchpoints-config', error);
    throw error;
  }
}

async function getTouchpointCategories({
  isMocked,
  personalized
}: {
  isMocked?: boolean;
  personalized: boolean;
}): Promise<any> {
  const log = useLogService();

  try {
    const response = await Api.get({
      url: GET_TOUCHPOINT_CATEGORIES,
      params: { isMocked, personalized }
    });

    if ('hasError' in response) {
      throw response;
    }

    return response.data;
  } catch (error) {
    log.error('get-touchpoint-categories', error);
    throw error;
  }
}

export {
  create,
  getAll,
  getById,
  getTouchpointCategories,
  getTouchpointsConfig,
  publish,
  update,
  validateName
};
