import { styled } from '@bees/vision-tokens';
import { theme } from '@hexa-ui/theme';
import { THEME_SPACE } from '../../constants';

const WrapperDialogButtons = styled('div', {
  display: 'flex',
  gap: theme.space[THEME_SPACE.SPACE_16_PX],
  justifyContent: 'flex-end'
});

export const ButtonsSide = styled('div', {
  display: 'flex',
  gap: '16px',
  variants: {
    reverse: {
      true: { flexDirection: 'row-reverse' },
      false: { flexDirection: 'row' }
    }
  }
});

const Styled = {
  WrapperDialogButtons,
  ButtonsSide
};

export default Styled;
