import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { getCountries } from '../../CountriesService';

export default function useGetCountries() {
  const { data, isSuccess, error, isLoading } = useQuery<any[], AxiosError>(
    'getCountries',
    getCountries,
    {
      staleTime: Infinity,
      retry: false
    }
  );

  return {
    response: data,
    error,
    isLoading,
    isSuccess
  };
}
