import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { LeverProvider } from '../../context/LeverContext/LeverContext';
import useUserInfo from '../../hooks/useUserInfo/useUserInfo';
import { BASE_URL } from '../../constants';

function LeverProviderContainer() {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useUserInfo();

  useEffect(() => {
    const supposedCountry = id?.slice(0, 2);
    const isValidCountry = user.availableCountries.find(({ code }) => code === supposedCountry);

    if (id && !isValidCountry) {
      navigate(BASE_URL);
    }
  }, [id, navigate, user]);

  return (
    <LeverProvider>
      <Outlet />
    </LeverProvider>
  );
}

export default LeverProviderContainer;
