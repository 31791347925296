import { useQuery } from 'react-query';
import { PAGE_SIZE_WITH_25 } from '../../../../constants';
import { getAll } from '../../LeverService';

export const useGetAllLevers = ({
  page = 0,
  pageSize = PAGE_SIZE_WITH_25,
  sort = 'DESC',
  sortField = 'period'
}) => {
  const queryFunction = () => {
    return getAll({ page, pageSize, sort, sortField });
  };

  const { data, isLoading, isError, error, refetch } = useQuery(
    ['getAllLevers', page, pageSize, sort, sortField],
    queryFunction,
    {
      staleTime: 300000,
      cacheTime: 2500
    }
  );

  return {
    data,
    isLoading,
    isError,
    error,
    refetch
  };
};
