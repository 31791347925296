import { mechanismTypesArrayProps } from './touchpointsPageEvents/touchpointsPageEvents.d';

export type ScreenNameType =
  | 'CampaignStrategyPage'
  | 'CampaignListPage'
  | 'CampaignSettingsPage'
  | 'CampaignAudiencesPage'
  | 'CampaignDetailsPage'
  | 'CampaignSetupPage'
  | 'CampaignSummaryPage'
  | 'CampaignTouchpointsPage'
  | 'CampaignTouchpointDetailsPage';

export const segmentScreenNames: Record<ScreenNameType, string> = {
  CampaignStrategyPage: 'CAMPAIGN_STRATEGY_PAGE',
  CampaignListPage: 'CAMPAIGN_LISTING_PAGE',
  CampaignSettingsPage: 'CAMPAIGN_SETTINGS_PAGE',
  CampaignAudiencesPage: 'CAMPAIGN_AUDIENCES_PAGE',
  CampaignDetailsPage: 'CAMPAIGN_DETAILS_PAGE',
  CampaignSetupPage: 'CAMPAIGN_SETUP_PAGE',
  CampaignSummaryPage: 'CAMPAIGN_SUMMARY_PAGE',
  CampaignTouchpointsPage: 'CAMPAIGN_TOUCHPOINTS_PAGE',
  CampaignTouchpointDetailsPage: 'CAMPAIGN_TOUCHPOINT_DETAILS_PAGE'
};

export const getScreenName = (value: string) => {
  switch (value) {
    case 'settings':
      return segmentScreenNames.CampaignSettingsPage;
    case 'audience':
      return segmentScreenNames.CampaignAudiencesPage;
    case 'touchpoints':
      return segmentScreenNames.CampaignTouchpointsPage;
    case 'touchpoint-details':
      return segmentScreenNames.CampaignTouchpointDetailsPage;
    case 'setup':
      return segmentScreenNames.CampaignSetupPage;
    case 'summary':
      return segmentScreenNames.CampaignSummaryPage;
    case 'campaign-strategy':
      return segmentScreenNames.CampaignStrategyPage;
    case 'details':
      return segmentScreenNames.CampaignDetailsPage;
    default:
      return segmentScreenNames.CampaignListPage;
  }
};

export const mapMechanismTypes = (mechanismTypes) => {
  let mechanismTypesArray: mechanismTypesArrayProps;

  if (mechanismTypes) {
    const mechanism = Array.isArray(mechanismTypes) ? mechanismTypes : [mechanismTypes];

    mechanismTypesArray = mechanism.map((item) => {
      return {
        mechanism_type: item
      };
    });
  }

  return mechanismTypesArray;
};
