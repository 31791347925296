import { LoadingDots } from '@hexa-ui/components';
import { useAuthenticationService } from 'admin-portal-shared-services';
import { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { BASE_URL } from './constants';
import { AllLeversProvider } from './context/AllLeversContext/AllLeversContext';
import useAppSidebar from './hooks/useAppSidebar';
import { useUserInfo } from './hooks/useUserInfo/useUserInfo';
import CreationCampaignPage from './pages/CreationCampaign/CreationCampaign';
import useGetCountries from './services/countries/hooks/useGetCountries/useGetCountries';
import HeaderService from './services/header/HeaderService';
import LeverProviderContainer from './components/LeverProviderContainer/LeverProviderContainer';

const StrategyPage = lazy(() => import('./pages/StrategyPage/StrategyPage'));
const HomePage = lazy(() => import('./pages/HomePage/HomePage'));
const SettingsPage = lazy(() => import('./pages/SettingsPage/SettingsPage'));
const AudiencePage = lazy(() => import('./pages/AudiencePage/AudiencePage'));
const TouchpointsPage = lazy(() => import('./pages/TouchpointsPage/TouchpointsPage'));
const TouchpointDetailsPage = lazy(
  () => import('./pages/TouchpointDetailsPage/TouchpointDetailsPage')
);
const SummaryPage = lazy(() => import('./pages/SummaryPage/SummaryPage'));
const DetailsPage = lazy(() => import('./pages/DetailsPage/DetailsPage'));

export function RouterContent() {
  useAppSidebar();

  const [isLoading, setIsLoading] = useState(true);

  const { supportedCountries, country } = useAuthenticationService().parseJwt();
  const { response } = useGetCountries();
  const user = useUserInfo();

  useEffect(() => {
    user.setUserInfo({
      availableCountries: (response || []).filter(({ code }) => supportedCountries.includes(code)),
      selectedCountry: user.selectedCountry ?? country,
      selectedVendor: user?.selectedVendor
    });

    setIsLoading(!response?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (isLoading) return <LoadingDots dataTestId="loader" />;

  return (
    <Suspense fallback={<LoadingDots />}>
      <Routes>
        <Route path={BASE_URL}>
          <Route
            element={
              <AllLeversProvider>
                <HeaderService />
              </AllLeversProvider>
            }
          >
            <Route index element={<HomePage />} />
            <Route path="campaign-strategy" element={<StrategyPage />} />
            <Route element={<LeverProviderContainer />}>
              <Route element={<CreationCampaignPage />}>
                <Route path="settings/:id?" element={<SettingsPage />} />
                <Route path="audience/:id" element={<AudiencePage />} />
                <Route path="touchpoints/:id" element={<TouchpointsPage />} />
                <Route path="touchpoint-details/:id" element={<TouchpointDetailsPage />} />
                <Route path="summary/:id" element={<SummaryPage />} />
              </Route>
              <Route path="details/:id" element={<DetailsPage />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}

function Router() {
  return (
    <BrowserRouter>
      <RouterContent />
    </BrowserRouter>
  );
}

export default Router;
