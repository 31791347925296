import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Paragraph } from '@hexa-ui/components';

import { breadcrumbItemsForNewCampaignPage } from '../../../hooks/useBreadCrumbItems/useBreadCrumbItems.consts';
import { useBreadCrumbItems } from '../../../hooks/useBreadCrumbItems/useBreadCrumbItems';
import { campaignCreationFlowAbandonmentEvent } from '../../../hooks/useSegment/modalEvents/modalEvents';
import { useGenericDialogProps } from '../../../hooks/useGenericDialog/useGenericDialog.d';

export const useBreadCrumbDialogCreationCampaignActions = (onOpenDialog) => {
  const { pathname, href } = window.location;
  const { getStaticBreadcrumbItems } = useBreadCrumbItems();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const dialogConfig = useCallback(
    (url: string): useGenericDialogProps => ({
      title: formatMessage({ id: 'LeaveDialog.Title' }),
      body: <Paragraph>{formatMessage({ id: 'LeaveDialog.Description' })}</Paragraph>,
      confirmMessage: formatMessage({ id: 'LeaveDialog.Leave' }),
      cancelMessage: formatMessage({ id: 'LeaveDialog.Cancel' }),
      buttonConfirmColor: 'destructive',
      onConfirm: () => {
        campaignCreationFlowAbandonmentEvent({
          buttonLabel: formatMessage({ id: 'LeaveDialog.Leave' }),
          buttonName: 'Yes, leave'
        });
        navigate(url, {
          state: { currentStep: href }
        });
      }
    }),
    [formatMessage, href, navigate]
  );

  const handleOpenDialog = useCallback(
    (url: string) => {
      if (pathname.includes('/details')) {
        navigate(url);
      } else {
        onOpenDialog(dialogConfig(url));
      }
    },
    [pathname, navigate, dialogConfig, onOpenDialog]
  );

  const breadCrumbItems = useMemo(
    () => getStaticBreadcrumbItems(breadcrumbItemsForNewCampaignPage),
    [getStaticBreadcrumbItems]
  );

  const breadCrumbItemsWithOnClick = useMemo(
    () =>
      breadCrumbItems.map((item) => ({
        ...item,
        onClick: (e: React.FormEvent) => {
          e.preventDefault();
          handleOpenDialog(item.path);
        }
      })),
    [breadCrumbItems]
  );

  return {
    breadCrumbItemsWithOnClick,
    dialogConfig,
    handleOpenDialog
  };
};
